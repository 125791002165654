import { css } from "styled-components"
import { TitleInner, TitleOuter } from "../../cover/cover.style"

export const banjaStyles = css`
  @keyframes hell {
    0% {
      background-color: rgba(18, 159, 201, 0.8);
    }
    50% {
      background-color: rgba(201, 51, 36, 0.2);
    }
    100% {
      background-color: rgba(18, 159, 201, 0.8);
    }
  }

  ${TitleOuter} {
    mix-blend-mode: difference;
    animation: hell 5s linear infinite; // ease-in-out
  }
  ${TitleInner} {
    color: black;
    h1 {
      text-transform: none;
    }
  }
`
