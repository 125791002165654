// extracted by mini-css-extract-plugin
export var articleRed = "#c93324";
export var articleRedRgb = "#c93324";
export var bgDark = "#151518";
export var bgLight = "#f0f0f0";
export var blockMarginBottom = "1em";
export var blockMarginLeftRight = "calc((100% - 1080px)/2.5)";
export var blockMarginLeftRightTablet = "calc((100% - 800px)/2.5)";
export var blockMaxWidth = "660px";
export var blockMaxWidthCentered = "720px";
export var blockMaxWidthCenteredTablet = "650px";
export var blockMaxWidthTablet = "500px";
export var breakpointDesktopBig = "1600px";
export var breakpointMobile = "1000px";
export var breakpointTablet = "1350px";
export var fontSizeH0 = "3.815rem";
export var fontSizeH1 = "3.052rem";
export var fontSizeH2 = "2.441rem";
export var fontSizeH3 = "1.953rem";
export var fontSizeH4 = "1.563rem";
export var fontSizeH5 = "1.25rem";
export var fontSizeSmall = ".8rem";
export var fontSizeSuperSmall = ".64rem";
export var headerFontColor = "#000";
export var headerHeight = "50px";
export var titleColorRgb = "#c93324";