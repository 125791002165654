import styled, { css } from "styled-components"
import chroma from "chroma-js"
import TvOverlay from "../bg-changer/tv-overlay"
import {
  blockMargins,
  ImgWrapper,
  SecondaryWrapper,
} from "../bg-changer/bg-changer-wrapper.style"

export const MapPointWrapper = styled.div`
  position: relative;
  background-color: transparent !important;
  // padding-top: 50vh;

  &.hidden {
    display: none;
  }
`

export const boxStyles = css`
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  background-color: white;
  padding: 1.5em !important;
  pointer-events: auto;
`

export const TitleWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: -1;

  ${blockMargins}
  display: flex;
  padding: 1em;
  h4 {
    margin: 2em 0 0 0 !important;
    font-size: ${(p) => p.theme.fontSizeH4};
    text-transform: uppercase;
    color: black;
    // text-shadow: 1px 1px rgba(0, 0, 0, 0.8);

    @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
      font-size: ${(p) => p.theme.fontSizeH5};
    }
  }
  // ${boxStyles}
  background: transparent;
  color: white;
  margin-bottom: 60vh !important; // 4em;
`

export const BoxWrapper = styled.div`
  // padding: 1em;
  min-height: 90vh;
  padding-bottom: 90vh;
  // border: 1px dashed red;
  // color: black;
  &.pt-50 {
    padding-top: calc(50vh - 50px);
    position: relative;
    p {
      transform: translateY(-50%);
    }
  }
  &.pt-90 {
    padding-top: 90vh;
  }
  &.pb-0 {
    padding-bottom: 0;
    min-height: none;
  }
  &.pb-10 {
    padding-bottom: 10vh;
    min-height: 10vh;
  }
  &.pb-20 {
    padding-bottom: 20vh;
    min-height: 20vh;
  }
  &.pb-25 {
    padding-bottom: 25vh;
    min-height: 25vh;
  }
  &.pb-50 {
    padding-bottom: 50vh;
    min-height: 50vh;
  }
  &.mob-pb-90 {
    @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
      padding-bottom: 90vh;
      min-height: 90vh;
    }
  }
  &.pb-100 {
    padding-bottom: 100vh;
  }
  &.pb-200 {
    padding-bottom: 200vh;
  }
  &.pb-300 {
    padding-bottom: 300vh;
  }

  .split-view & {
    @media screen and (min-width: ${(p) => p.theme.breakpointMobile}) {
      min-height: 30vh;
      padding-bottom: 30vh;
    }
    transition: opacity 0.3s ease-in-out;
    opacity: 0.6;
    &.in-view {
      opacity: 1;
    }
  }

  > p,
  > figure,
  > h4,
  > blockquote {
    margin: 0;
    background-color: white;
    .dark-mode & {
      background-color: ${(p) => chroma(p.theme.bgDark).alpha(1).hex()};
    }
    ${blockMargins}
    ${boxStyles}
  }

  > h4.has-text-align-center {
    background-color: transparent;
    box-shadow: none;
    button {
      display: inline;
      .toc-button-wrapper {
        display: none;
      }
      text-align: center;
      pointer-events: none;
    }
  }

  > blockquote {
    p {
      margin-top: 0;
      font-style: italic;
      &:before {
        font-size: ${(p) => p.theme.fontSizeH2};
        line-height: 0.1;
        content: "„";
        position: relative;
        display: block;
        float: left;
        left: 0px;
        width: 2rem;
        color: ${(p) => p.theme.titleColorSolid};
      }
    }
    cite {
      display: block;
      text-transform: uppercase;
      font-style: normal;
      font-family: "Roboto Condensed";
      font-size: ${(p) => p.theme.fontSizeSuperSmall};
      @media screen {
        color: rgb(180, 180, 180);
      }
      @media print {
        color: rgb(80, 80, 80);
      }
      letter-spacing: 0.5px;
      text-align: right;
      margin: 1rem 0 0;
    }
  }

  ${TvOverlay} {
    display: none;
  }
  ${ImgWrapper} {
    background: white !important;
    padding: 1em;
    @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
      padding: 0;
    }
    figure {
      height: 100%;
      img {
        object-fit: contain;
        position: relative;
      }
    }
  }

  ${SecondaryWrapper} {
    h5 {
      // protagonist name
      position: sticky;
      // margin-top: 10em;
      top: 3em;
      text-shadow: none;
      z-index: -1;
      strong {
        background-color: black;
        &,
        a,
        svg {
          color: white !important;
        }
        box-shadow:
          0.5em 0 0 black,
          -0.5em 0 0 black;
      }
      @media screen and (min-width: ${(p) => p.theme.breakpointMobile}) {
        transform: translateX(-3em);
      }
    }
    p {
      box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    }
  }

  > .profile-container {
    > div {
      border-left: 3px solid ${(p) => p.color || "transparent"};
    }
  }

  & > figure {
    padding: 1em !important;
    background-color: white;
  }

  background-color: transparent !important;
`
