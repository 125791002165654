import styled from "styled-components"

export const FooterWrapper = styled.div`
  // margin-top: 4em;
  position: relative;

  @media print {
    display: none;
  }

  .ph-footer-iconmenu {
    ul {
      padding: 0;
      margin: 0;
    }
  }

  .ph-iconmenu-item {
    display: inline-block;
    svg {
      margin: 0 0.5em 0 0;
    }
    &:after {
      content: " – ";
      text-decoration: none;
      margin: 0 0.5em;
    }
    &:last-child {
      &:after {
        content: "";
        margin: 0;
      }
    }
  }

  .ph-footer {
    background-color: ${(p) => p.theme.bgDark};
    box-shadow: inset 0 0 50px rgb(0 0 0);
    color: ${(p) => p.theme.titleColorText};
    font-family: "Roboto Condensed";
    font-weight: 400;
    line-height: 2em;
    text-transform: uppercase;
    padding: 3em 0;
    text-align: center;
    font-size: ${(p) => p.theme.fontSizeSuperSmall};
    a {
      color: ${(p) => p.theme.titleColorText};
      text-decoration: underline;
    }
    .ph-footer-iconmenu .ph-iconmenu-link {
      text-decoration: none;
    }
  }

  .ph-footer-logos {
    padding: 15px ${(p) => p.theme.sidePaddingMobile} 25px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
    background-color: white;

    @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
      padding: 15px ${(p) => p.theme.sidePaddingMobile} 50px;
    }
  }

  .ph-footer-logos-text {
    color: black;
    flex-basis: 100%;
    text-align: center;
    font-family: "Roboto Condensed";
    font-weight: 700;
    font-size: ${(p) => p.theme.fontSizeSuperSmall};
    text-transform: uppercase;
    margin: 2em 0 1em;
  }

  .ph-footer-logo-link {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-basis: 50%;
    min-width: 250px;
    max-width: 340px;
    position: relative;

    @media screen and (min-width: ${(p) =>
        p.theme.breakpointMobile}) and (max-width: ${(p) =>
        p.theme.breakpointTablet}) {
      flex-basis: 33.3333%;
    }

    @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
      min-width: 200px;
    }
  }

  .ph-footer-logo-link:hover {
    img {
      opacity: 0;
    }
    /*&:before {
    content: '';
    display: block;
    background: $title-color-light; // rgba(255, 255, 255, 0.95);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
  }*/
    &:after {
      display: block;
      content: attr(data-popup);
      position: absolute;
      text-transform: none;
      left: 0;
      top: 50%;
      width: 100%;
      transform: translateY(-50%);
      font-size: ${(p) => p.theme.fontSizeSuperSmall};
      text-align: center;
      color: rgba(40, 40, 40, 1);
      background: transparent;
      line-height: 1.5em;
      padding: 5px;
      z-index: 5;
    }
  }

  .ph-footer-logo {
    box-sizing: border-box;
    padding: 1em 2em;
    width: 80%;

    @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
      padding: 1em 0.25em;
    }
  }
`
