import React from "react"
import { ScrollTrigger } from "./scroll-trigger"
import { ScrollTriggerWrapper } from "./scroll-trigger-wrapper"
import innerText from "react-innertext"
import { Script } from "gatsby"
import { v4 as uuidv4 } from "uuid"
import { Transformer, isElement } from "gatsby-source-dek-wp"
import { DOMNode } from "html-react-parser"

export const scrollTriggerTransformer: Transformer = (
  node,
  i,
  { classList, children: _children },
) => {
  if (
    classList.includes("wp-block-dekoder-custom-blocks-scroll-trigger-wrapper")
  ) {
    return (
      <ScrollTriggerWrapper
        className={`scroll-trigger-wrapper ${classList.join(" ")}`}
      >
        {_children}
      </ScrollTriggerWrapper>
    )
  } else if (
    classList.includes(
      "wp-block-dekoder-custom-blocks-scroll-trigger-container",
    )
  ) {
    const children = React.Children.toArray(_children).filter(isElement)
    const styleChildren = children.filter((c) => c?.type === "style")
    const otherChildren = children.filter(
      (c) => c?.type !== "style" && c?.type !== "pre",
    )

    // const filteredChildren = scriptReduce(children)

    const scrollTriggerSiblings = (
      (node.parent?.children as DOMNode[]) || [node]
    ).filter(
      (c) =>
        "name" in c &&
        "attribs" in c &&
        c.name === "div" &&
        c.attribs.class
          .split(" ")
          .includes("wp-block-dekoder-custom-blocks-scroll-trigger-container"),
    )
    const pos = scrollTriggerSiblings.indexOf(node)
    const element = (
      <ScrollTrigger
        isFirst={pos === 0}
        isLast={pos === scrollTriggerSiblings.length - 1}
        styleChildren={styleChildren}
      >
        {otherChildren}
      </ScrollTrigger>
    )

    const patchedElement = scriptReduceElement(element)
    return patchedElement
  }
}

export function scriptReduceElement(element: React.ReactElement) {
  // check children for script tags with custom callbacks
  // 1st script tag is passed as prop to container element
  // other script tags are passed as prop to the previous block
  const id = newId()
  const children = React.Children.toArray(element.props.children).filter(
    isElement,
  )
  let containerScript
  const reducedChildren = children.reduce((acc: any, c: any) => {
    if (c.type !== "script") return [...acc, c]
    else {
      const lastElement = acc?.[acc.length - 1]
      if (lastElement) {
        const patchedElement = addCallbackScriptToElement(lastElement, c)
        return [...acc.slice(0, acc.length - 1), patchedElement]
      } else {
        containerScript = c
        return acc
      }
    }
  }, [] as React.ReactElement[])

  const _patchedElement = React.cloneElement(element, {
    children: reducedChildren,
  })

  const newElement = containerScript
    ? addCallbackScriptToElement(_patchedElement, containerScript)
    : _patchedElement

  return newElement
}

function addCallbackScriptToElement(
  el: React.ReactElement,
  scriptEl: React.ReactElement,
) {
  const id = newId()
  const scriptCode = keyifyFunctions(
    scriptEl.props.dangerouslySetInnerHTML?.__html || "",
    id,
  )
  return React.cloneElement(el, {
    id,
    script: (
      <Script
        {...scriptEl.props}
        dangerouslySetInnerHTML={{ __html: scriptCode }}
      ></Script>
    ),
  })
}

function keyifyFunctions(scriptCode: string, key: string) {
  // function onEnter() -> function onEnter_b485279b_e3f7_4db1_900e_128c49bbcd19()
  return scriptCode
    .replace(/function onInit\(/i, `function onInit_${key}(`)
    .replace(/function onEnter\(/i, `function onEnter_${key}(`)
    .replace(/function onLeave\(/i, `function onLeave_${key}(`)
    .replace(/function onScroll\(/i, `function onScroll_${key}(`)
    .replace(/function onClick\(/i, `function onClick_${key}(`)
}

function newId() {
  return uuidv4().replace(/-/g, "_")
}
