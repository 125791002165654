import dekoderLogo from "./src/images/logos/dekoder-black.svg"

export {
  siteMetadata,
  restApi,
  graphqlApi,
  categoryIds,
  categoryNames,
} from "./meta-data-node"

export { siteLogos } from "./site-logos"

export const projectPartners = [
  {
    name: {
      de: "dekoder",
      en: "dekoder",
      ru: "декодер",
      uk: "декодер",
      be: "декодер"
    },
    text: { de: "", en: "", ru: "" },
    url: {
      de: "https://www.dekoder.org/",
      en: "https://www.dekoder.org/",
      ru: "https://www.dekoder.org/ru",
      uk: "https://www.dekoder.org/",
      be: "https://www.dekoder.org/"
    },
    logo: dekoderLogo,
    scale: 0.6,
  },
]
