import { css } from "styled-components"

const mainFont = "Know Your Product"
const secondaryFont = "Rock Salt"

const mediaTextAsAvatar = css`
  .wp-block-media-text {
    min-height: auto !important;
    .wp-block-media-text__media img {
      width: 100px;
      height: 100px;
      &.size-thumbnail {
        border-radius: 50%;
        object-fit: cover;
      }
    }
    @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
      display: flex;
      flex-direction: row !important;
      .wp-block-media-text__media {
        flex-shrink: 0.9;
        img {
          width: 80px;
          height: 80px;
        }
      }
      .wp-block-media-text__content {
        flex-shrink: 0.4;
        width: 100%;
        // padding: 0;
      }
    }
  }
`

export const belarusRockStyles = css`
  h1,
  h2,
  h4 {
    font-family: ${mainFont};
    text-transform: none;
    line-height: 1;
    letter-spacing: 0;
  }
  h1 {
    font-family: ${mainFont};
  }
  h2 {
    font-family: ${secondaryFont};
    font-size: ${(p) => p.theme.fontSizeH4} !important;
    @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
      font-size: ${(p) => p.theme.fontSizeH5} !important;
    }
  }
  h4 {
    font-family: ${mainFont};
  }
  blockquote {
    max-width: 900px !important;
    p {
      font-family: ${secondaryFont};
      font-style: normal;
      &:before {
        font-family: "Roboto Condensed";
      }
    }
  }
  figcaption strong,
  p strong {
    padding: 0 1px;
    font-family: ${mainFont};
  }

  ${mediaTextAsAvatar}

  .wp-block-media-text__content p {
    font-family: ${secondaryFont};
    font-size: 1rem;
  }

  h4.h3 {
    font-size: ${(p) => p.theme.fontSizeH4};
    max-width: 1000px;
    .toc-button-wrapper {
      color: ${(p) => p.theme.articleRed};
      opacity: 1;
    }
  }

  .carousel-wrapper {
    transition: 0.7s ease-in-out;
    .wp-block-embed {
      max-width: 800px;
      margin: 0 auto;
      padding: 2em;
      transition: 0.7s ease-in-out;
      > div > div {
        box-shadow: 5px 5px 30px 0px rgba(0, 0, 0, 0.6);
      }
      figcaption {
        margin-top: 2em;
        transition: 0.7s ease-in-out;
        color: white;
        a,
        a:hover,
        a:active,
        a:visited {
          color: inherit !important;
          border: none;
        }
      }
    }
  }
  .carousel-wrapper:not(.in-view) {
    background-color: rgba(240, 240, 240, 1) !important;
    box-shadow: none;
    .wp-block-embed,
    .wp-block-image {
      // box-shadow: none;
      figcaption {
        color: black;
      }
    }
  }
  .wp-block-separator.is-style-dots::after {
    font-family: ${secondaryFont};
  }
  .font-family-rock-salt {
    font-family: ${secondaryFont};
  }
  .font-family-rock-salt strong {
    font-family: ${secondaryFont};
    text-decoration: underline;
  }
  .carousel-wrapper .wp-block-image {
    max-width: 800px;
    margin: 0 auto !important;
    padding: 2em 0;
    // border: 1px dashed yellow;
    img {
      box-shadow: 5px 5px 30px 0px rgba(0, 0, 0, 0.6);
    }
    figcaption {
      margin-top: 2em;
      color: white;
      font-size: ${(p) => p.theme.fontSizeSmall};
      text-transform: none;
      letter-spacing: 0;
      font-weight: 300;
      a,
      a:hover,
      a:active,
      a:visited {
        color: inherit !important;
        border: none;
      }
    }
  }

  .toc-wrapper {
    font-family: ${mainFont};
    margin: 1em 0;
    .toc-list-item {
      line-height: 1.1;
      border-bottom: none;
      border-left: 3px solid transparent;
      padding-left: 1rem;
      &.active {
        border-color: ${(p) => p.theme.articleRed};
      }
    }
    .toc-list-item:not(:last-child) {
      margin-bottom: 1em;
    }
  }
`
