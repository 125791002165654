import { DOMNode, Element } from "html-react-parser"
import React from "react"

export interface TransformOptions {
  classList: string[]
  children: React.ReactNode[]
  style?: Record<string, string>
}

export type Transformer = (
  node: DOMNode,
  i: number,
  options: TransformOptions,
) => JSX.Element | null | undefined

export function isElement(c: any): c is React.ReactElement {
  return React.isValidElement(c)
}
