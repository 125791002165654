import { css } from "styled-components"

import { translateJournalismStyles } from "./special-styles/translate-journalism"
import { diarySpecialStyles } from "./special-styles/diary-special"
import { hlebaFontStyles } from "./special-styles/hleba-font"
import { belarusRockStyles } from "./special-styles/belarus-rock"
import { banjaStyles } from "./special-styles/banja"
import { myths90sStyles } from "./special-styles/90s-myths"
import { freedomProtestStyles } from "./special-styles/freedom-protest"
import { darkModeStyles } from "./dark-mode"
import { chronicleStyles } from "./special-styles/chronicle"
import { kurapatyStyles } from "./special-styles/kurapaty"
import { propagandaStyles } from "./special-styles/propaganda"
import { bellitStyles } from "./special-styles/bellit"
import { timelineStyles } from "./special-styles/timeline"

export const specialStyles = css`
  &.dark-mode {
    ${darkModeStyles}
  }
  &.banja {
    ${banjaStyles}
  }
  &.hleba-font {
    ${hlebaFontStyles}
  }
  &.translate-journalism {
    ${translateJournalismStyles}
  }
  &.diary-special {
    ${diarySpecialStyles}
  }
  &.belarus-rock {
    ${belarusRockStyles}
  }
  &.chronicle {
    ${chronicleStyles}
  }
  &.cat-90s-myths-de,
  &.cat-90s-myths-ru {
    ${myths90sStyles}
  }
  &.cat-freedom-protest-de,
  &.cat-freedom-protest-ru,
  &.cat-after-putin-de {
    ${freedomProtestStyles}
  }
  &.cat-kurapaty-de,
  &.cat-kurapaty-be {
    ${kurapatyStyles}
  }
  &.cat-propaganda-ru,
  &.cat-propaganda-de,
  &.cat-propaganda-en {
    ${propagandaStyles}
  }
  &.cat-bellit-de {
    ${bellitStyles}
  }
  &.timeline {
    ${timelineStyles}
  }
`
