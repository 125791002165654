import styled from "styled-components"
import { blockMargins } from "./bg-changer-wrapper.style"

export const BgChangerStyleWrapper = styled.div`
  > .bg-changer-blocks .wp-block-group__inner-container {
    /* 
    > p,
    > blockquote {
      ${blockMargins}
      padding: 2rem;
      background-color: white;
      box-shadow: 0 1px 20px rgba(0, 0, 0, 0.4);
      @media screen {
        margin-top: 100vh !important;
        box-shadow: 0 1px 20px rgba(0, 0, 0, 0.4);
      }
      @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
        padding: 1rem;
      }
      @media print {
        box-shadow: 0 1px 20px rgba(0, 0, 0, 0.1);
      }
    } 

    blockquote {
      @media print {
        margin: 0;
      }
      p {
        margin: 0;
        font-style: italic;
        em {
          font-style: normal;
        }
        &:before {
          font-size: ${(p) => p.theme.fontSizeH2};
          line-height: 0.1;
          content: "„";
          position: relative;
          display: block;
          float: left;
          left: 0px;
          width: 2rem;
          color: ${(p) => p.theme.titleColorSolid};
        }
      }
      cite {
        display: block;
        text-transform: uppercase;
        font-style: normal;
        font-family: "Roboto Condensed";
        font-size: ${(p) => p.theme.fontSizeSuperSmall};
        @media screen {
          color: rgb(180, 180, 180);
        }
        @media print {
          color: rgb(80, 80, 80);
        }
        letter-spacing: 0.5px;
        text-align: right;
        margin: 1rem 0 0;
      }
    }

    */
  }
`
