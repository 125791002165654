import styled from "styled-components"

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  > * {
    margin: 0 0.5rem;
    pointer-events: auto;
  }
  > button,
  .button {
    border: 1px dashed gray;
    font-size: ${(p) => p.theme.fontSizeSmall};
    padding: 0.5em;
    color: white;
    text-decoration: none;
    &.active {
      background-color: rgb(200, 200, 200);
    }
  }
`

export const SocialImgPreviewBg = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 500;
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: auto;
  ${Wrapper} button {
    color: black;
  }
`
