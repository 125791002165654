import React from "react"
import styled from "styled-components"
import { withVisComps } from "./vis-container"
import { BgChangerStyleWrapper } from "../bg-changer/bg-changer-wrapper.style"
// import { OverlayWrapper } from "../map/map.style"

const Wrapper = styled.div`
  // border: 1px dashed red;

  position: relative;
  margin: 3rem 0 3rem 0 !important;
  padding: 0 !important;
  width: 100% !important;
  max-width: none !important;

  ${BgChangerStyleWrapper} + &, &:first-child {
    margin-top: 0 !important;
  }
`

interface Props {
  children: React.ReactNode
  className?: string
}

export const ScrollTriggerWrapper = withVisComps(Wrapper)
