import { faRepeat } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  cloneElement,
} from "react"
import styled from "styled-components"

const FlipBtnWrapper = styled.button<{ isBack?: boolean }>`
  color: black;
  font-size: ${(p) => p.theme.fontSizeSmall};
  position: absolute;
  bottom: 0;
  right: 0;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(200, 200, 200, 0.6);
  svg {
    display: block;
  }
  backface-visibility: hidden;
  z-index: 2;
  &.is-back {
    right: auto;
    left: 0;
    transform: translateZ(-1px) rotateY(180deg) !important;
  }
`

export const FlipBtn = ({ onClick, isBack }) => (
  <FlipBtnWrapper
    onClick={onClick}
    className={isBack ? "is-back" : ""}
    aria-label={"Flip card"}
  >
    <FontAwesomeIcon icon={faRepeat} />
  </FlipBtnWrapper>
)

export default function useFlip(
  set: (_: any) => void,
  children?: React.ReactElement,
) {
  const [flipped, setFlipped] = useState(false)
  useEffect(() => {
    set({ yRot: flipped ? 180 : 0 })
  }, [flipped, set])
  const flip = useCallback(() => setFlipped((f) => !f), [])
  const child = useMemo(() => {
    if (!children) return
    return cloneElement(children, { flip, flipped })
  }, [children, flip, flipped])
  const btn = (
    <>
      <FlipBtn onClick={flip} />
      <FlipBtn onClick={flip} isBack={true} />
    </>
  )
  return [flip, child, btn] as const
}
