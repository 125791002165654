import React, { createContext, useMemo } from "react"
import {
  useAllContents,
  useContents,
  useLangs,
  LangContext,
  useSpecialTeaser,
  withAuth,
  SpecialTiles,
  Header,
  LangSwitcher,
  SiteLogo,
  ContentsContext,
  SelectedContext,
  usePrevNext,
  useThemeColor,
  withWebpCheck,
  SPECIAL_CATEGORIES,
  usePreviewGraphql,
  useMultiContents,
  AllContentsContext,
} from "gatsby-source-dek-wp"
import "./app.scss"
import Article from "../article/article"
import Footer from "../footer/footer"
import FrontIntro from "../front-intro/front-intro"
import { ThemeProvider } from "styled-components"
import { theme } from "./theme"
import { EditorMenu } from "../editor-menu/editor-menu"
import { Content } from "gatsby-source-dek-wp/hooks/contents"
import { getLogos } from "../../../site-logos"

interface PrevNext {
  prev: Content | null
  next: Content | null
  multiContents: Content[]
}

export const PrevNextContext = createContext<PrevNext>({
  prev: null,
  next: null,
  multiContents: [] as Content[],
})

const App = ({ data, pageContext: staticPageContext, metaData }) => {
  const { categoryIds, categoryNames, restApi, graphqlApi } = metaData
  const { postId } = staticPageContext
  const pageContext = usePreviewGraphql(
    staticPageContext,
    postId,
    graphqlApi,
    categoryNames,
  )

  const { langId, slug } = pageContext
  const allContents = useAllContents(data, false, categoryNames)

  const [lang, allLangs] = useLangs(langId, allContents)
  const [contents, selected] = useContents(allContents, lang.id, slug, [])
  const specialTeaser = useSpecialTeaser(data, lang.id, categoryNames)

  const multiContents = useMultiContents(selected, contents)

  const availableLangs = selected
    ? [selected.langId, ...selected.translations.map((t) => t.langId)]
        .map((lId) => allLangs.find((l) => l.id === lId))
        .filter((l) => l)
    : allLangs

  const isMultiSubPage = !!(
    selected &&
    !selected.categories.some(({ id }) => SPECIAL_CATEGORIES.includes(id))
  )
  const isMultiMainPage = !!(selected && selected.categories.length > 1)
  const parentSlug =
    isMultiSubPage || isMultiMainPage
      ? getParentSlug(selected, contents)
      : lang.id

  const [prev, next] = usePrevNext(
    contents,
    selected,
    isMultiMainPage || isMultiSubPage,
    SPECIAL_CATEGORIES,
  )

  const prevNextMultiContents = useMemo(
    () => ({ prev, next, multiContents }),
    [prev, next, multiContents],
  )

  const patchedTheme = useMemo(
    () => ({
      ...theme,
      themeColor: selected?.themeColor || theme.themeColor,
      darkMode: (selected?.bodyClass || "").includes("dark-mode"),
    }),
    [selected?.bodyClass, selected?.themeColor, theme],
  )

  const themeColorComp = useThemeColor(selected?.themeColor, theme)

  return (
    <LangContext.Provider value={lang}>
      <AllContentsContext.Provider value={allContents}>
        <ContentsContext.Provider value={contents}>
          <SelectedContext.Provider value={selected}>
            <PrevNextContext.Provider value={prevNextMultiContents}>
              <ThemeProvider theme={patchedTheme}>
                {themeColorComp}
                <Header className={pageContext.bodyClass}>
                  <SiteLogo
                    homePath={`/${lang.id}`}
                    logos={getLogos(selected)}
                    langId={lang.id}
                    absoluteLinksOnly={pageContext.absoluteLinksOnly}
                  />
                  <EditorMenu pageContext={pageContext} />
                  <LangSwitcher allLangs={availableLangs} selected={selected} />
                </Header>
                <div className="main-wrapper">
                  {!!selected && (
                    <Article
                      {...{
                        pageContext,
                        selected,
                        restApi,
                        graphqlApi,
                        isMultiSubPage,
                        isMultiMainPage,
                        parentSlug,
                        prev,
                        next,
                        multiContents,
                      }}
                    />
                  )}
                  <div className="front">
                    <FrontIntro />
                    <SpecialTiles contents={specialTeaser} localPrefix="" />
                  </div>
                </div>
                <Footer metaData={metaData} />
              </ThemeProvider>
            </PrevNextContext.Provider>
          </SelectedContext.Provider>
        </ContentsContext.Provider>
      </AllContentsContext.Provider>
    </LangContext.Provider>
  )
}

export default withWebpCheck(withAuth(App))

function getParentSlug(subPage: Content, contents: Content[]) {
  const categoryId = subPage.categories.find(
    ({ id }) => !SPECIAL_CATEGORIES.includes(id),
  )?.id
  const isInSpecials = (content: Content) =>
    content.categories.some(({ id }) => SPECIAL_CATEGORIES.includes(id))
  const parent =
    typeof categoryId !== "undefined" &&
    contents.find(
      (c) =>
        isInSpecials(c) &&
        c.categories.map(({ id }) => id).includes(categoryId),
    )
  return parent ? parent.slug : ""
}
