import React, { useContext } from "react"
import styled from "styled-components"
import GoaLogo from "./goa-logo"
import { LangContext } from "gatsby-source-dek-wp"

const FrontIntroWrapper = styled.div`
  margin: 0 1rem;
  padding: 3rem 0;
  @media print {
    display: none;
  }
`

const IntroText = styled.div`
  text-align: center;
  // font-family: "Lora", serif;
  line-height: 1.5em;
  font-size: ${(p) => p.theme.fontSizeSmall};
  color: rgb(230, 230, 230);
  font-style: italic;
  a {
    color: rgb(230, 230, 230) !important;
    text-decoration: none;
    border-bottom: 3px solid ${(p) => p.theme.articleRed};
    &:hover {
      border-bottom: 3px solid rgb(230, 230, 230);
    }
  }
`

interface IntroTranslationObj {
  [key: string]: JSX.Element
}

const intros: IntroTranslationObj = {
  de: (
    <>
      „Eine Spielwiese für Netzformate“ – unsere{" "}
      <a href="https://www.dekoder.org/de" target="_blank" rel="noreferrer">
        dekoder
      </a>
      -Specials
    </>
  ),
  ru: (
    <>
      Поле экспериментов – спецпроекты{" "}
      <a href="https://www.dekoder.org/ru" target="_blank" rel="noreferrer">
        dekoder.org
      </a>
    </>
  ),
  en: (
    <>
      Specials from{" "}
      <a href="https://www.dekoder.org/de" target="_blank" rel="noreferrer">
        dekoder.org
      </a>
    </>
  ),
}

const FrontIntro = () => {
  const lang = useContext(LangContext)
  return (
    <FrontIntroWrapper>
      <GoaLogo />
      <IntroText>{intros[lang.id]}</IntroText>
    </FrontIntroWrapper>
  )
}

export default FrontIntro
