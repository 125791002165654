import React, { useCallback, useState, useContext } from "react"
import { ExtraColumnPortal } from "gatsby-plugin-popup-preloader"
import { transformString } from "../../modules/transformer"
import { LangContext, Img } from "gatsby-source-dek-wp"

const MORE_LINK = {
  de: "Mehr auf dekoder.org",
  en: "More at dekoder.org",
  ru: "Читать на dekoder.org",
}

interface Person {
  name: string
  description?: string
  image?: string
  url?: string
}

export function usePersons(persons: Person[], prefix: string, metaRef) {
  const langId = useContext(LangContext).id
  const [hover, setHover] = useState<Person | undefined>(undefined)
  const [expPerson, setExpPerson] = useState<Person | undefined>(undefined)

  const setActive = useCallback((person: Person) => setHover(person), [])
  const setInactive = useCallback(() => setHover(undefined), [])
  const collapse = useCallback(() => setExpPerson(undefined), [])

  const imgsComp = (
    <>
      {persons.map((person, i) => {
        if (!person.image) return null
        const clickable = !!person.description
        const binds = clickable
          ? {
              onClick: () => setExpPerson(person),
              onMouseEnter: () => setHover(person),
              onFocus: () => setHover(person),
              onMouseLeave: () => setHover(undefined),
              onBlur: () => setHover(undefined),
            }
          : {}
        return (
          <span
            key={i}
            {...binds}
            className={`author-image ${
              expPerson?.name === person.name ? " active" : ""
            }${hover?.name === person.name ? " hover" : ""}${
              clickable ? " clickable" : ""
            }`}
          >
            <Img src={person.image} alt={person.name} />
          </span>
        )
      })}
    </>
  )

  const namesComp = (
    <>
      {!!persons.length && (
        <span className="meta-item person">
          {prefix}:{" "}
          {persons.map((person, i) => {
            const clickable = !!person.description
            const binds = clickable
              ? {
                  onClick: (ev: React.MouseEvent<HTMLAnchorElement>) => {
                    ev.preventDefault()
                    ev.stopPropagation()
                    setExpPerson(person)
                  },
                  onMouseEnter: () => setHover(person),
                  onFocus: () => setHover(person),
                  onMouseLeave: () => setHover(undefined),
                  onBlur: () => setHover(undefined),
                }
              : {}
            return (
              <span {...binds} className={clickable ? "clickable" : ""} key={i}>
                {!!person.url || clickable ? (
                  <a href={person.url || "#"} target="_blank">
                    {person.name}
                  </a>
                ) : (
                  transformString(person.name)
                )}
              </span>
            )
          })}
        </span>
      )}
    </>
  )

  const popupComp = (
    <>
      {!!expPerson && (
        <ExtraColumnPortal
          refEl={metaRef}
          type={`popup`}
          expanded={!!expPerson}
          hover={hover}
          collapse={collapse}
          onMouseOver={() => setActive(expPerson)}
          onFocus={() => setActive(expPerson)}
          onMouseOut={setInactive}
          onBlur={setInactive}
          options={{
            noTopShift: true,
          }}
        >
          {transformString(expPerson?.description)}
          {!!expPerson?.url && (
            <>
              {" "}
              <strong>
                →{"\u00A0"}
                <a
                  href={expPerson.url}
                  className="link dekoder-read-more-link"
                  target="_blank"
                >
                  {MORE_LINK[langId]}
                </a>
              </strong>
            </>
          )}
        </ExtraColumnPortal>
      )}
    </>
  )

  return [namesComp, popupComp, imgsComp] as const
}

//  {/*...binds} className={clickable ? "clickable" : ""*/}>
