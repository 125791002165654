import React, {
  useRef,
  useEffect,
  useState,
} from "react"
import {
  CompressorInner,
  CompressorWrapper,
  CompressorOuter,
  Overlay,
  HoverOverlay,
} from "./compressor.style"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExpand } from "@fortawesome/free-solid-svg-icons"
/* import {
  ExtraColumnDispatchContext,
  closeAllItems,
} from "gatsby-plugin-popup-preloader" */
import { useIntersectionObserver } from "../../../hooks/intersection-observer"

interface CompressorProps {
  children: React.ReactNode
  onExpand?: () => void
  hidden?: boolean
  hideFull?: boolean
}

export const Compressor = ({ children, onExpand, hidden, hideFull }: CompressorProps) => {
  const contentRef = useRef<HTMLDivElement>(null)
  const outerRef = useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState(0)
  const [inViewRef, inView] = useIntersectionObserver({ threshold: 0.3 })
  // useInView({ })
  const [expanded, setExpanded] = useState(false)
  const [position, setPosition] = useState("relative")
  useEffect(() => {
    if (!inView) return
    if (!contentRef.current) return
    // console.log("COMPR HEIGHT", contentRef.current.offsetHeight)
    setHeight(contentRef.current?.offsetHeight)
  }, [inView, contentRef])
  useEffect(() => {
    const onScroll = (e) => {
      if (!outerRef.current) return
      const { top } = outerRef.current.getBoundingClientRect()
      const { offsetHeight } = outerRef.current
      if (position !== "fixed" && -top > offsetHeight / 2) setPosition("fixed")
      if (position !== "relative" && -top <= offsetHeight / 2)
        setPosition("relative")
    }
    window.addEventListener("scroll", onScroll)
    return () => window.removeEventListener("scroll", onScroll)
  }, [contentRef, outerRef, position])
  useEffect(() => {
    if (position === "relative") setExpanded(false)
  }, [position])
  useEffect(() => {
    if (!expanded) return
    if (typeof onExpand === "function") onExpand()
  }, [expanded])

  return (
    <CompressorOuter ref={outerRef}>
      <CompressorWrapper ref={inViewRef} style={{ minHeight: `${height}px` }} hideFull={hideFull}>
        <CompressorInner
          ref={contentRef}
          className={`compressor ${position} ${
            expanded ? "expanded" : "collapsed"
          } ${hidden ? "hidden" : ""}`}
          onClick={
            position === "fixed" && !expanded ? () => setExpanded(true) : undefined
          }
        >
          {children}
          <HoverOverlay active={!expanded && position === "fixed"}>
            <FontAwesomeIcon className="expand-icon" icon={faExpand} />
          </HoverOverlay>
        </CompressorInner>
      </CompressorWrapper>
      <Overlay
        active={position === "fixed" && expanded}
        onClick={expanded ? () => setExpanded(false) : undefined}
      />
    </CompressorOuter>
  )
}

/* function withExtraColumnCloser(Comp) {
  return (props) => {
    const dispatch = useContext(ExtraColumnDispatchContext)
    const onExpand = useCallback(() => {
      dispatch(closeAllItems())
    }, [dispatch])
    return <Comp {...props} onExpand={onExpand} />
  }
}

export default withExtraColumnCloser(Compressor) */
