import { css } from "styled-components"
import { TitleInner } from "../../cover/cover.style"
import { coverShadows, darkModeStyles } from "../dark-mode"

export const freedomProtestStyles = css`
  ${TitleInner} {
    align-items: flex-start;
    h1,
    h2 {
      margin-left: 1rem !important;
      margin-right: 1rem !important;
      text-transform: none !important;
      letter-spacing: 0 !important;
      text-align: left !important;
      line-height: 1 !important;
    }
    h1 {
      font-size: 5.5rem !important;
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
      word-spacing: 50em;
      @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
        font-size: 3rem !important;
      }
    }
    h2 {
      font-size: 3rem !important;
      margin-top: 0 !important;
      margin-bottom: 1rem !important;
      @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
        font-size: 2rem !important;
      }
    }
  }
  ${coverShadows};
  .social-image ${TitleInner} {
    justify-content: flex-start;
  }
  &.multi-main-page {
    ${darkModeStyles}

    figure.aligncenter.size-medium {
      // logo
      padding: 1em;
      background: white;
      box-shadow: rgba(0, 0, 0, 0.8) 0px 0px 10px inset;
      max-width: 280px;
      img {
        vertical-align: middle;
      }
    }
  }
`
