import styled from "styled-components"

export const videoStyles: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translateX(-50%) translateY(-50%)",
  minWidth: "100%",
  minHeight: "100%",
  width: "auto",
  height: "auto",
  backgroundColor: "#151518", // theme.bgDark
}

export const TitleOuter = styled.div<{ bgColor?: string }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${(p) => p.bgColor || "rgba(0, 0, 0, 0.3)"};
  color: inherit; // ${(p) => (p.bgColor ? "black" : "white")};
`

export const TitleInner = styled.div`
  position: absolute;
  z-index: 2;
  left: 0;
  text-align: center;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  > * {
    max-width: 900px;
  }

  h1,
  h2 {
    &.has-white-color {
      color: white;
    }
  }
  h1 {
    text-transform: uppercase;
    font-size: ${(p) => p.theme.fontSizeH0 || "3.815em"} !important;
    margin: 0.67em 0 !important;
    font-weight: 800;

    letter-spacing: 0.05em;
    transform-origin: center;
    @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
      font-size: ${(p) => p.theme.fontSizeH2} !important;
    }
  }

  h2 {
    font-size: ${(p) => p.theme.fontSizeH4 || "1.563em"} !important;
    margin: 1em 0 !important;
    font-weight: bold;

    text-transform: uppercase;
    letter-spacing: 0.1em;
    line-height: 1.5em;
    @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
      font-size: ${(p) => p.theme.fontSizeH5} !important;
      letter-spacing: 0.08em;
    }
  }

  .has-text-align-left & {
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 5%;
    padding-left: 5%;
    padding-right: 5%;
    h1,
    h2 {
      text-transform: none;
      letter-spacing: 0;
      text-align: left;
      margin: 0 0 0.5em 0 !important;
    }
  }
`
