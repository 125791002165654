import React from "react"
import { Carousel } from "./carousel"
import { Transformer, isElement } from "../../types"

export const carouselTransformer: Transformer = (node, i, { classList, children }) => {
  if (
    classList.includes("wp-block-dekoder-custom-blocks-carousel") ||
    classList.includes("wp-block-dekoder-custom-blocks-gallery") // LEGACY
  ) {
    const patchedChildren = React.Children.toArray(children).filter(isElement)
      .map((c) => {
        if (c.type !== "figure") return c
        // disable lazy loading
        // TODO: add next img preload to component!
        const figChildren = React.Children.toArray(c.props.children).filter(isElement)
        const img = figChildren.find((el) => el.type === "img")
        if (!img) return c
        const patchedImg = React.cloneElement(img, {
          ...img.props,
          loading: "eager",
        })
        const patchedFigure = React.cloneElement(c, { ...c.props }, [
          patchedImg,
          ...figChildren.filter((fc) => fc !== img),
        ])
        return patchedFigure
      })
    const { preselected } = node.attribs
    return (
      <Carousel
        children={patchedChildren}
        classList={classList}
        infinite={node.attribs.infinite === "true"}
        dots={node.attribs.dots === "true"}
        showOverflow={node.attribs.showoverflow === "true" || classList.includes("show-overflow")}
        size={node.attribs.size}
        selKey={preselected ? preselected * 1 - 1 : 0}
      />
    )
  }
}
