import React, { useState, useCallback } from "react"
import { GeoJSONFeature, MapMouseEvent } from "mapbox-gl"
import { getLayer } from "./map"
import { MapLayerElement } from "./map-layer"
import { isMobile } from "../../modules/utils"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons"
import { faSquareXmark } from "@fortawesome/free-solid-svg-icons"

interface TooltipEntry {
  feature: GeoJSONFeature
  name: string
  color?: string
}

interface TooltipState {
  x: number
  y: number
  entries: TooltipEntry[]
}

const TOOLTIP_WIDTH = 250
const TOOLTIP_OFFSET = 20

export function useTooltip(layers: MapLayerElement[]) {
  const [tooltip, setTooltip] = useState<TooltipState | null>(null)
  const [cursor, setCursor] = useState<string>("auto")

  const [tooltipFixed, setTooltipFixed] = useState(false)

  const setTooltips = useCallback(
    (e: MapMouseEvent, reverse?: boolean) => {
      const {
        features,
        point: { x, y },
      } = e
      const fs = (reverse ? features?.reverse() : features) ?? []
      const entries =
        fs.map((feature) => {
          const layer = getLayer(layers, feature)
          const color = layer?.props.color
          const nameKey = getLayer(layers, feature)?.props.nameKey || "name"
          const name = feature?.properties?.[nameKey]
          return { feature, name, color }
        }) ?? []
      const mapWidth = e.target.getContainer().clientWidth
      const newX =
        x + TOOLTIP_WIDTH > mapWidth
          ? x - TOOLTIP_WIDTH - 2 * TOOLTIP_OFFSET
          : x
      if (!tooltipFixed)
        setTooltip(features?.length ? { x: newX, y, entries } : null)
    },
    [tooltipFixed],
  )

  const onClick = useCallback(
    (e: MapMouseEvent) => {
      if (!e.features?.length) {
        setTooltip(null)
      } else setTooltips(e)
      setTooltipFixed((prev) => !prev)
    },
    [tooltipFixed],
  )

  const onMouseEnter = useCallback(
    (e: MapMouseEvent) => {
      const { features } = e
      setTooltips(e, true)
      setCursor(features?.length ? "pointer" : "auto")
    },
    [layers, tooltipFixed],
  )
  const onMouseLeave = useCallback(() => {
    setCursor("auto")
    if (!tooltipFixed) setTooltip(null)
  }, [tooltipFixed])
  const close = tooltipFixed || isMobile() ? () => setTooltip(null) : undefined
  const tooltipComp = tooltip && (
    <Tooltip x={tooltip.x} y={tooltip.y} close={close}>
      {tooltip.entries.map((entry, i) => (
        <div
          key={i}
          className="font-bold px-2 py-1"
          style={{
            backgroundColor: entry.color !== "#ffffff" ? entry.color : "black",
            fontSize: "0.8rem",
            padding: ".5em",
            paddingRight: !!close && i === 0 ? "2em" : undefined,
          }}
          dangerouslySetInnerHTML={{ __html: entry.name }}
        >
          {/* entry.name */}
        </div>
      ))}
    </Tooltip>
  )
  return [onMouseEnter, onMouseLeave, onClick, tooltipComp, cursor] as const
}

interface TooltipProps {
  x: number
  y: number
  children: React.ReactNode
  close?: () => void
}

const Tooltip = ({ x, y, children, close }: TooltipProps) => (
  <div
    className={`map-tooltip`}
    style={{
      position: "absolute",
      // backgroundColor: "white",
      color: "white",
      width: isMobile() ? "100%" : `${TOOLTIP_WIDTH}px`,
      maxWidth: isMobile() ? "100%" : `${TOOLTIP_WIDTH}px`,
      maxHeight: isMobile() ? "200px" : "400px",
      overflow: "scroll",
      left: isMobile() ? 0 : x,
      top: isMobile() ? "auto" : y,
      bottom: isMobile() ? 0 : "auto",
      transform: isMobile()
        ? undefined
        : `translate3d(${TOOLTIP_OFFSET}px, ${TOOLTIP_OFFSET}px, 0)`,
      boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
      zIndex: 15,
    }}
  >
    {!!close && (
      <div style={{ fontSize: "0.8rem" }}>
        <button
          onClick={close}
          style={{
            color: "white",
            position: "absolute",
            top: "0.5em",
            right: "0.5em",
            zIndex: 2,
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          }}
        >
          <FontAwesomeIcon icon={faSquareXmark} />
        </button>
      </div>
    )}
    {children}
  </div>
)
