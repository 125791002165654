import { useEffect } from "react"
import { Visual } from "./bg-changer-wrapper"

export function useImagePreloader(visuals: Visual[], inView: boolean) {
  useEffect(() => {
    if (!visuals?.length) return
    const preloadArr = inView ? visuals : visuals.slice(0, 1) // preload only first image if not in view
    function preloadImage(src: string) {
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.onload = resolve
        img.onerror = reject
        img.src = src
      })
    }
    ;(async function () {
      for (const v of preloadArr) {
        if (v.type === "image") await preloadImage(v.src)
      }
    })()
  }, [visuals, inView])
}
