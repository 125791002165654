import React, { useState, useEffect, useContext } from "react"
import { debounce } from "throttle-debounce"
import styled from "styled-components"
import { ArticleRefContext } from "../article/article"

const ProgressBar = styled.div`
  position: sticky; // sticky;
  top: 0;
  left: 0;
  width: 0%;
  margin: 0 !important;
  max-width: none !important;
  background-color: ${(p) => p.theme.dossierYellow}; /* fallback */
  height: 5px;
  z-index: 15;
  transform: translate3d(0, 0, 0);
  .article-wrapper.no-reading-progress-bar & {
    display: none;
  }
`

interface Props {
  refEl?: HTMLDivElement
  color?: string
  id?: string
}

function ReadingProgressBar({ refEl, color, id }: Props) {
  const contextRef = useContext(ArticleRefContext)
  const ref = refEl || contextRef?.current
  const percent = useScrollPercent(ref)
  return (
    <ProgressBar
      id={id}
      className="reading-progress-bar"
      style={{ width: `${percent}%`, backgroundColor: color }}
    />
  )
}

export default ReadingProgressBar

function useScrollPercent(refEl: HTMLDivElement | null | undefined) {
  const [percent, setPercent] = useState(0)
  useEffect(() => {
    if (!refEl) return
    const debouncedOnScroll = debounce(5, () => {
      if (!document.scrollingElement) return
      const scrollPos = document.scrollingElement.scrollTop - refEl.offsetTop
      const max = refEl.scrollHeight - window.innerHeight
      const newPercent = Math.round((scrollPos / max) * 1000) / 10
      setPercent(Math.max(Math.min(newPercent, 100), 0))
    })
    document.addEventListener("scroll", debouncedOnScroll) // scrollWrapper
    return () => document.removeEventListener("scroll", debouncedOnScroll)
  }, [refEl])
  return percent
}
