import { lineString } from "@turf/helpers"
import length from "@turf/length"
import along from "@turf/along"
import mapboxgl, { type CameraOptions } from "mapbox-gl"
import { type MapboxMap } from "react-map-gl"

// returns a moveCamera function that can be used to interpolate between two camera options
export const getCameraMover =
  (map: MapboxMap) =>
  (from: CameraOptions, to: CameraOptions, percent: number) =>
    interpolateCamera(map, from, to, percent)

function interpolateCamera(
  map: MapboxMap,
  from: CameraOptions,
  to: CameraOptions,
  percent: number,
) {
  const _options = {
    zoom:
      isset(from.zoom) && isset(to.zoom)
        ? interpolate(from.zoom, to.zoom, percent)
        : undefined,
    pitch:
      isset(from.pitch) && isset(to.pitch)
        ? interpolate(from.pitch, to.pitch, percent)
        : undefined,
    bearing:
      isset(from.bearing) && isset(to.bearing)
        ? interpolate(from.bearing, to.bearing, percent)
        : undefined,
    center:
      isset(from.center) && isset(to.center)
        ? interpolateCoords(from.center, to.center, percent)
        : undefined,
  }
  const options = removeUndefinedProps(_options)
  map.jumpTo(options)
}

function isset<T>(v: T): v is NonNullable<T> {
  return v !== null && v !== undefined
}

function interpolate(first: number, last: number, percent: number) {
  const val = first + (last - first) * percent
  return val
}

function interpolateCoords(
  from: mapboxgl.LngLatLike,
  to: mapboxgl.LngLatLike,
  percent: number,
) {
  if (!isset(from) || !isset(to)) return
  const line = lineString([from as [number, number], to as [number, number]])
  const distance = length(line)
  const center = along(line, distance * percent).geometry.coordinates as [
    number,
    number,
  ]
  return center
}

type RemoveUndefined<T> = {
  [K in keyof T as Exclude<K, undefined>]: T[K]
}

function removeUndefinedProps(obj: CameraOptions) {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, v]) => v !== undefined),
  ) as RemoveUndefined<CameraOptions>
}
