import React, { useState, useEffect, useMemo, createContext } from "react"
import { FlexWrapper } from "./flex-box.style"
import { useContainerSize } from "./container-size"
import FlexItem from "./flex-item"
export { FlexItem }

const MIN_PADDING = 20

export interface FlexCtx {
  containerWidth: number
  itemsPerRow: number
  totalItems: number
  itemWidth: number
  itemHeight: number
  flexHook?: (_: any) => [number, number]
  containerHeight: number
}

export const FlexBoxContext = createContext<FlexCtx | null>(null)

export const FlexBox = ({
  children,
  itemWidth = 250,
  itemHeight = 250,
  maxPerRow = 4,
  flexHook,
}) => {
  const [containerRefSetter, containerWidth, refEl] = useContainerSize()
  const itemsPerRow = useItemsPerRow(containerWidth, itemWidth, maxPerRow)
  const containerHeight = useContainerMinHeight(
    children.length,
    itemsPerRow,
    itemWidth,
    itemHeight,
    containerWidth,
    refEl,
  )
  const ctxValue = useMemo(
    () => ({
      containerWidth,
      itemsPerRow,
      totalItems: children.length,
      itemWidth,
      itemHeight,
      flexHook,
      containerHeight,
    }),
    [
      containerWidth,
      itemsPerRow,
      children.length,
      itemWidth,
      itemHeight,
      flexHook,
      containerHeight,
    ],
  )
  return (
    <FlexWrapper
      ref={containerRefSetter}
      style={{ height: `${containerHeight}px` }}
    >
      <FlexBoxContext.Provider value={ctxValue}>
        {children}
      </FlexBoxContext.Provider>
    </FlexWrapper>
  )
}

function useContainerMinHeight(
  totalItems,
  itemsPerRow,
  itemWidth,
  itemHeight,
  containerWidth,
  refEl,
) {
  const [height, setHeight] = useState(100) // px // "100vh"
  useEffect(() => {
    const rows = Math.ceil(totalItems / itemsPerRow)
    const defaultRowWidth = itemsPerRow * itemWidth
    const v_padding = (containerWidth - defaultRowWidth) / (itemsPerRow + 2)
    const calculatedMinHeight = rows * (itemHeight + v_padding) // + 2 * v_padding + 100
    // const parentHeight = refEl?.parentNode?.offsetHeight || 0
    const newMinHeight = calculatedMinHeight
    // console.log({ rows })
    // TODO padding top ...
    setHeight(newMinHeight)
  }, [totalItems, itemsPerRow, itemWidth, itemHeight, containerWidth, refEl])
  return height
}

function useItemsPerRow(containerWidth, itemWidth, maxPerRow) {
  const [itemsPerRow, setItemsPerRow] = useState(1)
  useEffect(() => {
    const newItemsPerRow = Math.floor(
      containerWidth / (itemWidth + MIN_PADDING),
    )
    setItemsPerRow(Math.min(Math.max(newItemsPerRow, 1), maxPerRow))
  }, [containerWidth, itemWidth, maxPerRow])
  return itemsPerRow
}
