import React from "react"
import { FogOuter, FogContainer, Fog1, Fog2 } from "./fog-overlay.style"

// see https://github.com/WebDevSHORTS/Fog-Overlay-Animation

export const FogComp = () => (
  <FogContainer>
    <Fog1 />
    <Fog2 />
  </FogContainer>
)

interface Props {
  children: React.ReactNode
  classList?: string[]
}

export const FogImgWrapper = ({ children, classList = [] }: Props) => {
  return (
    <FogOuter
      className={classList.join(" ")}
      style={{ position: "relative", padding: 0, height: "100%" }}
    >
      <figure>{children}</figure>
      <FogComp />
    </FogOuter>
  )
}
