import React from "react"
import { ThreejsCanvasAsCover } from "./threejs-canvas-as-cover"
import { Transformer } from "gatsby-source-dek-wp"

export const threejsCanvasTransformer: Transformer = (
  node,
  i,
  { children, classList },
) => {
  if (classList.includes("wp-block-dekoder-custom-blocks-threejs-canvas"))
    return <ThreejsCanvasAsCover fixedKey="threejs-canvas" />
}
