import styled, { css } from "styled-components"
import { boxStyles } from "./map-point.style"

export const MapWrapper = styled.div`
  position: relative;

  &.map-type-fullscreen {
    margin: 2rem 0 2rem 0 !important;
    &:first-child {
      margin-top: 0 !important;
    }
    padding: 0 !important;
    height: 100vh;
    height: 100dvh;
    width: 100% !important;
    max-width: none !important;
  }

  &.map-type-box {
    height: 60vh;
    @media screen and (min-width: ${(p) => p.theme.breakpointMobile}) {
      height: 70vh;
    }
    max-width: 900px !important;
    padding: 0 !important;
    margin-top: 2rem;
    &.has-range-select {
      margin-top: 4rem;
    }
    margin-bottom: 2rem;
    margin-left: auto;
    margin-right: auto;
  }

  &.has-scroll-elements {
    height: auto;
  }

  & + .figcaption,
  & + p.has-small-font-size {
    margin-top: -1rem;
    margin-bottom: 2rem;
  }

  .mapboxgl-map {
    overflow: visible;
    @media screen and (max-width: ${(p) => p.theme.breakpointTablet}) {
      overflow-x: clip;
    }
    z-index: 9;
    // pointer-events: none;

    position: sticky !important;
    top: 0;

    font-size: 1rem !important;
    font-family: "Roboto Condensed" !important;

    .mapboxgl-ctrl-attrib {
      font-size: ${(p) => p.theme.fontSizeSuperSmall} !important;
      a,
      a:hover {
        color: black !important;
        text-decoration: none !important;
      }
    }

    .dark-mode & {
      .mapboxgl-ctrl-group {
        background: ${(p) => p.theme.bgDark};
        box-shadow: 2px 2px 10px rgba(255, 255, 255, 0.1);
        button span {
          filter: invert(100%);
        }
      }
      .mapboxgl-ctrl-attrib {
        a {
          color: white !important;
        }
        background: transparent;
      }

      .mapboxgl-popup {
        .mapboxgl-popup-content {
          background: ${(p) => p.theme.bgDark};
        }
        &.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
          border-top-color: ${(p) => p.theme.bgDark};
        }
        &.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
          border-bottom-color: ${(p) => p.theme.bgDark};
        }
        &.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
          border-right-color: ${(p) => p.theme.bgDark};
        }
        &.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
          border-left-color: ${(p) => p.theme.bgDark};
        }
      }
    }

    .mapboxgl-marker {
      // &.selected {
      svg path:nth-child(4) {
        fill: transparent; // no border
      }
      // }
      &:not(.selected) {
        // border: 1px solid blue;
        svg path {
          fill: #cacaca;
          // var(--marker-color),
        }
      }
    }
    .mapboxgl-popup {
      // z-index: 100;
      .mapboxgl-popup-content {
        padding: 0.8rem;
        .mapboxgl-popup-close-button {
          width: 20px;
        }
      }
    }

    .map-tooltip a {
      color: white !important;
      &:hover {
        border-color: white;
      }
    }
  }
`

export const figureStyles = css`
  min-height: 90vh;
  padding-bottom: 90vh;
  img {
    ${boxStyles}
  }
`

export const OverlayWrapper = styled.div`
  z-index: 10;
  position: relative;
  pointer-events: none;
  margin-top: -100vh; // calc(-100vh + 2em);
  min-height: 100dvh;
  transition: transform 0.3s ease-in-out;

  button {
    pointer-events: auto;
  }

  .map-not-locked & {
    // :not(.split-view)
    transform: translateX(-50vw); // -50
    @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
      transform: translateX(-95vw);
    }
  }

  > figure {
    margin-bottom: 90vh !important;
    padding: 1em !important;
    background-color: white;
    ${boxStyles}
  }

  .profile-container {
    min-height: 90vh;
    padding-bottom: 90vh;
    > .wp-block-group__inner-container {
      ${boxStyles}
      padding-right: 1.5em;
      h5,
      .profile-img {
        margin-top: 0;
      }
      h5 {
        font-size: ${(p) => p.theme.fontSizeH4};
        margin-bottom: 1em;
      }
      p {
        margin: 0;
      }
    }
  }
`
