import React, { createContext, useReducer } from "react"
import {
  MapPointState,
  MapPointAction,
  mapPointReducer,
} from "./map-point-reducer"

const defaultState: MapPointState = {
  mapPoints: [],
  currentPoint: undefined,
  routes: [],
}

export const MapPointContext = createContext([
  defaultState,
  (() => {}) as React.Dispatch<MapPointAction>,
] as const)

let previousY = 0 // to calcuclate intersection direction
export const getPreviousY = () => previousY
export const setPreviousY = (y: number) => (previousY = y)

export function withMapPoints(Comp: React.ComponentType<any>) {
  return (props: React.ComponentProps<any>) => {
    const mapRed = useReducer(mapPointReducer, defaultState)
    return (
      <MapPointContext.Provider value={mapRed}>
        <Comp {...props} />
      </MapPointContext.Provider>
    )
  }
}
