import React from "react"
import { BgChangerWrapper } from "./bg-changer-wrapper"
import { Transformer, isElement } from "gatsby-source-dek-wp"
import { scriptReduceElement } from "../scroll-trigger/scroll-trigger.transformer"

import BgChanger from "./bg-changer"

const bgChangerTransformer: Transformer = (
  node,
  i,
  { classList, children: _children },
) => {
  if (
    "attribs" in node &&
    (classList.includes("wp-block-dekoder-custom-blocks-background-changer") ||
      classList.includes(
        "wp-block-dekoder-custom-blocks-background-changer-video",
      ))
  ) {
    const children = React.Children.toArray(_children).filter(isElement)

    const figure = children.find((c) => c.type === "figure")
    if (!figure) return null
    const noCrop = classList.includes("no-crop")
    const noOverlay = classList.includes("no-overlay")
    const noAutoplay = classList.includes("no-autoplay")
    const figureChildren = React.Children.toArray(figure.props.children).filter(
      isElement,
    )
    const credit = figureChildren.find((c) => c.type === "figcaption")
    const visualComp = figureChildren[0]
    const src = visualComp.props.src
    const poster = visualComp.props.poster
    const type = figure.props.className.match(/wp-block-video/)
      ? "video"
      : "image"
    const tracks = type === "video" ? visualComp.props.children : []
    const noAudio = node.attribs?.hasaudio === "false"

    // bg-changer-blocks group: DEPRECATED
    const groupChild = children.find((c) =>
      (c?.props?.className || "").match(/(bg-changer-blocks)/),
    )
    const groupChildChildren = groupChild?.props?.children?.props?.children
    // new: all other children except the (first) main element
    const filteredChildren = children.filter(
      (c) =>
        !(c?.props?.className || "").match(/(bg-changer-img|bg-changer-video)/),
    )

    const bgChangerChildren = groupChildChildren
      ? React.Children.toArray(groupChildChildren).filter(isElement)
      : React.Children.toArray(filteredChildren).filter(isElement)

    const element = (
      <BgChanger
        children={bgChangerChildren}
        visual={{
          type,
          src,
          credit,
          tracks,
          poster,
          noCrop,
          noOverlay,
          noAudio,
          noAutoplay,
        }}
      />
    )
    const patchedElement = scriptReduceElement(element)
    return patchedElement
  } else if (
    classList.includes(
      "wp-block-dekoder-custom-blocks-background-changer-wrapper",
    )
  ) {
    const isFirst = !node.parent && !node.prev?.prev
    const isLast = !node.parent && !node.next
    const children = React.Children.toArray(_children).filter(isElement)
    return (
      <BgChangerWrapper
        isFirst={isFirst}
        isLast={isLast}
        children={children}
        classList={classList}
      />
    )
  }
}

export default bgChangerTransformer
