import styled from "styled-components"

export const Wrapper = styled.div`
  margin: 1.5em ${p => p.theme.toolSidePadding} 0;
  .MuiSlider-thumb:not(.MuiSlider-active), .MuiSlider-track {
    transition: left .3s ease-in-out, width .3s ease-in-out;
  }
  .MuiSlider-mark {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    // margin-top: -1px;
    &:not(.MuiSlider-markActive) {
      opacity: 0.5;
    }
  }
  .MuiSlider-rail {
    opacity: 0.2;
  }
  .MuiSlider-valueLabel {
    background-color: transparent;
    font-family: "Roboto Condensed";
    top: .3em;
    > span > span {
      color: ${(p) =>
        p.theme.darkMode ? p.theme.textColorLight : "black"};
    }
  }
  .dark-mode & {
    .MuiSlider-markActive {
      background-color: currentColor; // rgba(255,255,255,0.6);
    }
  }
`
