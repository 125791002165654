import { Dispatch, SetStateAction, createContext } from "react"

interface LockContextProps {
  isLocked: boolean
  setIsLocked: Dispatch<SetStateAction<boolean>>
}

export const LockContext = createContext<LockContextProps>({
  isLocked: true,
  setIsLocked: () => {},
})
