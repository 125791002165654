import styled, { css } from "styled-components"
import fog1 from "./fog-1.png"
import fog2 from "./fog-2.png"

const fogCommon = css`
  background-repeat: repeat-x;
  background-size: auto 100%;
  background-position: center;
  position: absolute;
  height: 100%;
  width: 300vw;
  z-index: 2;
`

export const FogOuter = styled.div`
  // margin: 0 !important;
  // max-width: none !important;
  figure {
    margin: 0;
    padding: 0;
    img {
      display: block;
    }
    figcaption {
      position: relative;
      background-color: white;
      z-index: 3;
      margin-top: 0;
      padding-top: 0.5em;
    }
  }
`

export const FogContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0;
  left: 0;
  overflow: hidden;
  margin: 0;
  padding: 0;

  @keyframes marquee {
    0% {
      transform: translate3d(0, 0, 0);
    }

    100% {
      transform: translate3d(-200vw, 0, 0);
    }
  }
`

export const Fog1 = styled.div`
  background: url(${fog1});
  animation: marquee 60s linear infinite;
  ${fogCommon}
`

export const Fog2 = styled.div`
  background: url(${fog2});
  animation: marquee 30s linear infinite;
  ${fogCommon}
`
