import React, { Suspense, useContext } from "react"
import { PrevNextContext } from "../app/app"
import { PageContext } from "gatsby-source-dek-wp"
import { FullscreenWrapper } from "../fullscreen-wrapper/fullscreen-wrapper"
import { MetaInfo } from "../meta-info/meta-info"
import ReadingProgressBar from "../reading-progress-bar/reading-progress-bar"

const ThreejsCanvas = React.lazy(() => import("./threejs-canvas"))

export const ThreejsCanvasAsCover = ({ fixedKey }: { fixedKey: string }) => {
  const pageContext = useContext(PageContext)
  const { prev, next, multiContents } = useContext(PrevNextContext)
  return (
    <>
      <FullscreenWrapper adjustToViewport={true}>
        <Suspense fallback={<div>Loading ...</div>}>
          <ThreejsCanvas contents={multiContents} prev={prev} next={next} />
        </Suspense>
      </FullscreenWrapper>
      <ReadingProgressBar id="text" />
      <h1>{pageContext.title}</h1>
      <MetaInfo />
    </>
  )
}
