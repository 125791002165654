import React, { useContext } from "react"
import { EmbedWrapper } from "./youtube.style"
import { LangContext } from "gatsby-source-dek-wp"
import { useAutoCaptions } from "./auto-captions"
import { useAutoPauseOnSlideChange } from "./auto-pause-on-slide-change"

interface YoutubeVideoProps {
  className?: string
  figcaption?: React.ReactNode
  src?: string
  title?: string
}

export const YoutubeVideo: React.FC<YoutubeVideoProps> = (props) => {
  const { className, figcaption, ...iframeProps } = props
  const lang = useContext(LangContext)
  const src = useAutoCaptions(iframeProps.src, lang.id)
  const ref = useAutoPauseOnSlideChange()
  return (
    <EmbedWrapper className={className}>
      <div>
        <div>
          <iframe
            {...iframeProps}
            ref={ref}
            title={iframeProps.title ?? ""}
            src={src}
          ></iframe>
        </div>
        {figcaption}
      </div>
    </EmbedWrapper>
  )
}
