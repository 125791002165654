import { useEffect } from "react"
import { isMobile } from "../../modules/utils"
import mapboxgl from "mapbox-gl"

function useFlyTo(dest, map, active: boolean) {
  useEffect(() => {
    if (!active) return
    if (!dest || !map) return
    if (!dest.lon || !dest.lat) return
    // console.log("FLY TO", dest)
    map.flyTo({
      center: [dest.lon, dest.lat],
      essential: true,
      speed: 1.0, // def 1.2
      zoom: dest.zoom * 1 || 13,
      offset: getOffset(),
    })
  }, [dest, map, active])
}

export default useFlyTo

export function getOffset() {
  const offset = isMobile() ? [0, 0] : [300, 0]
  return offset as [number, number]
}
