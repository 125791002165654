import React, { useEffect, useRef, useState, useMemo } from "react"
import Captions from "./captions"
import { Img } from "../../../plugins/gatsby-source-dek-wp/components/image/image"

let firstTouchTriggered = false

interface BgVideoProps {
  src?: string
  active?: boolean
  muted?: boolean
  tracks?: React.ReactNode[]
  poster?: string
  style?: React.CSSProperties
  isFirst?: boolean
  setMuted: (_: any) => void
  noAutoplay?: boolean
  setCurrVideoRef?: (_: any) => void
  objectPosition?: string
}

const BgVideo = ({
  src,
  active = true,
  muted = true,
  tracks = [],
  poster,
  style,
  isFirst,
  setMuted,
  noAutoplay,
  setCurrVideoRef,
  objectPosition,
}: BgVideoProps) => {
  const [isSuspended, setIsSuspended] = useState(false)
  const ref: React.MutableRefObject<HTMLVideoElement | null> = useRef(null)
  const className = active ? "active" : "inactive"

  useEffect(() => {
    if (typeof setCurrVideoRef === "function") setCurrVideoRef(ref)
  }, [active, setCurrVideoRef])

  useEffect(() => {
    // ;[].push(muted)
    if (!ref.current) return
    if (active && !noAutoplay) {
      ref.current
        .play()
        .then(() => setIsSuspended(false))
        .catch(() => setIsSuspended(true))
    } else ref.current.pause()
  }, [active, ref, muted, noAutoplay])

  const onTouchStart = () => {
    if (!iOS()) return
    if (!isFirst) return
    if (firstTouchTriggered) return
    firstTouchTriggered = true
    setMuted(false)
    setTimeout(() => {
      setMuted(true)
    }, 100)
  }

  const showPosterImg = (!!isSuspended && !!poster) || (!src && !!poster)
  return (
    <>
      {!!isFirst && <link rel="preload" as="video" href={src} />}
      {!!src && (
        <video // eslint-disable-line jsx-a11y/media-has-caption
          ref={ref}
          muted={muted}
          loop
          preload="auto"
          className={className}
          playsInline // important for iOS
          controls={false}
          crossOrigin="anonymous" // for VTT
          poster={poster}
          style={style}
          onClick={onTouchStart}
          onTouchStart={onTouchStart}
        >
          <source src={src} />
          {tracks}
        </video>
      )}
      {showPosterImg && (
        <Img
          className={className}
          src={poster}
          alt="-"
          style={{
            pointerEvents: "none",
            width: "100%",
            height: "100%",
            objectFit: "cover",
            objectPosition: objectPosition || "center center",
          }}
        />
      )}
      {!!tracks?.length && <Captions videoRef={ref} show={active} />}
    </>
  )
}

export default BgVideo

// https://stackoverflow.com/questions/9038625/detect-if-device-is-ios
function iOS() {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  )
}
