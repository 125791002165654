import { useRef, useEffect, useState } from "react"
import { useDeepCompareMemo } from "use-deep-compare"

interface StateProps {
  inView: boolean
  y: number | undefined
  direction: "up" | "down" | "none"
}

export function useIntersectionObserver(
  options: IntersectionObserverInit = {},
  existingRef?: React.RefObject<HTMLDivElement>,
  once?: boolean,
) {
  const newRef: React.RefObject<HTMLDivElement> = useRef(null)
  const ref = existingRef || newRef
  const memorizedOptions = useDeepCompareMemo(() => options, [options])
  const [state, setState] = useState<StateProps>({
    inView: false,
    y: undefined,
    direction: "none",
  })
  useEffect(() => {
    if (!ref.current) return
    const o = new IntersectionObserver(([entry], observer) => {
      const { y } = entry.boundingClientRect
      setState((oldState) => ({
        inView: entry.isIntersecting,
        y,
        direction:
          typeof oldState.y === "undefined"
            ? "none"
            : y > oldState.y
              ? "up"
              : "down",
      }))
      if (once && entry.isIntersecting) {
        observer.disconnect()
      }
    }, memorizedOptions)
    o.observe(ref.current)
  }, [ref, memorizedOptions, once])
  return [ref, state.inView, state.direction] as const
}
