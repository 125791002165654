import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faArrowAltCircleLeft,
  faArrowAltCircleRight,
} from "@fortawesome/free-solid-svg-icons"
import { ArrowButton } from "./arrow.style"

export const Arrow = (props) => (
  <ArrowButton {...props}>
    <FontAwesomeIcon
      icon={props.icon || (props.dir === "left" ? faArrowAltCircleLeft : faArrowAltCircleRight)}
    />
  </ArrowButton>
)