import { theme } from "../components/app/theme"

const { breakpointMobile, breakpointTablet } = theme

export const BREAKPOINT_MOBILE = parseInt(
  (breakpointMobile || "").replace("px", ""),
)
export const BREAKPOINT_TABLET = parseInt(
  (breakpointTablet || "").replace("px", ""),
)

export function isMobile() {
  if (typeof window === "undefined") return true
  return window.innerWidth <= BREAKPOINT_MOBILE
}

export function isTablet() {
  if (typeof window === "undefined") return true
  return window.innerWidth <= BREAKPOINT_TABLET
}

export function isTouchDevice() {
  return "ontouchstart" in document.documentElement
}

export const delay = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms))

export function getFormatedDate(dateString: string) {
  const date = new Date(dateString)
  const dd = `${date.getDate()}`.padStart(2, "0")
  const mm = `${date.getMonth() + 1}`.padStart(2, "0")
  const yyyy = date.getFullYear()
  return dd + "." + mm + "." + yyyy
}
