// import React, { useEffect, useState } from "react"
import styled from "styled-components"

export const FullscreenWrapper = styled.div<{
  adjustToViewport?: boolean
  bgColor?: string
}>`
  width: 100%;
  height: ${(p) =>
    p.adjustToViewport ? `calc(100vh - ${p.theme.headerHeight})` : "100%"};
  position: relative;
  overflow: hidden;
  background-color: ${(p) => p.bgColor || "transparent"};
  margin: 0 !important;
  margin-bottom: 0 !important;
  padding: 0;
  max-width: none !important;
  // border: 2px dashed yellow;

  @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
    padding: 0 !important;
  }

  @media print {
    height: auto;
  }
`
