import React, { useContext } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { LangContext } from "gatsby-source-dek-wp"
import { Content } from "gatsby-source-dek-wp/hooks/contents"

const TO_MAIN_PAGE: { [_: string]: string } = {
  de: "zur Übersicht",
  en: "to main page",
  ru: "на главную",
  uk: "на головну",
  be: "на галоўную",
}

const Wrapper = styled.div`
  display: flex;
  margin-top: 4em !important;
  margin-bottom: 4em !important;
  justify-content: space-between;
  font-size: ${(p) => p.theme.fontSizeSmall};
  a,
  a:hover,
  a:visited,
  a:active {
    color: white !important;
    font-weight: 700;
    padding: calc(1em - 3px) 0.5em;
    width: 100%;
    text-align: center;
    margin: 0.5em;
    @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
      margin: 0.1em;
    }
    border-top: 3px solid transparent !important;
    border-bottom: 3px solid transparent !important;
  }
  a {
    background-color: #696a6e;
    box-shadow: 0 1px 20px rgba(0, 0, 0, 0.2);
    &:hover {
      border-bottom-color: ${(p) => p.theme.articleRed} !important;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    /* a:first-child, a:last-child {
      justify-content: space-between;
    } */
  }
`

const Title = styled.span`
  display: none;
  margin: 0 0.5em;
  @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
    display: none;
  }
`

interface Props {
  prev: Content
  next: Content
  parent: Content
}

export const NavBar = ({ prev, next, parent }: Props) => {
  const lang = useContext(LangContext)
  const toMainPage = TO_MAIN_PAGE[lang.id] || TO_MAIN_PAGE["en"]
  return (
    <Wrapper className="nav-bar">
      <Link to={`/${prev?.slug}`}>
        <FontAwesomeIcon icon={faArrowLeft} />
        <Title>{prev?.title}</Title>
      </Link>
      {!!parent && <Link to={`/${parent}`}>{toMainPage}</Link>}
      <Link to={`/${next?.slug}`}>
        <Title>{next?.title}</Title>
        <FontAwesomeIcon icon={faArrowRight} />
      </Link>
    </Wrapper>
  )
}
