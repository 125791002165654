import { useContext } from "react"
import { useDeepCompareMemo } from "use-deep-compare"
import { queryRes2Context } from "../query-res-2-content"
import { AuthContext } from "./auth"
import { SPECIAL_CATEGORIES } from "../constants"
import { isInCategoryIds } from "./contents"
import { DataProp } from "./contents"
import { LangDef } from "./langs"

export const useSpecialTeaser = (data: DataProp, langId: LangDef["id"], categoryNames: string[]) => {
  const isAuthed = useContext(AuthContext)
  const nodes = data.allWpPost?.nodes
  const teasers = useDeepCompareMemo(
    () =>
      (nodes || [])
        .filter(n => isInCategoryIds(n, SPECIAL_CATEGORIES))
        .map((n) => queryRes2Context(n, categoryNames))
        .filter((n) => (!!langId ? n.langId === langId : n))
        .filter((n) => (isAuthed ? n : n.published))
        .sort((a, b) => b.orderId - a.orderId),
    [nodes, langId, isAuthed]
  )
  return teasers
}
