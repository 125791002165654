import React from "react"
import { isElement, Transformer } from "gatsby-source-dek-wp"
import { MapLayer } from "./map-layer"
import {
  CircleLayerSpecification,
  FillLayerSpecification,
  LineLayerSpecification,
  SymbolLayerSpecification,
} from "mapbox-gl"
import innerText from "react-innertext"
import { Script } from "gatsby"

export interface LayerAttribs {
  id: string
  label?: string
  type: "circle" | "line" | "fill" | "symbol"
  color?: string
  isactive: "true" | "false"
  isinteractive: "true" | "false"
  [key: string]: any
}

export const mapLayerTransformer: Transformer = (
  node,
  i,
  { children: _children, classList },
) => {
  if (
    classList.includes("wp-block-dekoder-custom-blocks-map-geojson") &&
    "attribs" in node
  ) {
    const childEls = React.Children.toArray(_children).filter(isElement)
    const { id, label, type, color, isactive, isinteractive } =
      node.attribs as LayerAttribs
    const geojsonUrl =
      childEls.find((c) => c?.type === "div")?.props.children?.[0]?.props
        ?.href || ""

    const codeBlock = childEls.find((c) => c?.type === "pre")
    let customProps = { paint: {}, layout: {} }
    if (codeBlock) {
      let innerStr = ""
      try {
        // TODO: add validation?
        innerStr = innerText(codeBlock)
        const obj = JSON.parse(innerStr)
        customProps.layout = obj.layout ? { ...obj.layout } : {}
        customProps.paint = obj.paint
          ? { ...obj.paint }
          : obj.layout
            ? {}
            : { ...obj } // if only paint is provided as a root object
      } catch (e) {
        console.error(
          "MapLayer: custom props must be valid JSON. Please check:",
          innerStr,
        )
      }
    }
    const { paint, layout } = customProps

    const layerProps = getLayerProps({
      id,
      type,
      color: color ?? "#ff0000",
      paint,
      layout,
      isactive,
      isinteractive,
    })
    if (!layerProps) {
      console.error(`Wrong map layer type ${type}`)
      return null
    }
    const scriptEl = childEls.find((c) => c?.type === "script")
    return (
      <MapLayer
        spreadsheetId={node.attribs.spreadsheetid}
        geojsonUrl={geojsonUrl}
        label={label ?? id}
        layerProps={layerProps}
        isActive={isactive === "true"}
        isInteractive={isinteractive === "true"}
        color={color}
        nameKey={node.attribs.namekey ?? "name"}
        descriptionKey={node.attribs.descriptionkey ?? "description"}
        scriptEl={
          !!scriptEl
            ? keifyScriptEl(scriptEl, id, [
                "onInit",
                "onClick",
                "onRangeChange",
              ])
            : undefined
        }
      />
    )
  }
}
export function keifyScriptEl(
  scriptEl: React.ReactElement,
  key: string,
  funcNames = ["onInit", "onEnter", "onLeave", "onScroll", "onClick"],
) {
  const scriptCode = keyifyFunctions(
    scriptEl.props.dangerouslySetInnerHTML?.__html ?? "",
    key,
    funcNames,
  )
  return (
    <Script // TODO: Gatsby Script?
      {...scriptEl.props}
      dangerouslySetInnerHTML={{ __html: scriptCode }}
    ></Script>
  )
}

function keyifyFunctions(
  scriptCode: string,
  _key: string,
  funcNames: string[],
) {
  // function onEnter() -> function onEnter_b485279b_e3f7_4db1_900e_128c49bbcd19()
  const key = safeKey(_key)
  for (const funcName of funcNames) {
    scriptCode = scriptCode.replace(
      new RegExp(`function ${funcName}\\(`, "g"),
      `function ${funcName}_${key}(`,
    )
  }
  return scriptCode
}

export function safeKey(key: string) {
  return key.replace(/-/g, "_")
}

function getLayerProps({
  id,
  type,
  color,
  paint,
  layout,
}: LayerAttribs & {
  color: string
  paint: Record<string, number>
  layout: Record<string, number>
}) {
  if (type === "circle") {
    return {
      id,
      source: id,
      type,
      layout: {
        ...layout,
      },
      paint: {
        "circle-radius": ["interpolate", ["linear"], ["zoom"], 7, 5, 10, 12], // zoom7: 5px, zoom10: 12px
        "circle-color": color,
        ...paint,
      },
    } as CircleLayerSpecification
  } else if (type === "fill") {
    return {
      id,
      source: id,
      type,
      layout: {
        ...layout,
      },
      paint: {
        "fill-color": color,
        ...paint,
      },
    } as FillLayerSpecification
  } else if (type === "line") {
    return {
      id,
      source: id,
      type,
      layout: {
        ...layout,
      },
      paint: {
        "line-color": color,
        ...paint,
      },
    } as LineLayerSpecification
  } else if (type === "symbol") {
    return {
      id,
      source: id,
      type,
      layout: {
        "text-field": "{title}",
        ...layout,
      },
      paint: {
        "text-color": color,
        ...paint,
      },
    } as SymbolLayerSpecification
  }
}
