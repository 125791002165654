import React from "react"
import { MediaText } from "./media-text"

export function mediaTextTransformer(node, i, { classList, children }) {
  if (classList.includes("wp-block-media-text")) {
    const patchedChildren = children.map(c => {
      if (c.type !== "figure") return c
      // disable lazy loading
      const img = React.Children.toArray(c.props.children).find(el => el.type === "img")
      if (!img) return c
      const patchedImg = React.cloneElement(img, {...img.props, loading: "eager"})
      const patchedFigure = React.cloneElement(c, {...c.props}, [patchedImg])
      return patchedFigure
    })
    return <MediaText children={patchedChildren} classList={classList} />
  }
}
