import * as React from "react"
import { isElement, Transformer } from "gatsby-source-dek-wp"
import { SplitView } from "./split-view"

export const splitViewTransformer: Transformer = (
  node,
  i,
  { children: _children, classList },
) => {
  if (
    classList.includes("wp-block-dekoder-custom-blocks-split-view") &&
    "attribs" in node
  ) {
    const childEls = React.Children.toArray(_children).filter(isElement)
    const firstChild = childEls[0]
    const otherChildren = childEls.slice(1)
    return (
      <SplitView firstChild={firstChild} className={classList.join(" ")}>
        {otherChildren}
      </SplitView>
    )
  }
}
