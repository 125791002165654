import styled from "styled-components"

export const SplitViewWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: none !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  overflow-x: clip;
  .split-view__primary {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
  }
  .split-view__secondary {
    position: relative;
    margin-top: -100vh;
    height: 100%;
    pointer-events: none;

    transition: transform 0.3s ease-in-out;
    .map-not-locked & {
      transform: translateX(-50vw); // -50
      @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
        transform: translateX(-95vw);
      }
    }
  }
`
