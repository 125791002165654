import React from "react"
import { InternalTeaser } from "./internal-teaser"
import { isElement, Transformer } from "gatsby-source-dek-wp"

export const internalTeaserTransformer: Transformer = (
  node,
  i,
  { classList, children },
) => {
  if (classList.includes("wp-block-dekoder-custom-blocks-internal-teaser")) {
    const { title, kicker, postid, url, comingsoon } = node.attribs
    const { bgimageurl, bgimageurlsmall, template } = node.attribs
    // deprecatedId: from Magical Posts Accordion block
    const _deprecatedId =
      React.Children.toArray(children)
        .filter(isElement)
        .find((c) => c?.props && c.props.hasOwnProperty("data-post-id"))
        ?.props?.["data-post-id"] * 1
    const id = postid ? parseInt(postid) : _deprecatedId
    // if (!id) console.error("no id for internal teaser", node)
    const fullscreen =
      template === "fullscreen" || classList.includes("fullscreen")
    const comp = (
      <InternalTeaser
        postId={id}
        title={title}
        kicker={kicker}
        fullscreen={fullscreen}
        bgImageUrl={bgimageurl}
        bgImageUrlSmall={bgimageurlsmall}
        template={template}
        externalUrl={url}
        comingSoon={comingsoon === "true"}
        backText={children}
      />
    )
    return comp
  }
}
