import React from "react"
import { Transformer } from "gatsby-source-dek-wp"
import { MapRangeSelect } from "./range-select"

export const mapRangeSelectTransformer: Transformer = (
  node,
  i,
  { children: _children, classList },
) => {
  if (
    classList.includes("wp-block-dekoder-custom-blocks-map-range-select") &&
    "attribs" in node
  ) {
    return (
      <MapRangeSelect
        initialMin={
          node.attribs.initialmin
            ? parseInt(node.attribs.initialmin)
            : undefined
        }
        initialMax={
          node.attribs.initialmax
            ? parseInt(node.attribs.initialmax)
            : undefined
        }
        min={parseInt(node.attribs.min)}
        max={parseInt(node.attribs.max)}
      />
    )
  }
}
