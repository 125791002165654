import { faChevronDown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import styled from "styled-components"

const ButtonWrapper = styled.button`
  display: block;
  position: absolute;
  top: 80vh;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
`

export const ScrollDownButton = () => (
  <ButtonWrapper>
    <FontAwesomeIcon icon={faChevronDown} />
  </ButtonWrapper>
)
