import React from "react"
import { faVolumeMute, faVolumeUp } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styled from "styled-components"

interface WrapperProps {
  show?: boolean
  muted?: boolean
}

export const MUTE_BTN_HEIGHT = "45px"

const MuteButtonWrapper = styled.button<WrapperProps>`
  position: sticky;
  top: calc(100vh - ${MUTE_BTN_HEIGHT} - 20px);
  height: ${MUTE_BTN_HEIGHT};
  margin-bottom: 20px;
  margin-right: 20px;
  left: calc(100% - 70px);
  z-index: 5;
  font-size: ${(p) => p.theme.fontSizeH3};
  color: white;
  transition: opacity 1s ease-in-out;
  opacity: ${(p) => (p.show ? 1 : 0)};

  &.is-shown svg {
    animation: ${(p) => (p.muted ? "blink 1.6s infinite" : "none")};
  }

  @keyframes blink {
    0%,
    30%,
    100% {
      transform: scale(1, 1);
    }
    20% {
      transform: scale(1.2, 1.2);
      opacity: 0.7;
    }
    50% {
      transform: scale(1.3, 1.3);
      opacity: 0.9;
    }
  }
`

interface Props {
  muted?: boolean
  onClick?: () => void
  show?: boolean
}

const MuteButton = ({ muted, onClick, show }: Props) => (
  <MuteButtonWrapper
    className={`mute-button ${show ? "is-shown" : "is-hidden"}`}
    show={show}
    muted={muted}
    onClick={onClick}
    aria-label={muted ? "Unmute" : "Mute"}
  >
    <FontAwesomeIcon icon={muted ? faVolumeUp : faVolumeMute} />
  </MuteButtonWrapper>
)

export default MuteButton
