import { Transformer, isElement } from "gatsby-source-dek-wp"
import { domToReact } from "html-react-parser"
import React from "react"
import innerText from "react-innertext"

export const splitWordsTransformer: Transformer = (
  node,
  i,
  { classList, children: _children },
) => {
  const children = _children.filter(isElement)
  const hasMark = !!children.find((c) => c.type === "mark")
  if (classList.includes("split-words") && !!hasMark) {
    const el = domToReact([node])
    const patchedEl = React.cloneElement(el, {
      children: _children.map((c) => {
        if (isElement(c) && c.type === "mark") {
          const text = innerText(c)
          const { style = {} } = c.props
          const markColor = style.backgroundColor || "transparent"
          return (
            <mark
              {...c.props}
              style={{
                ...style,
                backgroundColor: "transparent",
                "--mark-color": markColor,
              }}
            >
              {text.split(" ").map((word, i) => (
                <>
                  <span key={i}>{word}</span>{" "}
                </>
              ))}
            </mark>
          )
        } else return c
      }),
    })
    return patchedEl
  }
}
