import styled, { css } from "styled-components"
import { MUTE_BTN_HEIGHT } from "../bg-video/mute-button"

export const blockMargins = css`
  @media screen and (min-width: ${(p) => p.theme.breakpointTablet}) {
    max-width: ${(p) => p.theme.blockMaxWidth};
    margin: 0 ${(p) => p.theme.blockMarginLeftRight}
      ${(p) => p.theme.blockMarginBottom};
  }

  @media screen and (max-width: ${(p) => p.theme.breakpointTablet}) {
    max-width: ${(p) => p.theme.blockMaxWidthTablet};
    margin-left: ${(p) => p.theme.blockMarginLeftRightTablet};
    margin-right: ${(p) => p.theme.blockMarginLeftRightTablet};
  }

  @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
    // padding: 0 15px ${(p) => p.theme.blockMarginBottom};
    margin: 0 10px;
    max-width: none;
  }

  &.block-align-center {
    @media screen and (min-width: ${(p) => p.theme.breakpointMobile}) {
      margin-left: auto !important;
      margin-right: auto !important;
    }
  }
  &.block-align-right {
    @media screen and (min-width: ${(p) => p.theme.breakpointMobile}) {
      margin-left: auto !important;
    }
  }

  @media print {
    margin: 0 15px ${(p) => p.theme.blockMarginBottom} !important;
    max-width: none !important;
  }
`

export const BgChangerStyleWrapper = styled.div`
  position: relative;
  margin: 0 !important; // 0 2em !important;
  padding: 0 !important;
  width: 100% !important;
  max-width: none !important;
  &.bg-dark {
    @media screen {
      background-color: black;
    }
  }
  & + p {
    margin-top: 3rem !important;
  }
  & + .reading-progress-bar + p {
    margin-top: 3rem !important;
  }
  p + & {
    margin-top: 3rem !important;
  }
`

export const SecondaryWrapper = styled.div`
  z-index: 6;
  position: relative;
  @media screen {
    padding-bottom: 50vh; // 80vh;
    margin-top: -55vh; // -85
  }
  pointer-events: none;

  .title-wrapper {
    padding: 1rem;
    @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
      padding: 0;
    }
    // border: 1px dashed blue;
    @media screen {
      margin-top: calc(55vh - 50px); // 85vh - 50px
      transform: translateY(-100%);
    }
    > div {
      // background-color: rgba(0,0,0,0.1);
      padding: 1rem;
    }
    h1,
    h5 {
      margin-top: 0 !important;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      @media screen {
        max-width: 900px !important;
      }
    }
    h1 {
      margin-bottom: 1rem !important;
    }
    h5 {
      margin-bottom: 0 !important;
    }
    @media print {
      margin-bottom: 2rem;
    }
  }

  p,
  h1,
  h2,
  h5,
  blockquote {
    pointer-events: auto;
  }

  h1,
  h2,
  h5 {
    @media screen {
      color: white;
      text-shadow: 1px 1px rgba(0, 0, 0, 0.4);
      a,
      a:hover,
      a:active,
      a:visited {
        color: white !important;
      }
    }
    // text-shadow: 1px 1px rgba(255, 255, 255, 0.3);
    padding: 0 10px;
    ${blockMargins}
    &.has-text-align-center {
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }
    @media print {
      color: black;
      text-shadow: none;
    }
  }

  h1 {
    // title
    // margin: 2em auto 1em;
    font-size: ${(p) => p.theme.fontSizeH1};
    &.has-text-align-center {
      margin-top: 2em;
    }
    @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
      font-size: ${(p) => p.theme.fontSizeH2};
      margin-bottom: 1em;
    }
  }

  h5 {
    // subtitle
    // margin: 0 auto 2em;
    font-size: ${(p) => p.theme.fontSizeH5};
  }

  .h2 {
    // monthes
    max-width: ${(p) => p.theme.blockMaxWidth};
    text-align: center;
    @media screen {
      color: white;
      // margin: 100vh auto !important;
    }
    font-size: ${(p) => p.theme.fontSizeH2};

    svg {
      display: none;
    }
  }
`

export const ImgWrapper = styled.div`
  z-index: 0;
  background-repeat: no-repeat;
  top: 0%;
  margin-top: -${parseInt(MUTE_BTN_HEIGHT.replace("px", "")) + 1 + 20}px; // 20: margin-bottom
  left: 0;
  width: 100%;
  @media screen {
    position: sticky;
    height: 100vh;
  }
  figure {
    padding: 0;
    margin: 0;
    img {
      pointer-events: none !important;
    }
    figcaption {
      background-color: rgba(0, 0, 0, 0.4);
      color: rgb(220, 220, 220) !important;
      font-size: ${(p) => p.theme.fontSizeSuperSmall};
      padding: 0.1em 0.5em;
      a,
      a:visited {
        color: rgb(220, 220, 220) !important;
      }
      ${BgChangerStyleWrapper}.no-crop &,
      ${BgChangerStyleWrapper}.figcaption-centered & {
        min-height: 2rem;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 1000px;
      }
    }
  }
  @media screen {
    img,
    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: opacity 1s ease-in-out;
      opacity: 0;
      pointer-events: none;
      &.active {
        opacity: 1;
        pointer-events: auto;
      }
    }

    ${BgChangerStyleWrapper}.no-crop & {
      img {
        object-fit: contain;
        padding: 2rem;
        @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
          padding: 0;
        }
      }
    }
    img.no-crop {
      object-fit: contain;
      padding: 2rem;
      @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
        padding: 0;
      }
    }
    ${BgChangerStyleWrapper}.bg-dark & {
      // background: ${(p) => p.theme.bgDark} !important;
      background: black !important;
    }
  }

  @media print {
    position: relative;
    margin-bottom: 2rem !important;
    text-align: center;
    figure {
      img {
        object-fit: contain;
        max-height: 35vh;
        width: auto;
        padding: 0;
        page-break-before: auto;
        page-break-after: auto;
        page-break-inside: avoid;
        &:not(.active) {
          display: none;
        }
      }
      figcaption {
        min-height: 2rem;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 1000px;

        background-color: transparent;
        color: black;
      }
    }
  }
`

export const CreditWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 3;
  ${BgChangerStyleWrapper}.no-crop &,
  ${BgChangerStyleWrapper}.figcaption-centered & {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  @media print {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
  }
`
