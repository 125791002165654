import React, { Suspense } from "react"
import { MethodsText } from "./methods-text"
import { TermFrequencyToolMeta } from "./meta"
import { Compressor } from "./compressor/compressor"
import { parseMarkerStr } from "./term-setter/term-setter.transformer"

const TermFreqTool = React.lazy(() =>
  import("./term-freq-tool").then((module) => ({
    default: module.TermFreqTool,
  }))
)

// TODO: lazy load

export function termFreqToolTransformer(node, i, { classList, children }) {
  if (
    classList.includes("wp-block-dekoder-custom-blocks-term-frequency-tool")
  ) {
    const {
      startyear,
      endyear,
      apiurl,
      sourcename,
      sourceurl,
      downloadurl,
      lastupdate,
      hidefull,
    } = node.attribs
    const defaultMarkers = parseMarkerStr(node.attribs.marker)
    const hideFull = hidefull === "true"
    const hasMethods = !!children?.length
    const showMeta = !!sourcename || !!lastupdate || hasMethods
    const metaTitleEl = children.find((c) => c.props.type === "h3")
    return (
      <div style={{ maxWidth: "none" }}>
        <Compressor hideFull={hideFull}>
          <Suspense fallback={<div style={{height: "600px"}}>loading ...</div>}>
            <TermFreqTool
              suggestionApi={`${apiurl}/suggestion`}
              termApi={`${apiurl}/term`}
              yearRange={[startyear * 1, endyear * 1]}
              defaultMarkers={defaultMarkers}
              type="primary" // secondary
              shareProjectName={`${new URL(apiurl).host.split(".")[0]}special`} // putin.api.dekoder.org => putinspecial
            />
          </Suspense>
        </Compressor>
        {showMeta && !hideFull && (
          <TermFrequencyToolMeta
            sourceUrl={sourceurl}
            sourceName={sourcename}
            downloadUrl={downloadurl}
            hasMethods={hasMethods}
            lastUpdate={lastupdate}
            methodsText={hasMethods && (
              <MethodsText title={metaTitleEl?.props?.children?.[0]}>
                {children.filter((c) => c !== metaTitleEl)}
              </MethodsText>
            )}
          />
        )}
        {}
      </div>
    )
  }
}
