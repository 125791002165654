import React, { useContext } from "react"
import { LangContext } from "gatsby-source-dek-wp"
import scrollIntoView from "scroll-into-view"
import styled from "styled-components"

const FOOTNOTE_STR = {
  de: "Fußnoten",
  en: "Footnotes",
  ru: "Примечания",
  be: "Зноскі",
  uk: "Примітки",
}

const FootnoteHeader = ({ langId = "de" }) => (
  <div className={"my-8"} style={{ marginTop: "3rem", marginBottom: "2rem" }}>
    <strong className={"uppercase"}>{FOOTNOTE_STR[langId]}</strong>
  </div>
)

const Wrapper = styled.div`
  > div {
    display: flex;
    align-items: flex-start;
    p {
      font-size: ${p => p.theme.fontSizeSmall};
      font-family: "Roboto Condensed" !important;
      margin: 0 0 1em;
      button.goto-footnote-link {
        color: ${p => p.theme.articleRed};
      }
    }
    > div:first-child {
      margin-right: 1.5rem;
      font-weight: 700;
    }
  }
`

const Footnote = ({ children, footnoteId, number }) => {
  const num = number || footnoteId
  const isFirst = num === 1
  const lang = useContext(LangContext)
  const onUpClick = (e) => {
    e.preventDefault()
    scrollTo(`#footnote-link-${footnoteId}`)
  }
  return (
    <Wrapper className={"footnote mb-8"}>
      {isFirst && <FootnoteHeader langId={lang.id} />}
      <div className={"flex"} id={`footnote-${footnoteId}`}>
        <div className={"mr-8"}>
          <p>
            <button className={"font-display text-base"} onClick={onUpClick}>
              {" "}
              {num}{" "}
            </button>
          </p>
        </div>
        <div className={"text-base"}>{children}</div>
      </div>
    </Wrapper>
  )
}

export default Footnote

export function scrollTo(elSelector) {
  const targetEl = document.querySelector(elSelector)
  if (!targetEl) {
    console.error(`Element ${elSelector} not found.`)
    return
  }
  scrollIntoView(targetEl, { time: 300 })
}
