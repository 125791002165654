import React, {
  useContext,
  createContext,
  SetStateAction,
} from "react"
import { useBodyClass } from "gatsby-source-dek-wp"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { MethodsBodyOverlay, MethodsTextWrapper } from "./methods-text.style"

export const MethodsContext = createContext<
  [boolean, React.Dispatch<SetStateAction<boolean>>]
>([false, () => {}])

interface MethodsLinkProps {
  children: React.ReactNode
}

export const MethodsLink = ({ children }: MethodsLinkProps) => {
  const [showMethods, setShowMethods] = useContext(MethodsContext)
  const onClick: React.MouseEventHandler = (e) => {
    e.preventDefault()
    setShowMethods((s) => !s)
  }
  useBodyClass(`${showMethods ? "show-methods-text" : ""}`)
  return (
    <>
      <a // eslint-disable-line jsx-a11y/anchor-is-valid
        className="methods-link"
        href="#"
        onClick={onClick}
      >
        {children}
      </a>
      <MethodsBodyOverlay // eslint-disable-line jsx-a11y/control-has-associated-label
        className={`methods-body-overlay ${showMethods ? "active" : ""}`}
        onClick={() => setShowMethods(false)}
      />
    </>
  )
}

interface MethodsTextProps {
  children: React.ReactNode
  title?: string
}

export const MethodsText = ({ children, title }: MethodsTextProps) => {
  const [, setShowMethods] = useContext(MethodsContext)
  return (
    <MethodsTextWrapper>
      <div className="methods-header">
        <button
          className="close-button"
          onClick={() => setShowMethods(false)}
          onKeyDown={() => setShowMethods(false)}
        >
          <FontAwesomeIcon className="close-icon" icon={faTimes} />
        </button>
      </div>
      <div className="methods-text-inner">
        {!!title && <h3>{title}</h3>}
        <div>{children}</div>
      </div>
    </MethodsTextWrapper>
  )
}
