import { AuthContext } from "gatsby-source-dek-wp"
import React, { useState, useContext, Suspense } from "react"
import { SocialImgPreviewBg, Wrapper } from "./editor-menu.style"

const SocialImage = React.lazy(() => import("../social-image/social-image"))

const WP_BASE_URL = "https://wp.dekoder.org"

const SOCIAL_IMG_CLASSES = ["default", "insta-post", "insta-story"]

export const EditorMenu = ({ pageContext }) => {
  const [debugSocialImg, setDebugSocialImg] = useState(false)
  const [socialImgClass, setSocialImgClass] = useState("default")
  const isAuthed = useContext(AuthContext)
  const reload = () => {
    const url = window.location.href.split("?")[0]
    window.location = `${url}?preview=true` as unknown as Location
  }
  const logout = () => {
    const url = window.location.href.split("?")[0]
    window.location = url as unknown as Location
  }
  if (!isAuthed) return null
  return (
    <>
      <Wrapper>
        <div style={{ color: "red" }}>!!! PREVIEW !!!</div>
        <button onClick={reload}>reload</button>
        {!!pageContext && (
          <button onClick={() => setDebugSocialImg((d) => !d)}>
            socialimg
          </button>
        )}
        {!!pageContext?.postId && (
          <a
            className="button"
            href={`${WP_BASE_URL}/wp/wp-admin/post.php?post=${pageContext.postId}&action=edit`}
            target="_blank"
          >
            edit
          </a>
        )}
        <button onClick={logout}>logout</button>
      </Wrapper>
      {!!pageContext && debugSocialImg && (
        <Suspense>
          <SocialImgPreviewBg onClick={() => setDebugSocialImg(false)}>
            <Wrapper style={{ position: "fixed", top: 0 }}>
              {SOCIAL_IMG_CLASSES.map((c) => (
                <button
                  key={c}
                  className={c === socialImgClass ? "active" : ""}
                  onClick={(e) => {
                    e.stopPropagation()
                    setSocialImgClass(c)
                  }}
                >
                  {c}
                </button>
              ))}
            </Wrapper>
            <SocialImage
              pageContext={pageContext}
              className={socialImgClass}
              preview={true}
            />
          </SocialImgPreviewBg>
        </Suspense>
      )}
    </>
  )
}
