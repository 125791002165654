import React from "react"
import { Img, isElement } from "gatsby-source-dek-wp"
import innerText from "react-innertext"
import { COLORS } from "./routes"

export interface BasicMapPointDef {
  id: string
  children?: React.ReactElement[]
  color?: string
  lat: number
  lon: number
  zoom?: number
  hidden?: boolean
  category: string
  onClick?: () => void
}
export interface MapPointDef extends BasicMapPointDef {
  title: string
  color: string
  imgSrc?: string
}

export interface MapPointRoute {
  name: string
  color?: string
  imgUrl?: string
  onClick?: () => void
}
export interface MapPointState {
  mapPoints: MapPointDef[]
  currentPoint?: BasicMapPointDef
  routes: MapPointRoute[]
}

export type MapPointAction =
  | {
      type: "add"
      point: BasicMapPointDef
    }
  | { type: "remove"; id: MapPointDef["id"] }
  | { type: "setCurrentPoint"; currentPoint: BasicMapPointDef }
  | { type: "setRoutes"; routes: MapPointRoute[] }

export const mapPointReducer: React.Reducer<MapPointState, MapPointAction> = (
  state,
  action,
) => {
  const { mapPoints } = state
  switch (action.type) {
    case "add":
      const { point } = action
      const h4 = (point.children || []).find((c) => c.type === "h4")
      const title = h4 ? innerText(h4) : ""
      const figure = (point.children || []).find((c) => c.type === "figure")
      const imgSrc = figure
        ? React.Children.toArray(figure.props.children)
            .filter(isElement)
            .find((c) => c.type === "img" || c.type === Img)?.props?.src
        : undefined
      return {
        ...state,
        mapPoints: [
          ...mapPoints,
          {
            ...point,
            color: point.color ?? COLORS[0],
            title,
            imgSrc,
          },
        ],
      }
    case "remove":
      return {
        ...state,
        mapPoints: [...mapPoints.filter((a) => a.id !== action.id)],
      }
    case "setCurrentPoint":
      const { currentPoint } = action
      return {
        ...state,
        currentPoint,
      }
    case "setRoutes":
      const { routes } = action
      return {
        ...state,
        routes,
      }
    default:
      throw new Error()
  }
}

export function add(point: BasicMapPointDef) {
  return {
    type: "add" as const,
    point,
  }
}

export function remove(id: string) {
  return { type: "remove" as const, id }
}

export function setCurrentPoint(currentPoint: BasicMapPointDef) {
  return { type: "setCurrentPoint" as const, currentPoint }
}

export function setRoutes(routes: MapPointRoute[]) {
  return { type: "setRoutes" as const, routes }
}
