import { useState, useEffect } from "react"
import { parseWKT } from "./wkt"

export const EMPTY_GEOJSON = {
  type: "FeatureCollection",
  features: [],
} as GeoJSON.FeatureCollection<GeoJSON.Geometry>

export function useSpreadsheetLoader(
  spreadsheetId?: string,
  preloadedGeojson?: GeoJSON.FeatureCollection<GeoJSON.Geometry>,
) {
  const [geojson, setGeojson] = useState(preloadedGeojson)
  useEffect(() => {
    if (preloadedGeojson) return
    async function loadData() {
      if (!spreadsheetId) return
      const { id, sheet, prefetchColumns } = parseSpreadsheetId(spreadsheetId)
      // const prefetchSpreadsheetId = `${id}:${sheet}:${prefetchColumns.join(",")}`;
      const parsedGeojson = await getSpreadsheetData(id, sheet, prefetchColumns)
      setGeojson(parsedGeojson)
    }
    loadData()
  }, [spreadsheetId, preloadedGeojson])
  return geojson
}

export function parseSpreadsheetId(spreadsheetId: string) {
  // format is id:sheet:prefetchColumns:dataColumns, i.e. xxxxxxx:Sheet1:A,B,C
  const [id, sheet = "Sheet1", prefetchColumnsStr = "", dataColumnsStr = ""] =
    spreadsheetId.split(":")
  const prefetchColumns = parseColumnsStr(prefetchColumnsStr)
  const dataColumns = parseColumnsStr(dataColumnsStr)
  return { id, sheet, prefetchColumns, dataColumns }
}

function parseColumnsStr(columnsStr: string) {
  return columnsStr
    .split(",")
    .map((col) => col.trim())
    .filter(Boolean)
}

interface SheetCol {
  id: string
  label: string
  type: string
  pattern: string
}
interface SheetRow {
  c: {
    v: number | string | null
    f?: string
  }[]
}

let spreadsheetCache: {
  [url: string]: GeoJSON.FeatureCollection<
    GeoJSON.Geometry,
    GeoJSON.GeoJsonProperties
  >
} = {}

export async function getSpreadsheetData(
  id: string,
  sheet: string,
  columns?: string[],
  queryId: string | null = "",
) {
  const query = `SELECT ${columns?.length ? columns.join(", ") : "*"}${queryId ? ` WHERE A = ${queryId}` : ""}`
  const url = `https://docs.google.com/spreadsheets/d/${id}/gviz/tq?tqx=out:json&tq=${query}&sheet=${sheet}`

  if (spreadsheetCache[url] && process.env.IS_BUILD) {
    // console.log("SKIPPING, using cache", url);
    return spreadsheetCache[url]
  }

  const response = await fetch(url).catch(console.error)
  if (!response || !response.ok) return EMPTY_GEOJSON
  const data = await response.text()
  logByteSize(
    data,
    `${id}:${sheet}:${columns?.join(",")}${queryId ? `:${queryId}` : ""}`,
  )
  let json: { errors?: any[]; table?: { cols: SheetCol[]; rows: SheetRow[] } } =
    {}
  try {
    const innerObj = data.substring(47, data.length - 2)
    json = JSON.parse(innerObj)
  } catch (e) {
    console.error(e, url)
    throw new Error("Could not parse response from Google Sheets API")
  }
  if (!("table" in json) || !json.table) {
    console.error(json?.errors)
    throw new Error("Invalid response from Google Sheets API")
  }
  const { cols, rows }: { cols: SheetCol[]; rows: SheetRow[] } = json.table
  const columnNames = cols.map((col, i) => col.label || `column_${i}`)
  const rowData = rows.map((row) =>
    row.c.reduce(
      (acc, cell, i) => ({
        ...acc,
        [columnNames[i]]: cell ? cell.v : null,
      }),
      {} as Record<string, number | string | null>,
    ),
  )
  const parsedGeojson = jsonToGeoJson(rowData)

  spreadsheetCache[url] = parsedGeojson

  return parsedGeojson
}

export function logByteSize(data: string, comment?: string) {
  const bytes = new Blob([data]).size
  console.log(
    `Data size: ${bytes.toLocaleString("en-US")} bytes${comment ? ` - ${comment}` : ""}`,
  )
}

function jsonToGeoJson(rowsJson: Record<string, number | string | null>[]) {
  const featureCollection = {
    type: "FeatureCollection" as const,
    features: rowsJson.map((row) => {
      let { lat, lon, wkt, ...otherProps } = row
      if (lat !== undefined && lon !== undefined) {
        wkt = `POINT (${lon} ${lat})`
      }
      const geometry =
        wkt && typeof wkt === "string" ? (parseWKT(wkt) ?? null) : null
      return {
        type: "Feature" as const,
        properties: Object.entries(otherProps).length ? otherProps : null,
        geometry,
      }
    }),
  } as GeoJSON.FeatureCollection<GeoJSON.Geometry>
  return featureCollection
}
