import { css } from "styled-components"

export const bellitStyles = css`
  p,
  h1,
  h2,
  h3,
  .tile-inner {
    font-family: "Palatino", serif;
  }
  .tile-outer {
    box-shadow: none;
    overflow: visible;
    @media screen and (min-width: ${(p) => p.theme.breakpointMobile}) {
      width: 420px;
      height: 300px;
    }
    .tile-inner {
      padding: 10%;
    }

    .tile-title-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .tile-kicker {
      text-transform: none !important;
      font-size: 1em !important;
      font-weight: 800;
    }
    .tile-title {
      max-width: 320px;
      font-size: 1.6em !important;
    }
    .tile-img-wrapper {
      img {
        filter: contrast(0.9) brightness(0.7)
          drop-shadow(2px 2px 10px rgba(0, 0, 0, 1));
      }
      &:before,
      &:after {
        display: none;
      }
    }
  }
  &.social-image,
  .social-image {
    position: relative;
  }
  .social-image-header {
    position: absolute;
    top: 0;
    left: 5%;
    width: 90%;
    z-index: 100;
    background-color: transparent;
    &.insta-post {
      top: 10%;
    }
  }
  .social-image-body {
    height: 100%;
    h1,
    h3 {
      text-transform: none !important;
      letter-spacing: none;
      // display: none !important;
    }
    h3 {
      display: block;
      font-size: 2em !important;
    }
    &.insta-post,
    &.insta-story {
      h1 {
        font-size: 2.5em !important;
        padding: 15px;
      }
    }
    .cover {
      display: flex;
      background-color: black;
      justify-content: center;
      img {
        // object-fit: contain !important;
        object-position: center center !important;
        filter: contrast(0.9) brightness(0.7); //  drop-shadow(2px 2px 10px rgba(0,0,0,1));
      }
    }
    .cover-title-outer {
      background-color: transparent;
    }
  }
`
