import React, { useState } from "react"
import { FullscreenWrapper } from "../fullscreen-wrapper/fullscreen-wrapper"
import BgVideo from "../bg-video/bg-video"
import { TitleOuter, TitleInner, videoStyles } from "./cover.style"
// import { CreditWrapper } from "../bg-changer/bg-changer-wrapper.style"
import MuteButton from "../bg-video/mute-button"
import { FogComp } from "../fog-overlay/fog-overlay"

// TODO: share logic with bg-changer ...

export interface CoverProps {
  videoSrc?: string
  posterSrc?: string
  children?: React.ReactNode
  adjustToViewport?: boolean
  objectPosition?: string
  className?: string
}

export const Cover = ({
  videoSrc,
  posterSrc,
  children,
  adjustToViewport = false,
  objectPosition,
  className = "",
}: CoverProps) => {
  const [muted, setMuted] = useState(true)
  const classList = className.split(" ")
  const withFog = classList.includes("with-fog")
  return (
    <FullscreenWrapper
      adjustToViewport={adjustToViewport}
      className={`cover ${classList.join(" ")}`}
    >
      <BgVideo
        src={videoSrc}
        poster={posterSrc}
        style={videoStyles}
        muted={muted}
        setMuted={setMuted}
        objectPosition={objectPosition}
      />
      {withFog && <FogComp />}
      <TitleOuter
        bgColor={(!(videoSrc || posterSrc) && "transparent") || undefined}
        className={`cover-title-outer ${classList.join(" ")}`}
      >
        <TitleInner className="cover-title-inner">{children}</TitleInner>
      </TitleOuter>
      {/* !!credit && <CreditWrapper>{credit}</CreditWrapper> */}
      {!!videoSrc && (
        <MuteButton
          muted={muted}
          onClick={() => setMuted((m) => !m)}
          show={true}
        />
      )}
    </FullscreenWrapper>
  )
}
