import { css } from "styled-components"
import { BoxWrapper, MapPointWrapper } from "../../map/map-point.style"

export const timelineStyles = css`
  ${BoxWrapper}:first-of-type {
    margin-top: 50vh;
  }
  ${BoxWrapper}:not(:last-of-type) p {
    position: relative;
    &::after {
      content: " ";
      display: block;
      position: absolute;
      top: calc(100% + 1rem);
      left: 50%;
      width: 0px;
      height: calc(90vh - 2rem);
      border: 2px solid rgba(140, 140, 140, 0.7);
      z-index: -1;
    }
    &.timeline-is-red::after {
      border-color: rgba(255, 0, 0, 0.7);
    }
    &.no-timeline::after {
      border: none;
    }
  }
`
