import React from "react"
import styled from "styled-components"
import { faClipboard } from "@fortawesome/free-regular-svg-icons"
import { faClipboardCheck } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"

const Btn = styled.button`
  display: inline;
  border: 1px dashed gray;
  margin-top: 1em;
  padding: 0.5em 1em;
  transition: background-color 0.3s ease-in-out;
  > svg {
    margin-right: 1em;
  }
  &.clicked {
    background-color: ${(p) => p.theme.gnoseBlue};
    transition: none;
  }
`

export function ClipboardBtn({ text }: { text: string }) {
  const [btnClicked, setBtnClicked] = useState(false)
  const onClick = async () => {
    await navigator.clipboard.writeText(text)
    setBtnClicked(true)
    setTimeout(() => setBtnClicked(false), 500)
  }
  return (
    <Btn className={btnClicked ? "clicked" : ""} onClick={onClick}>
      <FontAwesomeIcon icon={btnClicked ? faClipboardCheck : faClipboard} />
      Copy to Clipboard
    </Btn>
  )
}
