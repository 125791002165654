import React, {
  useState,
  useRef,
  useContext,
  createContext,
  Dispatch,
  SetStateAction,
  useMemo,
  useEffect,
  useCallback,
} from "react"
import { MapWrapper, OverlayWrapper } from "./map.style"
import { MapPointContext } from "./map-points"
import { useBodyClass } from "../../modules/hooks"
import useFlyTo from "./fly-to"
import { useMarker } from "./marker"
import { useMapLock } from "./map-lock"
import { withMapPoints } from "./map-points"
import { LockContext } from "./lock-context"
import { MapPoint } from "./map-point"
import { ScrollTrigger } from "../scroll-trigger/scroll-trigger"
import { CameraDebug } from "./camera-debug"
import { GeoJSONFeature, ProjectionSpecification } from "mapbox-gl"
import { Map as ReactMap, MapRef, MapProvider, useMap } from "react-map-gl"
import "mapbox-gl/dist/mapbox-gl.css"
import { FillLayerBtn, FillLayerBtnWrapper } from "./fill-layer-btn.style"
import { MapLayerElement, MapLayerProps, isFillLayer } from "./map-layer"
import { useTooltip } from "./tooltip"
import {
  MapRangeSelectProps,
  RangeContext,
  RangeSetContext,
} from "./range-select"
import { Range } from "./range-select"
import { InViewProvider } from "./in-view-provider"
import { MountedMapsContext } from "react-map-gl/dist/esm/components/use-map"
import { isMobile } from "../../modules/utils"

const PRELOAD_THRESHOLD = "1000px"

export const LayerContext = createContext<MapLayerElement[]>([])

export const SelectedContext = createContext<
  | [
      GeoJSONFeature | undefined,
      Dispatch<SetStateAction<GeoJSONFeature | undefined>>,
    ]
  | undefined
>(undefined)

interface MapProps {
  children: React.ReactElement[]
  options: {
    center?: [number, number]
    zoom?: number
    zoomMobile?: number
    bearing?: number
    pitch?: number
    projection?: ProjectionSpecification
    style?: string
    mapType: string
  }
  layers: React.ReactElement<MapLayerProps>[]
  rangeSelect?: React.ReactElement<MapRangeSelectProps>
}

const Map = ({ children = [], options, layers, rangeSelect }: MapProps) => {
  const mapRef = useRef<MapRef>(null)
  const [{ mapPoints, currentPoint }] = useContext(MapPointContext)
  // const firstPoint = mapPoints.length ? mapPoints[0] : undefined
  const hasScrollElements = children.some(
    (c) =>
      [MapPoint, ScrollTrigger].includes(c.type as React.FC) &&
      !c?.props?.hidden,
  )
  const hasMapPoints = children.some(
    (c) => [MapPoint].includes(c.type as React.FC) && !c?.props?.hidden,
  )

  const inViewRef = useRef<HTMLDivElement>(null)

  const map = mapRef.current?.getMap()

  const [isLocked, setIsLocked, lockComp] = useMapLock(map)
  const isInteractive = !isLocked

  useMarker(mapPoints, currentPoint, map)
  useFlyTo(currentPoint, map, hasMapPoints)
  useBodyClass("with-map")

  const [patchedFillLayers, otherLayers, fillLayerBtns] =
    useFillLayerBtns(layers)

  const [onMouseEnter, onMouseLeave, onClick, tooltipComp, cursor] =
    useTooltip(layers)

  const interactiveLayerIds = layers
    .filter((l) => l.props.isInteractive)
    .map((l) => l.props.layerProps.id)

  const mapStyle =
    options.mapType === "fullscreen"
      ? ({ height: "100vh", position: "sticky", top: 0 } as React.CSSProperties)
      : undefined

  const [range, _setRange] = useState<Range>([
    rangeSelect?.props.initialMin ?? rangeSelect?.props.min ?? 0,
    rangeSelect?.props.initialMax ?? rangeSelect?.props.max ?? 0,
  ] as unknown as Range)
  const setRange = useCallback(_setRange, [])

  const higherMapCtx = useContext(MountedMapsContext)

  const { zoom, zoomMobile } = options
  const initialZoom = isMobile() ? zoomMobile || zoom : zoom

  const inner = (
    <InViewProvider
      refObj={inViewRef}
      once={true}
      options={{ rootMargin: `${PRELOAD_THRESHOLD} 0px` }}
    >
      <LockContext.Provider value={{ isLocked, setIsLocked }}>
        <LayerContext.Provider value={layers}>
          <RangeContext.Provider value={range}>
            <RangeSetContext.Provider value={setRange}>
              <MapWrapper
                id="default"
                ref={inViewRef}
                // style={{ border: inView ? "1px solid green" : "1px solid yellow" }}
                className={`${isLocked ? "map-locked" : "map-not-locked"} ${
                  hasScrollElements && "has-scroll-elements"
                } map-type-${options.mapType} ${!!rangeSelect && "has-range-select"}`}
              >
                <ReactMap
                  ref={mapRef}
                  style={mapStyle}
                  mapboxAccessToken={process.env.GATSBY_MAPBOX_KEY || ""}
                  initialViewState={{
                    longitude: options.center?.[0] ?? 13.408333,
                    latitude: options.center?.[1] ?? 52.518611,
                    zoom: initialZoom,
                  }}
                  projection={
                    options.projection as unknown as ProjectionSpecification
                  }
                  mapStyle={options.style || "mapbox://styles/mapbox/light-v10"}
                  boxZoom={isInteractive}
                  doubleClickZoom={isInteractive}
                  dragRotate={isInteractive}
                  dragPan={isInteractive}
                  keyboard={isInteractive}
                  scrollZoom={isInteractive}
                  touchPitch={isInteractive}
                  touchZoomRotate={isInteractive}
                  interactiveLayerIds={interactiveLayerIds}
                  cursor={cursor ?? "default"}
                  onMouseEnter={onMouseEnter}
                  onMouseMove={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                  onClick={onClick}
                >
                  {lockComp}
                  {rangeSelect}
                  {tooltipComp}
                  {!isLocked && <CameraDebug />}
                  {patchedFillLayers}
                  {otherLayers}
                </ReactMap>
                <OverlayWrapper>
                  {fillLayerBtns}
                  {children}
                </OverlayWrapper>
              </MapWrapper>
            </RangeSetContext.Provider>
          </RangeContext.Provider>
        </LayerContext.Provider>
      </LockContext.Provider>
    </InViewProvider>
  )

  return !!higherMapCtx ? inner : <MapProvider>{inner}</MapProvider>
}

function useFillLayerBtns(layers: React.ReactElement<MapLayerProps>[]) {
  const fillLayers = useMemo(() => layers.filter(isFillLayer), [layers])
  const otherLayers = useMemo(
    () => layers.filter((l) => !isFillLayer(l)),
    [layers],
  )
  const [activeLayerIds, setActiveLayerIds] = useState<string[]>([])
  useEffect(() => {
    setActiveLayerIds(
      fillLayers
        .filter((l) => l.props.isActive)
        .map((l) => l.props.layerProps.id),
    )
  }, [fillLayers])
  const patchedFillLayers = useMemo(
    () =>
      fillLayers.map((l, i) => {
        const isActive = activeLayerIds.includes(l.props.layerProps.id)
        return React.cloneElement(l, {
          ...l.props,
          isActive,
        })
      }),
    [fillLayers, activeLayerIds],
  )

  const fillLayerBtns = (
    <FillLayerBtnWrapper>
      {fillLayers
        .filter((l) => !!l.props.label)
        .map((l, i) => (
          <FillLayerBtn
            key={i}
            isActive={activeLayerIds.includes(l.props.layerProps.id)}
            color={
              (l.props.layerProps.paint?.["fill-color"] as string) ?? "#ff0000"
            }
            onClick={() => {
              setActiveLayerIds((prev) =>
                prev.includes(l.props.layerProps.id)
                  ? prev
                  : [l.props.layerProps.id],
              )
            }}
          >
            {l.props.label}
          </FillLayerBtn>
        ))}
    </FillLayerBtnWrapper>
  )
  return [patchedFillLayers, otherLayers, fillLayerBtns] as const
}

export default withMapPoints(Map)

export function getLayer(
  layers: MapLayerElement[],
  feature?: GeoJSONFeature | null,
) {
  if (!feature) return
  return layers.find((l) => l.props.layerProps.id === feature?.layer?.id)
}
