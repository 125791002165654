import { useMemo } from "react"
import { PrecisionInterval } from "./term-freq-tool"
import { FreqObj, TermsQueryData } from "./elastic-search"

export function useGroupData(
  data: TermsQueryData[] = [] as TermsQueryData[],
  interval: PrecisionInterval,
  active?: boolean
) {
  return useMemo(() => {
    // console.log({ data, interval })
    if (interval === "month" || !active) return data
    return data.map((s) => ({
      ...s,
      data: groupSeriesByYear(s.data),
    }))
  }, [data, interval, active])
}

interface FreqObjYear extends Omit<FreqObj, 'x'>  {
  x: number // year
}

function groupSeriesByYear(data: TermsQueryData["data"]) {
  return data.reduce((acc, curr) => {
    const [year] = String(curr.x).split(".") // , month
    const obj = { ...curr, x: parseInt(year) }
    const existing = acc.find((p) => p.x * 1 === parseInt(year))
    return existing
      ? [...acc.filter((p) => p !== existing), mergePoints(obj, existing)]
      : [...acc, obj]
  }, [] as FreqObjYear[])
}

function mergePoints(a, b) {
  return {
    ...a,
    x: a.x,
    y: a.y + b.y,
    count: a.count + b.count,
    parties: { ...a.parties, ...b.parties },
    contexts: { ...a.contexts, ...b.contexts },
  }
}
