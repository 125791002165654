import React from "react"
import { YoutubeVideo } from "./youtube"
import { Transformer, isElement } from "../../types"

export const youtubeVideoTransformer: Transformer = (
  node,
  i,
  { classList, children },
) => {
  if (classList.includes("wp-block-embed-youtube")) {
    const childs = React.Children.toArray(children).filter(isElement)
    const container = childs.find((c) => c.type === "div")
    const figcaption = childs.find((c) => c.type === "figcaption")
    const iframe = container
      ? React.Children.toArray(container.props.children)
          .filter(isElement)
          .find((c) => c.type === "iframe")
      : undefined
    const iframeProps = iframe?.props ?? {}
    return (
      <YoutubeVideo
        className={classList.join(" ")}
        {...iframeProps}
        figcaption={figcaption}
      />
    )
  }
}
