import React, {
  useState,
  createContext,
  useContext,
  useRef,
  useEffect,
} from "react"
import { QuizWrapper, NextButton } from "./quiz.style"
import useEnrichedItems from "./enriched-items"
import useUserSelections from "./user-selections"
import QuizFeedback from "./quiz-feedback"
import { LangContext } from "gatsby-source-dek-wp"
import scrollIntoView from "scroll-into-view"

type onOptionClick = (_: any) => void

export const QuizContext = createContext<onOptionClick>(() => {})

const next = {
  de: "Weiter",
  ru: "Дальше",
}

/* const share = {
  de: "Ergebnis teilen",
  ru: "Поделиться результатом",
} */

const again = {
  de: "Nochmal",
  ru: "Еще раз",
}

function useIsSafari() {
  const [isSafari, setIsSafari] = useState(false)
  useEffect(() => {
    if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent))
      setIsSafari(true)
  }, [])
  return isSafari
}

interface Props {
  items: React.ReactNode[]
  feedbacks: React.ReactNode[]
}

const Quiz = ({ items = [], feedbacks = [] }: Props) => {
  const lang = useContext(LangContext)
  const ref: React.MutableRefObject<HTMLDivElement | null> = useRef(null)
  const [userSelections, onOptionClick, reset] = useUserSelections()
  const enrichedItems = useEnrichedItems(items, userSelections)
  const [currItemId, gotoNext, restart] = useNav()

  const currItem = enrichedItems[currItemId]
  const currSelection = (currItem || { props: {} }).props.selection
  const prevItems = enrichedItems.slice(0, currItemId)

  const feedbackComp = useFeedback(userSelections, feedbacks)

  const isSafari = useIsSafari()

  const onNextClick = async () => {
    gotoNext()
    if (isSafari) await new Promise((res) => setTimeout(res, 100))
    if (!ref.current) return
    scrollIntoView(ref.current, {
      time: isSafari ? 200 : 0,
      align: { topOffset: 15 },
    })
  }

  const startOver = async () => {
    restart()
    reset()
    if (isSafari) await new Promise((res) => setTimeout(res, 100))
    if (!ref.current) return
    scrollIntoView(ref.current, {
      time: isSafari ? 200 : 0,
      align: { topOffset: 15 },
    })
  }

  // const displayUrl = typeof window !== "undefined" ? window.location.href : ""

  return (
    <QuizContext.Provider value={onOptionClick}>
      <QuizWrapper>
        {prevItems}
        <div ref={ref}>{currItem || feedbackComp}</div>
        {!!currItem ? (
          <NextButton onClick={onNextClick} inactive={!currSelection}>
            {next[lang.id]}
          </NextButton>
        ) : (
          <NextButton onClick={startOver}>{again[lang.id]}</NextButton>
        )}
      </QuizWrapper>
    </QuizContext.Provider>
  )
}

export default Quiz

function useNav() {
  const [currItemId, setCurrItemId] = useState(0)
  const gotoNext = () => setCurrItemId((i) => i + 1)
  const restart = () => setCurrItemId(0)
  return [currItemId, gotoNext, restart] as const
}

function useFeedback(userSelections, feedbacks) {
  const total = userSelections.length
  const correct = userSelections.filter((s) => s.isCorrect).length
  const matchingFeedback = feedbacks
    .sort((a, b) => a.props.minCorrect - b.props.minCorrect)
    .reduce((acc, curr) => {
      const { feedback, img } = curr.props
      return correct >= curr.props.minCorrect ? { feedback, img } : acc
    }, {})
  // console.log(correct, total, feedback)
  const { feedback, img } = matchingFeedback
  return <QuizFeedback {...{ total, correct, feedback, img }} />
}
