import { useEffect } from "react"
import { setTermsArr } from "./term-reducer"

export function getTermsFromUrl() {
  const { hash } = window.location || ""
  const terms = decodeURIComponent(hash).replace(/^\#q\=/, "").split(",").filter(t => !!t)
  return terms
}

export function useTermUrlEffect(enabled: boolean, termState, termDispatch) {
  useEffect(() => {
    const terms = getTermsFromUrl()
    if (terms.length) {
      const termsArr = terms.map((term) => ({ term }))
      // console.log("useTermUrlEffect", termsArr)
      termDispatch(setTermsArr(termsArr))
    }
  }, [termDispatch])
  
  useEffect(() => {
    if (!enabled) return
    const terms = termState.terms.map((t) => t.term).join(",")
    const newURI = encodeURIComponent(terms)
    window.history.replaceState(
      "",
      "",
      `${window.location.pathname}${window.location.search}${newURI ? `#q=${newURI}` : ""}`
    )
  }, [enabled, termState])
}
