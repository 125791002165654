import { useContext, useRef, useMemo, useEffect } from "react"
import { useIntersectionObserver } from "gatsby-source-dek-wp"
import { VisRefContext } from "./vis-container"
import { VideoRefContext } from "../bg-changer/bg-changer-wrapper"
import { getCameraMover } from "../map/move-camera"
import { useMap } from "react-map-gl"
import { SCROLL_OFFSET_TOP } from "../map/map-point"
import { throttle } from "throttle-debounce"
import { RangeSetContext } from "../map/range-select"

const SCROLL_THROTTLE = 10

export const OBSERVER_OPTIONS: Record<string, any> = {
  threshold: 0,
  rootMargin: `-${SCROLL_OFFSET_TOP}% 0% -${100 - SCROLL_OFFSET_TOP}% 0%`,
}

declare global {
  interface Window {
    [key: string]: any
  }
}

export function useExternalCallbacks(
  id: string | undefined,
  isLast?: boolean,
  observerOptions = OBSERVER_OPTIONS,
) {
  // const SCROLL_OFFSET_TOP = 100

  const [ref, inView, direction] = useIntersectionObserver(observerOptions)
  const wasInView = useRef(false)

  const ctx = useCtx()

  const onInit = `onInit_${id}`
  const onEnter = `onEnter_${id}`
  const onLeave = `onLeave_${id}`
  const onScroll = `onScroll_${id}`

  // onInit effect
  useEffect(() => {
    if (!id) return
    // if (!ctx.box || !ctx.container) return
    if (typeof window[onInit] === "function") {
      try {
        window[onInit](ctx)
      } catch (e) {
        console.error(e)
      }
    }
  }, [ctx, id])

  // onEnter / onLeave / onScroll effect
  useEffect(() => {
    if (!id) return

    if (inView && !wasInView.current) {
      wasInView.current = true
    }

    if (!inView && wasInView.current && typeof window[onLeave] === "function") {
      try {
        window[onLeave]({ ...ctx, direction })
      } catch (e) {
        console.error(e)
      }
    }

    if (inView && typeof window[onEnter] === "function") {
      // make sure onEnter is triggered after last onLeave when scrolling up
      const timeout = direction === "up" ? 10 : 0
      setTimeout(() => {
        try {
          window[onEnter](ctx)
        } catch (e) {
          console.error(e)
        }
      }, timeout)
    }

    if (inView && typeof window[onScroll] === "function") {
      const cb = () => {
        if (!ref.current) return
        const container = ref.current // ?.offsetParent // ?.offsetParent
        const percent = calcScrollPercent(container, {
          yOffset: (SCROLL_OFFSET_TOP / 100) * window.innerHeight,
          maxScrollOffset: isLast
            ? -((SCROLL_OFFSET_TOP / 100) * window.innerHeight)
            : 0,
        })
        try {
          window[onScroll]({ ...ctx, percent })
        } catch (e) {
          console.error(e)
        }
      }
      const throttledCb = throttle(SCROLL_THROTTLE, cb) as EventListener
      window.addEventListener("scroll", throttledCb)
      return () => window.removeEventListener("scroll", throttledCb)
    }
  }, [inView, id, ctx, wasInView, direction, isLast])

  return [ref, inView] as const
}

// ctx is a context object that is used to pass the map and moveCamera function to the external callbacks
export function useCtx() {
  const refs = useContext(VisRefContext)
  const mapRef = useMap()
  const videoRef = useContext(VideoRefContext)
  const setRange = useContext(RangeSetContext)
  const _map = mapRef?.default ?? mapRef?.current
  const ctx = useMemo(() => {
    const map = _map ? _map.getMap() : undefined
    const moveCamera = map ? getCameraMover(map) : undefined
    return {
      map,
      moveCamera,
      container: refs?.container?.current,
      box: refs?.box?.current,
      boxLeft: refs?.boxLeft?.current,
      video: videoRef?.current,
      setRange,
    }
  }, [
    _map,
    refs?.container?.current,
    refs?.box?.current,
    refs?.boxLeft?.current,
    videoRef?.current,
    setRange,
  ])
  return ctx
}

interface CalcScrollPercentOptions {
  maxScrollOffset?: number
  yOffset?: number
}

function calcScrollPercent(
  container: HTMLElement,
  options: CalcScrollPercentOptions, // true
) {
  const { maxScrollOffset = 0, yOffset = 0 } = options
  const rect = container.getBoundingClientRect()
  const containerHeight = container.offsetHeight
  const maxScroll = containerHeight + maxScrollOffset // - 2 * HEADER_HEIGHT
  const currScroll = -rect.y + yOffset // + window.innerHeight

  const percent = Math.min(Math.max(currScroll / maxScroll, 0), 1)
  return percent
}
