import React, { createContext } from "react"
import { Action, Marker, TermState } from "../term-reducer"
import { LangDef } from "../../../hooks/langs"
import { TermObj } from "../term-setter/term-obj"

export const DEFAULT_TERM_STATE: TermState = {
  langId: "de",
  terms: [],
  markers: [],
  range: undefined,
}

export const TermStateContext = createContext<TermState>(DEFAULT_TERM_STATE)
export const TermDispatchContext = createContext<React.Dispatch<Action>>(() => {})
