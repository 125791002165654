import { useRef, useState, useLayoutEffect } from "react"

export function useResponsiveWidth() {
  const ref = useRef<HTMLDivElement>()
  const [width, setWidth] = useState(1000)
  useLayoutEffect(() => {
    const handleResize = () => {
      if (!ref.current) return
      const wrapperWidth = ref.current.getBoundingClientRect().width
      // console.log("WRAPPER", wrapperWidth, ref.current)
      setWidth(wrapperWidth) // window.innerWidth
    }
    handleResize()
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])
  return [ref, width] as const
}