import React from "react"
import { ALL_LANGS, LangContext } from "gatsby-source-dek-wp"
import { LangDef } from "gatsby-source-dek-wp/hooks/langs"

interface Props {
  pageContext: { langId: LangDef["id"] }
  children?: React.ReactNode
  metaData?: typeof import("../../../meta-data-es6")
}

const LayoutStandalone = ({ pageContext, children }: Props) => {
  const { langId } = pageContext
  const lang = ALL_LANGS.find((l) => l.id === langId) || ALL_LANGS[0]
  return <LangContext.Provider value={lang}>{children}</LangContext.Provider>
}

export default LayoutStandalone
