import { Geometry, Position } from "geojson"

export const parseWKT = (wkt: string): Geometry | null => {
  try {
    let [type, coordinatesString] = (
      wkt.match(/^([A-Z]+)\s*\((.*)\)$/) ?? []
    ).slice(1)
    type = type.toUpperCase()

    const parseCoordinates = (coords: string): number[] =>
      coords.trim().split(/\s+/).map(Number)

    const parsers: Record<string, (str: string) => any> = {
      POINT: (str: string) => parseCoordinates(str),
      LINESTRING: (str: string) => str.split(",").map(parseCoordinates),
      MULTILINESTRING: (str: string) =>
        (str.match(/\([^)]+\)/g) ?? []).map((ring) =>
          ring.slice(1, -1).split(",").map(parseCoordinates),
        ),
      POLYGON: (str: string) =>
        (str.match(/\([^)]+\)/g) ?? []).map((ring) =>
          ring.slice(1, -1).split(",").map(parseCoordinates),
        ),
      MULTIPOLYGON: (str: string) =>
        (str.match(/\(\([^)]+\)\)/g) ?? []).map((polygon) =>
          (polygon.slice(1, -1).match(/\([^)]+\)/g) ?? []).map((ring) =>
            ring.slice(1, -1).split(",").map(parseCoordinates),
          ),
        ),
    }

    const typesDict: Record<
      string,
      "Point" | "LineString" | "MultiLineString" | "Polygon" | "MultiPolygon"
    > = {
      POINT: "Point",
      LINESTRING: "LineString",
      MULTILINESTRING: "MultiLineString",
      POLYGON: "Polygon",
      MULTIPOLYGON: "MultiPolygon",
    }

    if (!(type in parsers)) {
      throw new Error(`Unsupported geometry type: ${type}`)
    }

    return {
      type: typesDict[type],
      coordinates: parsers[type](coordinatesString),
    }
  } catch (e) {
    console.error(`Error parsing WKT: ${wkt}`, e)
    return null
  }
}
