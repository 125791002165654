import { useState } from "react"

export default function useUserSelections() {
  const [userSelections, setUserSelections] = useState([])

  const onOptionClick = (id, value, isCorrect) => {
    const newSelection = { id, value, isCorrect }
    setUserSelections((us) => [...us.filter((s) => s.id !== id), newSelection])
  }

  const reset = () => setUserSelections([])

  return [userSelections, onOptionClick, reset]
}
