import styled from "styled-components"
import { blockStyles } from "../gallery/gallery"

export const SubheadingWrapper = styled.h4`
  button {
    text-align: left;
    display: flex;
    align-items: left;
    > span {
      display: block;
    }
  }
  &.has-text-align-center button {
    width: 100%;
    text-align: center;
    justify-content: center;
  }
  // .article-wrapper.category-centered & {
  text-align: left;
  margin-top: 2em !important;
  margin-bottom: 2em !important;
  padding-bottom: 0;
  &.h2 {
    font-size: ${(p) => p.theme.fontSizeH4};
    button {
      text-transform: uppercase;
    }
  }
  // }

  &.bg-dark {
    background-color: ${(p) => p.theme.bgDark};
    color: white;
    box-shadow: inset 0 0 50px rgba(0, 0, 0, 1);

    max-width: none !important;
    margin: 0 !important;
    padding: 2em 0 !important;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    font-size: ${(p) => p.theme.fontSizeH3};
    > * {
      ${blockStyles}
      text-transform: uppercase;
      margin-bottom: 0;
      margin-top: 0;
    }

    @media print {
      color: black;
      background-color: transparent;
      box-shadow: none;
    }
  }
`
