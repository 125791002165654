import React, { Suspense } from "react"
import termsStringToTermBundles from "./terms-string-to-term-bundles"
import { enrichTermBundleWithColors } from "../term-colors"
import JSON5 from "json5"
import { type Marker } from "../term-reducer"
import { Transformer } from "../../../types"

const TermSetter = React.lazy(() => import("./term-setter"))

export const termSetterTransformer: Transformer = (
  node,
  i,
  { classList, children }
) => {
  if ("attribs" in node && node.attribs["terms"]) {
    const termBundles = termsStringToTermBundles(node.attribs["terms"])
    const termsBundlesWithColors = enrichTermBundleWithColors(termBundles)
    const markers = parseMarkerStr(node.attribs["marker"])
    const [from, to] = (node.attribs["range"] || "")
      .split("-")
      .map((v) => parseInt(v))
    const range =
      typeof from === "number" && typeof to === "number"
        ? ([from, to] as [number, number])
        : undefined
    const type =
      node.name === "span" ? "inline" : node.attribs["type"] || "trigger"
    const childs = node.name === "span" ? null : children

    return (
      <Suspense fallback={<div>loading ...</div>}>
        <TermSetter
          terms={termsBundlesWithColors}
          type={type}
          markers={markers}
          children={childs}
          range={range}
        />
      </Suspense>
    )
  }
}

export function parseMarkerStr(markerStr: string) {
  let markers = []
  if (markerStr) {
    try {
      markers = JSON5.parse(`[${markerStr}]`)
    } catch (e) {
      console.error(e, markerStr)
    }
  }
  return markers as Marker[]
}
