import React, { useContext, useEffect } from "react"
import { BgChangerContext, Visual } from "./bg-changer-wrapper"
import { BgChangerStyleWrapper } from "./bg-changer.style"
import { BoxWrapperWithInViewTrigger } from "../scroll-trigger/scroll-trigger"
import { useExternalCallbacks } from "../scroll-trigger/external-callbacks"

const SCROLL_OFFSET_TOP = 90 // 15 // in percent
interface Props {
  children: React.ReactNode[]
  visual: Visual
  script?: React.ReactNode
  id?: string
}
const BgChanger = ({ children, visual, id, script }: Props) => {
  const { type, src } = visual || {}
  const setters = useContext(BgChangerContext)
  const [ref, inView] = useExternalCallbacks(id || "TODO", false, {
    rootMargin: `-${SCROLL_OFFSET_TOP}% 0% -${100 - SCROLL_OFFSET_TOP}% 0%`,
  })

  useEffect(() => {
    // add/remove url to parent
    setters.setVisuals((vs) => [...vs, visual])
    return () => setters.setVisuals((v) => [...v.filter((i) => i !== visual)])
  }, [visual, setters])

  useEffect(() => {
    if (!inView) return
    setters.setActive(visual)
  }, [visual, inView, setters])

  return (
    <BgChangerStyleWrapper ref={ref}>
      {children.map((c, i) => (
        <BoxWrapperWithInViewTrigger
          key={i}
          script={c?.props?.script}
          id={c?.props?.id}
          className={`${c?.props?.className}`}
        >
          {c}
        </BoxWrapperWithInViewTrigger>
      ))}
      {!!script && script}
    </BgChangerStyleWrapper>
  )
}

export default BgChanger
