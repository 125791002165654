import { useRef, useContext, useEffect } from "react"
import { SlideContext } from "../carousel/carousel"

export function useAutoPauseOnSlideChange() {
  const ref = useRef<HTMLIFrameElement>(null)
  const currSlide = useContext(SlideContext)
  useEffect(() => {
    const runCmd = (cmd: string) => {
      if (!ref.current) return
      if (!ref.current?.contentWindow) return
      if (typeof ref.current?.contentWindow?.postMessage !== "function") return
      ref.current.contentWindow.postMessage(
        `{"event":"command","func":"${cmd}","args":""}`,
        "*"
      )
    }
    runCmd("pauseVideo")
  }, [currSlide, ref])
  return ref
}
