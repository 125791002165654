import { useMemo, useEffect, createContext, useRef, useContext } from "react"
import { navigate } from "gatsby"
import { AuthContext } from "./auth"

const STORAGE_LANG_KEY = "lang"

declare global {
  interface Navigator {
    userLanguage: string
  }
}

export interface LangDef {
  id: string
  name: string
  short: string
  locale: string
}

export const ALL_LANGS: LangDef[] = [
  { id: "de", name: "Deutsch", short: "Deu", locale: "de-DE" },
  { id: "en", name: "English", short: "Eng", locale: "en-US" },
  { id: "ru", name: "Русский", short: "Рус", locale: "ru-RU" },
  { id: "uk", name: "Українська", short: "Укр", locale: "uk-UA" },
  { id: "be", name: "Беларуская", short: "Бел", locale: "be-BY" },
]

export const LangContext = createContext<LangDef>(ALL_LANGS[0])

export function useLangs(pageLangId: string, allArticles = []) {
  const firstLoad = useFirstLoad()
  const isAuthed = useContext(AuthContext)
  const allLangs = useMemo(
    () => getDistinctLangs(allArticles, !isAuthed),
    [allArticles, isAuthed]
  )

  const lang = useMemo(
    () => allLangs.find((l) => l.id === pageLangId) || allLangs[0],
    [allLangs, pageLangId]
  )

  useEffect(() => {
    if (firstLoad && !pageLangId) {
      const newLang = getLangOnLoad(allLangs)
      navigate(`/${newLang.id}`)
    }
  }, [firstLoad, pageLangId, allLangs])

  useEffect(() => {
    if (typeof window === "undefined") return
    window.localStorage.setItem(STORAGE_LANG_KEY, lang.id)
  }, [lang])

  return [lang, allLangs]
}

function getDistinctLangs(allArticles, onlyPublished = true) {
  const distinctLangIds = allArticles
    .filter((a) => (onlyPublished ? a.published : a))
    .reduce(
      (acc, a) => (a && !acc.includes(a.langId) ? [...acc, a.langId] : acc),
      []
    )
  return distinctLangIds.length
    ? distinctLangIds
        .map((id) => ALL_LANGS.find((l) => l.id === id))
        .filter((l) => l)
        .sort((a, b) => (a.name < b.name ? -1 : 1))
    : [ALL_LANGS[0]]
}

function getLangOnLoad(languages) {
  const getCompatibleLang = (langId) => languages.find((l) => l.id === langId)
  return (
    (typeof window === "object"
      ? getCompatibleLang(localStorage.getItem(STORAGE_LANG_KEY))
      : false) ||
    getCompatibleLang(navLang()) ||
    languages[0]
  )
}

function navLang() {
  if (typeof navigator === "undefined") return
  return (navigator.language || navigator.userLanguage).split("-")[0]
}

function useFirstLoad() {
  const tmp: React.MutableRefObject<number> = useRef(0)
  if (!tmp.current) {
    tmp.current = 1
    return true
  }
  return false
}
