import * as React from "react"
import Slider from '@mui/material/Slider'
import { Wrapper } from "./range-select.style"
import { createTheme, Theme, ThemeProvider } from "@mui/material/styles"
import { useTheme } from "styled-components"

interface Props {
  range: [number, number]
  setRange: (_: any) => void
  min: number
  max: number
  step?: number
  theme?: Theme
}

export const RangeSelect = ({ range, setRange, min, max, step, theme }: Props) => {
  const handleChange = (ev: any, _newRange: number | number[]) => {
    const newRange = typeof _newRange === "number" ? [_newRange, _newRange] : _newRange
    setRange([newRange[0], newRange[1]] as number[])
  }
  const defaultTheme = useSliderTheme()
  return (
    <ThemeProvider theme={theme ?? defaultTheme}>
      <Wrapper>
        <Slider
          value={[range[0], range[1]]}
          min={min}
          max={max}
          step={step}
          onChange={handleChange}
          valueLabelDisplay="on"
          aria-labelledby="range-slider"
          marks={true}
        />
      </Wrapper>
    </ThemeProvider>
  )
}

function useSliderTheme() {
  const theme = useTheme()
  const sliderTheme = React.useMemo(
    () =>
      createTheme({
        palette: {
          primary: {
            light: theme?.themeColor || "#129fc9",
            main: theme?.themeColor || "#129fc9",
            dark: theme?.themeColor || "#129fc9",
            contrastText: theme?.darkMode ? "#333" : "#000",
          },
        },
      }),
    [theme?.themeColor]
  )
  return sliderTheme
}
