import { useIntersectionObserver } from "gatsby-source-dek-wp"
import React, { createContext } from "react"

export const InViewContext = createContext<boolean>(false)

interface InViewProviderProps {
  children: React.ReactNode
  refObj: React.RefObject<HTMLDivElement>
  options?: IntersectionObserverInit
  once?: boolean
}

export const InViewProvider: React.FC<InViewProviderProps> = ({
  children,
  refObj,
  options,
  once,
}) => {
  const [, inView] = useIntersectionObserver(options, refObj, once)
  return (
    <InViewContext.Provider value={inView}>{children}</InViewContext.Provider>
  )
}
