import { css } from "styled-components"

export const myths90sStyles = css`
  h1 {
    font-size: 4rem;
    font-weight: 400;
    text-transform: uppercase;

    @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
      font-size: ${(p) => p.theme.fontSizeH3};
      padding: 2rem 1rem;
    }
  }
  &:not(.social-image) h1 {
    max-width: 1100px !important;
    padding: 2rem;
    background-color: ${(p) => p.theme.colors?.current || "transparent"};
    box-shadow: 10px 10px 0
      ${(p) => (p.theme.colors?.current ? "black" : "transparent")};
    margin: calc(2rem - 5px) auto 2rem !important;
  }

  // TODO: don't include in frontend code!
  &.social-image {
    display: flex;
    flex-direction: column-reverse;
    position: relative;
    .social-image-header {
      flex-direction: row-reverse;
      // position: absolute;
      bottom: 0;
      width: 100%;
      background-color: rgb(240, 240, 240);
      height: 90px;
      img {
        filter: invert(1);
      }
      .start-page-logo-outer {
        justify-content: flex-end;
        margin-right: 0.7em;
      }
      .start-page-logo-slogan {
        color: black !important;
        font-size: 0.7em;
        margin-bottom: -3.5em;
      }
    }
    .social-image-lang-indicator {
      opacity: 0;
    }
    .social-image-body {
      height: 100%;
    }
    h1,
    h3 {
      text-align: left;
      margin: 0 !important;
      letter-spacing: 0 !important;
      color: black;
    }
    .cover-title-outer {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }
    .cover-title-inner {
      background-color: ${(p) => p.theme.colors?.current};
      box-shadow: 10px 10px 0
        ${(p) => (p.theme.colors?.current ? "black" : "transparent")};
      position: relative;
      width: 1100px;
      height: auto;
      padding: 2rem;
      align-items: flex-start;
    }
    h3.social-image-author {
      display: block;
      font-weight: 300;
      font-size: 2rem;
      text-transform: uppercase;
      margin-bottom: 2rem !important;
    }
  }
`
