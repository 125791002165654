import React from "react"
import styled from "styled-components"
// import { faWindowClose } from "@fortawesome/free-regular-svg-icons"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { fontSizeSmall } from "./extra-column.module.scss"

const XButtonWrapper = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  color: inherit;
  font-size: ${(p) => p.theme.fontSizeSmall ?? fontSizeSmall};

  opacity: 1;
  line-height: 1;
  width: 2em;
  height: 2em;
  svg {
    position: relative;
  }
  &:hover {
    // opacity: 0.5;
    background-color: rgba(250, 250, 250, 0.3);
  }
`
export const XButton = ({ extraClasses, onClick }) => (
  <XButtonWrapper className={`${extraClasses}`} onClick={onClick}>
    <FontAwesomeIcon icon={faTimes} />
  </XButtonWrapper>
)

export default XButton
