import React, { useContext, useState, useEffect } from "react"
import chroma from "chroma-js"
import Tooltip from "@mui/material/Tooltip"
import { components } from "react-select"

import { useTranscribe } from "../transcribe"
import { TranslateContext } from "../contexts/translate"
import { TermLabelWrapper } from "./term-label.style"

const TermLabel = ({
  color,
  terms,
  onClick,
  remove,
  alpha = 0.3,
  selected,
  tip,
  range,
}) => {
  const transcribeEnabled = useContext(TranslateContext)
  const transcribe = useTranscribe()

  const [renderColor, setRenderColor] = useState("gray")

  useEffect(() => {
    const c = chroma(color || "gray")
      .alpha(alpha)
      .css()
    setRenderColor(c)
  }, [color, alpha])

  const termsStr = terms
    .map((t) => t.term)
    .join(" & ")
    .split("+")
    .join(" + ")
  const translStr = terms
    .map((t) => t.translation || t.term)
    .join(" & ")
    .split("+")
    .join(" + ")
  const innerLabel = (
    <TermLabelWrapper
      className={`term-label${selected ? " selected" : ""}${
        typeof onClick === "function" ? " clickable" : ""
      }`}
      style={{
        backgroundColor: renderColor,
      }}
    >
      <span // eslint-disable-line jsx-a11y/no-static-element-interactions
        className={`term-label-label`}
        onClick={onClick}
        onKeyDown={onClick}
        style={{
          // color: renderColor,
          boxShadow: `5px 0 0 ${renderColor}, -5px 0 0 ${renderColor}`,
          backgroundColor: renderColor,
        }}
      >
        {transcribeEnabled ? transcribe(translStr) : translStr || termsStr}
        {!!range && ` (${range[0]}–${range[1]})`}
      </span>
      {typeof remove === "function" && (
        <button
          className="term-label-remove"
          aria-label="remove term"
          onClick={remove}
          style={{
            // boxShadow: `5px 0 0 ${renderColor}, -5px 0 0 ${renderColor}`,
            // backgroundColor: renderColor,
            color: chroma(renderColor).brighten(3).css(),
          }}
        >
          <components.MultiValueRemove />
        </button>
      )}
    </TermLabelWrapper>
  )
  const hasTranslations = terms.reduce(
    (acc, curr) => acc || !!curr.translation,
    false
  )
  if (hasTranslations || tip) {
    const transc = (
      <>
        {transcribe(termsStr).toUpperCase()}
        <br />
      </>
    )
    const tooltip = (
      <span>
        {!!hasTranslations && transc}
        {tip}
      </span>
    )
    return (
      <Tooltip title={tooltip} arrow>
        {innerLabel}
      </Tooltip>
    )
  } else return innerLabel
}

export default TermLabel
