import React from "react"
import styled from "styled-components"
import chroma from "chroma-js"

const POPUP_BTN_STR: { [k: string]: string } = {
  de: "Hier lang!",
  en: "Go!",
  ru: "Посмотреть!",
}

const PopupWrapper = styled.div<{ color: string }>`
  min-width: 150px;
  h5 {
    margin: 0 0 1em;
  }
  img {
    max-width: 100%;
  }
  button {
    // border: 1px solid gray;
    border-radius: 4px;
    box-shadow: 0 0 0 2px rgb(0 0 0 / 10%);
    display: block;
    width: 80px;
    margin: 0.5em auto 0;
    padding: 0.5em;
    background-color: transparent;
    font-size: ${(p) => p.theme.fontSizeSmall || "0.8em"};
    &:hover {
      background-color: ${(p) => chroma(p.color).alpha(0.1).hex()};
    }
  }
`

interface Props {
  title: string
  imgSrc?: string
  onClick?: () => void
  color: string
  langId?: string
}

export const MarkerPopup = ({
  title,
  imgSrc,
  onClick,
  color,
  langId,
}: Props) => (
  <PopupWrapper color={color}>
    <h5>{title}</h5>
    {!!imgSrc && <img src={imgSrc} alt={title} />}
    {!!onClick && (
      <button onClick={onClick}>{POPUP_BTN_STR[langId || "de"]}</button>
    )}
  </PopupWrapper>
)
