import dekoderLogo from "./src/images/logos/dekoder-white.svg"
import novayaLogo2 from "./src/images/logos/novaya-white-en.svg"

const DEK_X_NOVAYA_CATS = [40081, 40087, 51109]

export const siteLogos = [
  { 
    src: dekoderLogo, 
    url: {
      de: "https://specials.dekoder.org/de",
      en: "https://specials.dekoder.org/en",
      ru: "https://specials.dekoder.org/ru",
      uk: "https://specials.dekoder.org/ru",
      be: "https://specials.dekoder.org/be"
    },
    scale: 0.8,
    slogan: {
      de: "[Specials]",
      en: "[Specials]",
      ru: "[спецпроекты]",
      uk: "[спецпроекти]",
      be: "[спецпраекты]"
    }
  },
]

export const dekoderXnovayaLogos = [
  ...siteLogos.map(l => ({...l, slogan: undefined })),
  {
    src: novayaLogo2,
    url: {
      de: "https://novayagazeta.eu/",
      en: "https://novayagazeta.eu/",
      ru: "https://novayagazeta.eu/",
    },
    scale: 1
  }
]

export function getLogos(content) {
  return isWithNovaya(content) ? dekoderXnovayaLogos : siteLogos
}

function isWithNovaya(selected) {
  if (!selected) return false
  const catIds = selected.categories.map(({ id }) => id)
  return catIds.some((id) => DEK_X_NOVAYA_CATS.includes(id))
}