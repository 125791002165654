import { useBodyClass, useIntersectionObserver } from "gatsby-source-dek-wp"
import React, {
  forwardRef,
  createContext,
  ForwardedRef,
  useRef,
  useMemo,
} from "react"
import styled, { css } from "styled-components"
import { OBSERVER_OPTIONS } from "./external-callbacks"

type VisRef = React.MutableRefObject<HTMLDivElement | null>
interface VisRefsObj {
  box: VisRef
  boxLeft: VisRef
  container: VisRef
}
export const VisRefContext = createContext<VisRefsObj | undefined>(undefined)

const commonStyle = css`
  position: absolute;
  z-index: 10;

  pointer-events: none;

  transition: opacity 0.3s ease-in-out;
  opacity: 1;
  .map-not-locked & {
    opacity: 0;
    > * {
      pointer-events: none;
    }
  }
`

const WrapperBox = styled.div<{ ref: ForwardedRef<HTMLDivElement> }>`
  ${commonStyle}
  top: 5vh;
  height: calc(100vh - 10vh);
  width: ${(p) => p.theme.secondColumnWidth};
  right: ${(p) => p.theme.blockMarginLeftRight};
  &.align-left {
    right: auto;
    left: ${(p) => p.theme.blockMarginLeftRight};
  }
  @media screen and (max-width: ${(p) => p.theme.breakpointTablet}) {
    width: ${(p) => p.theme.secondColumnWidthTablet};
    right: ${(p) => p.theme.blockMarginLeftRightTablet};
  }
  @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
    width: calc(100% - 20px);
    right: 10px;
    top: auto;
    bottom: 10px;
    height: calc(50% - 10px);
  }

  .is-authed & {
    border: 1px dashed rgba(190, 190, 190, 0);
  }
  // width: 600px;

  /* display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; */
`

const WrapperContainer = styled.div<{ ref: ForwardedRef<HTMLDivElement> }>`
  ${commonStyle}
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

interface VisContainerProps {
  children?: React.ReactNode
  type: "container" | "box"
  align?: "right" | "left"
  className?: string
}

export const VisContainer = forwardRef(
  (
    { children, type, align, className }: VisContainerProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const Wrapper = type === "container" ? WrapperContainer : WrapperBox
    return (
      <Wrapper
        ref={ref}
        className={`${className} ${align ? `align-${align}` : ""}`}
      >
        {children}
      </Wrapper>
    )
  },
)

export const VisWrapper = styled.div`
  overflow: hidden;
  z-index: 9;
  position: sticky !important;
  top: 0;
  @media screen {
    position: sticky;
    height: 100vh;
  }
`

const Content = styled.div<{ ref: React.ForwardedRef<unknown> }>`
  margin-top: -100vh;
  z-index: 10;
  position: relative;
`

export function withVisComps(Comp: React.ComponentType<any>) {
  return React.forwardRef((_props: Record<any, any>, wrapperRef) => {
    const box: React.MutableRefObject<HTMLDivElement | null> = useRef(null)
    const boxLeft: React.MutableRefObject<HTMLDivElement | null> = useRef(null)
    const container: React.MutableRefObject<HTMLDivElement | null> =
      useRef(null)
    const refs = useMemo(
      () => ({ box, boxLeft, container }),
      [box?.current, boxLeft?.current, container?.current],
    )
    const { children, ...props } = _props
    const [, inView] = useIntersectionObserver(OBSERVER_OPTIONS, container)
    useBodyClass(inView ? "scroll-trigger-in-view" : "")
    return (
      <VisRefContext.Provider value={refs}>
        <Comp {...props}>
          <VisWrapper>
            <VisContainer
              className="vis-container"
              type="container"
              ref={container}
            />
            <VisContainer type="box" align="right" ref={box} />
            <VisContainer type="box" align="left" ref={boxLeft} />
          </VisWrapper>
          <Content ref={wrapperRef}>{children}</Content>
        </Comp>
      </VisRefContext.Provider>
    )
  })
}
