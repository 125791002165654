import React, {
  useContext,
  useEffect,
  useCallback,
  useRef,
  useState,
} from "react"
import { LangContext } from "gatsby-source-dek-wp"

import { addTerm, setMarkers, removeTerm, setTermsArr } from "../term-reducer"

import TermLabel from "../term-label/term-label"
import scrollIntoView from "scroll-into-view"
import { addExample, resetExamples } from "../term-bookmarks/bookmarks-reducer"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCaretSquareLeft,
  faCaretSquareRight,
} from "@fortawesome/free-regular-svg-icons"

import { usePrevious } from "../utils"
import { TermDispatchContext, TermStateContext } from "../contexts/terms"
import { BookmarksContext } from "../contexts/bookmarks"

import { TermSetterWrapper } from "./term-setter.style"
import { useIntersectionObserver } from "../../../hooks/intersection-observer"
import type { TermObj } from "./term-obj"
import { getTermsFromUrl } from "../term-url-effect"

// const BOOKMARK_COLOR = menuColor // "rgb(80, 80, 80)"

// TODO: remove constant
const STORY_JUMPING_BODY_CLASS = "story-jumping"

const ALT_CLICK_TIP_ADD = {
  de: (
    <>
      <em>alt + click</em>: hinzufügen
    </>
  ),
  en: (
    <>
      <em>alt + click</em>: add to selection
    </>
  ),
  ru: (
    <>
      <em>alt + click</em>: добавить
    </>
  ),
}

const ALT_CLICK_TIP_REMOVE = {
  de: (
    <>
      <em>alt + click</em>: entfernen
    </>
  ),
  en: (
    <>
      <em>alt + click</em>: remove
    </>
  ),
  ru: (
    <>
      <em>alt + click</em>: убрать
    </>
  ),
}

function useScrollPos(ref: React.RefObject<HTMLDivElement>, terms) {
  const [pos, setPos] = useState("no-scroll") // "between", "end", "no-scroll"
  const onScroll = useCallback(() => {
    const refEl = ref.current
    if (!refEl) return
    if (refEl.scrollWidth <= refEl.clientWidth) setPos("no-scroll")
    else if (refEl.scrollLeft === 0) setPos("start")
    else if (refEl.scrollLeft - (refEl.scrollWidth - refEl.clientWidth) === 0)
      setPos("end")
    else setPos("between")
  }, [ref])
  useEffect(() => {
    const refEl = ref.current
    if (!refEl) return
    refEl.addEventListener("scroll", onScroll)
    return () => refEl.removeEventListener("scroll", onScroll)
  }, [ref, onScroll])
  useEffect(() => {
    // update on term change &&
    // ;[].push(terms)
    onScroll()
  }, [terms, onScroll, ref])
  const prevTerms = usePrevious(terms)
  useEffect(() => {
    // scroll left when terms are added
    const refEl = ref.current
    if (!refEl || !prevTerms) return
    if (terms.length > prevTerms.length)
      refEl.scrollTo({
        left: 0,
        behavior: "smooth",
      })
  }, [terms, prevTerms, ref])
  return pos
}

interface TermSetterProps {
  terms: TermObj[][]
  children: React.ReactNode
  type: string
  markers?: any[]
  removeSavedTerm?: (term: TermObj[]) => void
  introStr?: string
  range?: [number, number]
}

const TermSetter = ({
  terms,
  children,
  type,
  markers,
  removeSavedTerm,
  introStr,
  range,
}: TermSetterProps) => {
  const [isSelected, setTerms, selectedTerms] = useTermState(
    terms,
    isValidRange(range) && range
  )
  const termState = useContext(TermStateContext)
  // const jumping = useContext(JumpingContext)
  const [ref1, ref2, onClickAll] = useTrigger(
    type === "trigger",
    setTerms,
    terms,
    markers,
    range
  )
  const onIndividualClick = useIndividualClick(
    type === "bookmarks" || type === "inline",
    setTerms,
    range
  )
  useDefaultSetter(type === "default", setTerms, terms, markers)
  useExampleBookmarkSetter(type === "examples", terms)

  const lang = useContext(LangContext)

  const pos = useScrollPos(ref1, terms)

  const scrollLeft = () => {
    const refEl = ref1.current
    if (!refEl) return
    const scrollLength = 0.8 * refEl.clientWidth
    refEl.scrollTo({
      left: refEl.scrollLeft - scrollLength,
      behavior: "smooth",
    })
  }
  const scrollRight = () => {
    const refEl = ref1.current
    if (!refEl) return
    const scrollLength = 0.8 * refEl.clientWidth
    refEl.scrollTo({
      left: refEl.scrollLeft + scrollLength,
      behavior: "smooth",
    })
  }

  if (type === "default" || type === "examples") return null
  return (
    <TermSetterWrapper
      ref={ref2}
      className={`term-setter-wrapper ${type} ${
        isSelected ? " selected" : ""
      } scroll-${pos}`}
    >
      <button className="scroll-button left" onClick={scrollLeft}>
        <FontAwesomeIcon icon={faCaretSquareLeft} />
      </button>
      <span // eslint-disable-line jsx-a11y/no-static-element-interactions
        onClick={onClickAll}
        onKeyDown={onClickAll}
        className="term-setter-buttons"
        ref={ref1}
      >
        {!!introStr && (
          <span className="term-setter-intro-text">{introStr}</span>
        )}
        {terms.map((t, i) => {
          const thisTerms = t.map((trm) => trm.term)
          const isSelected = thisTerms.every(
            (t1) =>
              selectedTerms.some((t2) => t2 === t1) &&
              isSameRange(range, termState.range)
          ) //  && thisTerms.length === selectedTerms.length
          return (
            <TermLabel
              key={i}
              color={t[0].color} // type === "bookmarks" ? BOOKMARK_COLOR :
              alpha={type === "bookmarks" ? 1 : 1}
              selected={isSelected}
              onClick={(e) => onIndividualClick(e, t, isSelected)}
              terms={t}
              range={isValidRange(range) && range}
              remove={
                typeof removeSavedTerm === "function" && !t[0].nonRemovable
                  ? () => removeSavedTerm(t)
                  : undefined
              }
              tip={
                type === "bookmarks"
                  ? isSelected
                    ? ALT_CLICK_TIP_REMOVE[lang.id]
                    : ALT_CLICK_TIP_ADD[lang.id]
                  : ""
              }
            />
          )
        })}
      </span>
      <button className="scroll-button right" onClick={scrollRight}>
        <FontAwesomeIcon icon={faCaretSquareRight} />
      </button>
      {children}
    </TermSetterWrapper>
  )
}

export default TermSetter

function useTermState(termBundleArr, range) {
  const termState = useContext(TermStateContext)
  const termDispatch = useContext(TermDispatchContext)

  const selectedTerms = termState.terms.map((t) => t.term)
  const thisTerms = termBundleArr.reduce(
    (acc, curr) => [...acc, ...curr.map((c) => c.term)],
    []
  )
  const isSelected =
    selectedTerms.every((t) => thisTerms.some((t2) => t2 === t)) &&
    thisTerms.length === selectedTerms.length &&
    isSameRange(range, termState.range)

  const setTerms = useCallback(
    (termBundleArr, markers, range) => {
      const terms = termBundleArr.reduce((acc, curr) => [...acc, ...curr], [])
      // console.log("SET TERMS", terms, termBundleArr, markers)
      termDispatch(setTermsArr(terms, range))
      termDispatch(setMarkers(markers))
    },
    [termDispatch]
  )

  return [isSelected, setTerms, selectedTerms] as const
}

const SCROLL_OFFSET_TOP = 15 // in percent

function useTrigger(active, setTerms, terms, markers, range) {
  const ref1 = useRef<HTMLDivElement>(null)
  const [ref2, inView] = useIntersectionObserver({
    threshold: 0,
    rootMargin: `-${SCROLL_OFFSET_TOP}% 0% -${100 - SCROLL_OFFSET_TOP}% 0%`,
  })
  const [clickJumping, setClickJumping] = useState(false)
  useEffect(() => {
    if (!inView || !active) return
    if (clickJumping) return
    if (document.body.classList.contains(STORY_JUMPING_BODY_CLASS)) return
    setTerms(terms, markers, range)
  }, [inView, setTerms, terms, markers, active, clickJumping, range])

  const onClickAll = () => {
    if (!active) return
    setClickJumping(true)
    setTerms(terms, markers, range)
    scrollIntoView(
      ref1.current,
      { align: { top: SCROLL_OFFSET_TOP / 100 } },
      () => {
        setTimeout(() => setClickJumping(false), 200)
      }
    )
  }

  return [ref1, ref2, onClickAll] as const
}

function useIndividualClick(active, setTerms, range) {
  const termDispatch = useContext(TermDispatchContext)
  const onIndividualClick = (e, terms, isSelected) => {
    if (!active) return
    if (e.altKey) {
      // add terms
      terms.forEach((t, i) => {
        isSelected
          ? termDispatch(removeTerm(t.term))
          : termDispatch(
              addTerm(t.term, t.color /*TERM_COLORS[i]*/, t.translation)
            )
      })
    } else {
      setTerms([terms], range)
    }
  }
  return onIndividualClick
}

function useDefaultSetter(active, setTerms, terms, markers) {
  useEffect(() => {
    if (!active) return
    const termsFromUrl = getTermsFromUrl()
    if (termsFromUrl.length) return
    setTerms(terms, markers)
  }, [terms, setTerms, active, markers])
}

function useExampleBookmarkSetter(active, terms) {
  const lang = useContext(LangContext)
  const [, bookmarkDispatch] = useContext(BookmarksContext)
  useEffect(() => {
    if (!active) return
    terms.reverse().forEach((term) => {
      bookmarkDispatch(addExample(term, lang.id))
    })
    return () => bookmarkDispatch(resetExamples())
  }, [terms, active, bookmarkDispatch, lang.id])
}

function isValidRange(range) {
  return Array.isArray(range) && range.length === 2
}

function isSameRange(range1, range2) {
  return (range1 || []).join("-") === (range2 || []).join("-")
}
