export type TransformFeatureFunc = (feature: GeoJSON.Feature) => GeoJSON.Feature

export function getTransformFeatureFunc(
  scriptEl: React.ReactElement | undefined,
): TransformFeatureFunc {
  let transformFeature: TransformFeatureFunc = (feature) => feature
  if (scriptEl) {
    const scriptStr = scriptEl.props.dangerouslySetInnerHTML?.__html ?? ""
    if (scriptStr.includes("function transformFeature(")) {
      const transformFunc = evaluateScript(scriptStr)
      if (typeof transformFunc === "function") transformFeature = transformFunc
    }
  }
  return transformFeature
}

function evaluateScript(scriptString: string) {
  if (typeof window !== "undefined") {
    // Client-side
    const script = new Function(`${scriptString}; return transformFeature;`)
    return script()
  } /* else {
    // Server-side
    const vm = require("vm");
    const script = new vm.Script(`${scriptString}; transformFeature`);
    const sandbox = {};
    const context = new vm.createContext(sandbox);
    const transformFeature = script.runInContext(context);

    return transformFeature; 
  } */
}
