import React from "react"
import styled from "styled-components"
import { Source } from "../source/source"
import { SpecialRelations } from "../special-relations/special-relations"
import { SocialBar } from "./social-bar"

export const IntroWrapper = styled.div`
  margin-top: 2.5rem !important;
  p {
    font-style: italic;
    em,
    figcaption {
      font-style: normal;
    }
  }
`

interface IntroProps {
  children: React.ReactNode
  className?: string
}

export const Intro = ({ children, className = "" }: IntroProps) => {
  const classList = className.split(" ")
  const noSocialBar = classList.includes("no-social-bar")
  return (
    <>
      <IntroWrapper className={`intro-wrapper ${className}`}>
        {children}
      </IntroWrapper>
      {!noSocialBar && <SocialBar />}
      <Source />
      <SpecialRelations />
    </>
  )
}
