import type { TermObj } from "./term-obj"

export default function termsStringToTermBundles(termsString = ""): TermObj[][] {
  return termsString.split(",").map((tb) => {
    const termsRaw = tb.split("&") // /\&|\%/ & as divider, % as alternative
    const terms = termsRaw.map((tr) => {
      const [term, translation] = tr
        .split(":")
        .map((s) => s.trim().toLowerCase())
      return { term, translation }
    })
    return terms
  })
}
