import React from "react"
import styled, { ThemeProvider } from "styled-components"
import { Cover } from "../cover/cover"

import "../article/fonts/fonts.scss"
import "./fonts.scss"

/**
 * Important: keep this component as isolated as possible!
 */

// for font definitions
import "../app/app.scss"
import { SiteLogo } from "../../../plugins/gatsby-source-dek-wp/components/site-logo"
import { getLogos } from "../../../site-logos"
import { specialStyles } from "../article/special-styles.style"

const SocialImageWrapper = styled.div`
  width: 1200px;
  height: 630px;
  background-color: white;
  &.preview {
    // border: 1px solid red;
  }
  .social-image-author {
    display: none;
  }
  &.insta-post {
    width: 770px;
    height: 900px;
  }
  &.insta-story {
    width: 540px;
    height: 960px;
  }
  ${specialStyles}
`

const Header = styled.div`
  height: 50px;
  background-color: black; // $menu-color;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const LangIndicator = styled.div`
  display: flex;
  justify-content: center;
  z-index: 10;

  color: #fff !important;
  letter-spacing: 4px;
  padding-right: 15px;
  text-transform: uppercase;

  font-size: 0.8em;
  font-weight: 700;
`

const Body = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% - 50px);
  ${specialStyles}
`

export const SocialImage = ({
  pageContext = {},
  preview = false,
  className = "",
}) => {
  const {
    titleVideoPoster,
    langId,
    bodyClass = "",
    socialImgOptions = {},
  } = pageContext
  const { showSubtitle } = socialImgOptions
  const title = socialImgOptions.title || pageContext.title
  const subtitle = socialImgOptions.subtitle || pageContext.description

  const catClasses = pageContext.categories
    .map(({ name }) => `cat-${name}`)
    .join(" ")

  const theme = (currentTheme) => ({
    ...currentTheme,
    colors: {
      current: pageContext.themeColor,
    },
  })
  // console.log("RENDERING SOCIAL IMG", title, titleVideoPoster)

  const authors = pageContext.authors.map(({ name }) => name).join(", ")
  return (
    <ThemeProvider theme={theme}>
      <SocialImageWrapper
        className={`social-image ${catClasses} ${
          preview ? "preview" : ""
        } ${className}`}
      >
        <Header className={`social-image-header ${className}`}>
          <SiteLogo
            className="social-image-logo"
            logos={getLogos(pageContext)}
            langId={langId}
          />
          <LangIndicator className="social-image-lang-indicator">
            {langId}
          </LangIndicator>
        </Header>
        <Body className={`social-image-body ${bodyClass} ${className}`}>
          <Cover posterSrc={titleVideoPoster}>
            <h3 className="social-image-author">{authors}</h3>
            <h1>{title}</h1>
            {showSubtitle && <h2>{stripTags(subtitle)}</h2>}
          </Cover>
        </Body>
      </SocialImageWrapper>
    </ThemeProvider>
  )
}

export default SocialImage

function stripTags(string) {
  if (typeof string === "string") return string.replace(/(<([^>]+)>)/gi, "")
}
