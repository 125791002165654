import { css } from "styled-components"
import { BoxWrapper } from "../../map/map-point.style"
import { SecondaryWrapper } from "../../bg-changer/bg-changer-wrapper.style"

const accentColor = "#99a3b3"

export const propagandaStyles = css`
  &.bg-blue {
    .article-body {
      font-size: 18px;
    }
    .subheading .toc-button-wrapper {
      display: none;
    }
    .extra-column-item.toc {
      background-color: ${accentColor};
    }
    ${BoxWrapper} p {
      background-color: rgb(210, 210, 211);
      color: #1e1e22; // white;
      box-shadow: rgb(240, 185, 73) 8px 8px !important;
      font-family: "Roboto Condensed", sans-serif;
      font-size: 22px;
      @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
        font-size: 20px;
      }
      &.has-background {
        box-shadow: none !important;
        filter: grayscale(0);
        transition: filter 0.3s ease-in-out;
      }
    }
    ${BoxWrapper}:not(.in-view) p.has-background {
      filter: grayscale(1);
    }
    .vis-container p {
      font-family: "Roboto Condensed", sans-serif;
    }
    ${SecondaryWrapper} p.no-box-shadow {
      box-shadow: none !important;
    }
    p + .bg-changer-wrapper {
      margin-top: 6rem !important;
    }
    .bg-changer-wrapper + p {
      margin-top: 6rem !important;
    }
    .bg-changer-wrapper + .reading-progress-bar + p {
      margin-top: 6rem !important;
    }
    .scroll-trigger-wrapper {
      margin: 6rem 0 !important;
      &:first-child {
        margin-top: 0 !important;
      }
    }

    a:not(.link) {
      color: ${accentColor};
      &:hover {
        border-color: ${accentColor};
      }
    }
    blockquote p:before {
      color: ${accentColor};
    }

    .force-graph-container::before {
      display: block;
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url("/images/noise-texture.gif");
      opacity: 0.05;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      pointer-events: none;
    }

    .cover-title-inner h1 {
      @media screen and (min-width: ${(p) => p.theme.breakpointMobile}) {
        font-size: 5rem !important;
      }
    }

    .cover-title-outer.has-background-gradient {
      box-shadow: none !important;
      background-image: none !important;
      &::before {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
        backdrop-filter: blur(50px);
        mask: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 1) 120%
        );
      }

      .mobile-font-size-24 {
        @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
          font-size: 24px !important;
        }
      }
    }
  }
  .tile-inner {
    padding-top: 7.5%;
    padding-bottom: none;
    align-items: flex-start;
    font-size: 1.5rem;
  }

  .cover-title-inner {
    h1,
    h2 {
      text-transform: none;
      letter-spacing: 0;
      margin: 0 0 1em 0 !important;
    }
  }

  .social-image-body {
    .cover-title-outer {
      box-shadow: inset 0 0 150px #000 !important;
      background-image: radial-gradient(
        transparent 80%,
        rgba(0, 0, 0, 0.2)
      ) !important;
    }
    .cover-title-inner {
      padding: 2rem;
      align-items: flex-start;
      justify-content: flex-start;
    }
    h1 {
      text-transform: none;
      letter-spacing: 0;
      max-width: 65%;
      font-size: 5rem !important;
      font-weight: 700;
      text-align: left;
      margin: 0 !important;
    }
    &.insta-story h1 {
      font-size: 3rem !important;
    }
    &.insta-story,
    &.insta-post {
      h1 {
        max-width: 90%;
      }
    }
  }
`
