import React, { useMemo, cloneElement } from "react"
import { v4 as uuidv4 } from "uuid"
import type { Transformer } from "gatsby-source-dek-wp"

import parse, {
  DOMNode,
  attributesToProps,
  domToReact,
} from "html-react-parser"

import {
  galleryTransformer,
  mediaTextTransformer,
  footnoteTransformer,
  carouselTransformer,
  tocTransformer,
  subheadingTransformer,
  introTransformer,
  imageTransformer,
  youtubeVideoTransformer,
  embedTransformer,
  termFreqToolTransformer,
  termSetterTransformer,
} from "gatsby-source-dek-wp"
import { quizTransformer } from "../components/quiz/quiz.transformer"
import bgChangerTransformer from "../components/bg-changer/bg-changer.transformer"
import imageMapTransformer from "../components/image-map/image-map.transformer"
import { fogTransformer } from "../components/fog-overlay/fog-overlay.transformer"
import { mapTransformer } from "../components/map/map.transformer"
import { mapLayerTransformer } from "../components/map/map-layer.transformer"
import { scrollTriggerTransformer } from "../components/scroll-trigger/scroll-trigger.transformer"
import { internalTeaserTransformer } from "../components/internal-teaser/internal-teaser.transformer"
import { threejsCanvasTransformer } from "../components/threejs-canvas/threejs-canvas.transformer"
import {
  annotationTransformer,
  linkTransformer,
} from "gatsby-plugin-popup-preloader"
import { metaInfoTransformer } from "../components/meta-info/meta-info.transformer"
import { coverTransformer } from "../components/cover/cover.transformer"
import { cardDeckTransformer } from "../components/card-deck/card-deck.transformer"
import { timeMachineTransformer } from "../components/time-machine/time-machine.transformer"
import { scriptTransformer } from "./script.transformer"
import { splitWordsTransformer } from "./split-words"
import { mapPointTransformer } from "../components/map/map-point.transformer"
import { splitViewTransformer } from "../components/split-view/split-view.transformer"
import { mapRangeSelectTransformer } from "../components/map/range-select.transformer"

let transformers: Transformer[] = [
  quizTransformer,
  introTransformer,
  subheadingTransformer,
  bgChangerTransformer,
  imageMapTransformer,
  fogTransformer,
  tocTransformer,
  carouselTransformer,
  mapTransformer,
  mapPointTransformer,
  mapLayerTransformer,
  mapRangeSelectTransformer,
  scrollTriggerTransformer,
  internalTeaserTransformer,
  galleryTransformer,
  mediaTextTransformer,
  footnoteTransformer,
  linkTransformer,
  annotationTransformer,
  metaInfoTransformer,
  imageTransformer,
  youtubeVideoTransformer,
  embedTransformer,
  coverTransformer,
  cardDeckTransformer,
  threejsCanvasTransformer,
  timeMachineTransformer,
  termFreqToolTransformer,
  termSetterTransformer,
  scriptTransformer,
  splitWordsTransformer,
  splitViewTransformer,
]

const options = {
  replace: transform,
}

export function transform(node: DOMNode) {
  // node, i
  // if (node.name === "style") return <></> // null
  if (!["tag", "script"].includes(node.type)) return
  const classList = nodeClassList(node)
  const style = "attribs" in node ? attributesToProps(node.attribs).style : {}
  let children = getChildren(node)
  if ("name" in node && node.name === "script") {
    // allow script tags having children
    children = children.map((c) => transformString(`${c}`))
  }

  for (const t of transformers) {
    if (typeof t !== "function") {
      console.error("transformer is not a function", t)
      continue
    }
    const options = { classList, style, children }
    const comp = t(node, 0, options)
    if (comp)
      return cloneElement(comp, {
        ...comp.props,
        key: comp.props?.fixedKey || uuidv4(),
      })
  }
}

export function transformString(contentString: string) {
  return parse(contentString || "", options)
}

export function useTransformer(contentString: string) {
  const transformedContent = useMemo(
    () => transformString(contentString),
    [contentString],
  )
  return transformedContent
}

function getChildren(node: DOMNode) {
  const c =
    "children" in node
      ? domToReact(node.children.filter(isDOMNode), options)
      : []
  return React.Children.toArray(c).filter(isNotNewLine)
}

function nodeClassList(node: DOMNode) {
  if (!("attribs" in node)) return []
  if (!node.attribs || !node.attribs.class) return []
  return node.attribs.class.split(" ")
}

function isNotNewLine(child: React.ReactNode) {
  if (typeof child === "string" && child.trim() === "") return false
  else return true
}

function isDOMNode(child: any): child is DOMNode {
  return "type" in child
}
