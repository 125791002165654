import React from "react"
import { BoxWrapper, MapPointWrapper } from "../map/map-point.style"
import { ScrollDownButton } from "./scroll-down-button"
import { useExternalCallbacks } from "./external-callbacks"

interface ScrollTriggerProps {
  id?: string
  children: JSX.Element[]
  styleChildren?: JSX.Element[]
  isFirst: boolean
  isLast: boolean
  script?: JSX.Element
}

export const ScrollTrigger: React.FC<ScrollTriggerProps> = ({
  children,
  styleChildren,
  isLast,
  id,
  script,
}) => {
  const [ref] = useExternalCallbacks(id, isLast)
  return (
    <MapPointWrapper
      ref={ref}
      className="scroll-trigger"
      // style={{ border: `1px dashed ${inView ? "green" : "yellow"}` }}
    >
      {children.map((c, i) => {
        const isFirst = i === 0
        const isScreenCenter = (c?.props?.className || "").includes("pt-50")
        return (
          <BoxWrapperWithInViewTrigger
            // style={i === 0 ? { paddingTop: "50vh" } : undefined}
            script={c?.props?.script}
            id={c?.props?.id}
            key={i}
            className={`${c?.props?.className}`}
          >
            {c}
            {isFirst && isScreenCenter && false && <ScrollDownButton />}
          </BoxWrapperWithInViewTrigger>
        )
      })}
      {!!script && script}
      {styleChildren}
    </MapPointWrapper>
  )
}

interface BoxWrapperProps {
  id: string
  script?: React.ReactNode
  children?: React.ReactNode
  className?: string
  [key: string]: any
}

export function BoxWrapperWithInViewTrigger(props: BoxWrapperProps) {
  const { id, script, children, className, ...otherProps } = props
  const [ref, inView] = useExternalCallbacks(id)
  return (
    <BoxWrapper
      ref={ref}
      className={`${!!className && className} ${inView ? "in-view" : ""}`}
      {...otherProps}
    >
      {children}
      {!!script && script}
    </BoxWrapper>
  )
}
