import React, { useContext, useState, useEffect, createContext } from "react"
import { RangeSelect } from "gatsby-source-dek-wp"
import styled from "styled-components"
import { LayerContext } from "./map"
import { useCtx } from "../scroll-trigger/external-callbacks"
import { createTheme } from "@mui/material"
import { mapboxMapLoaded } from "./map-layer"

export const RangeContext = createContext<Range>([0, 100])
export const RangeSetContext = createContext<
  React.Dispatch<React.SetStateAction<Range>>
>(() => {})
export interface MapRangeSelectProps {
  min: number
  max: number
  initialMin?: number
  initialMax?: number
}

export type Range = [number, number]

export const MapRangeSelect: React.FC<MapRangeSelectProps> = ({ min, max }) => {
  const range = useContext(RangeContext)
  const setRange = useContext(RangeSetContext)
  const layers = useContext(LayerContext)
  const ctx = useCtx()
  useEffect(() => {
    async function runOnRangeChange() {
      if (!ctx.map) return
      await Promise.all(
        layers.map((layer) =>
          layerIsLoaded(ctx.map as mapboxgl.Map, layer.props.layerProps.id),
        ),
      )
      for (const layer of layers) {
        const onRangeChange = `onRangeChange_${layer.props.layerProps.id}`
        if (typeof window[onRangeChange] === "function") {
          // console.log(onRangeChange)
          window[onRangeChange]({ ...ctx, range })
        }
      }
    }
    runOnRangeChange()
  }, [layers, ctx, range])
  const theme = createTheme({
    palette: {
      primary: {
        light: "#003f5c",
        main: "#003f5c",
        dark: "#003f5c",
        contrastText: "#000",
      },
    },
  })
  return (
    <RangeSelectWrapper>
      <div>{range[0]}</div>
      <RangeSelect
        range={range}
        setRange={setRange}
        min={min}
        max={max}
        theme={theme}
      />
      <div>{range[1]}</div>
    </RangeSelectWrapper>
  )
}

function layerIsLoaded(map: mapboxgl.Map, layerId: string) {
  return new Promise((resolve) => {
    const isLoaded = () =>
      !!map.getSource(layerId)?.serialize().data.features.length
    if (isLoaded()) {
      // console.log("layer is already loaded", layerId)
      resolve(true)
    } else {
      const checkLayer = () => {
        // console.log("checking layer", layerId)
        if (isLoaded()) {
          map.off("styledataloading", checkLayer)
          map.off("render", checkLayer)
          resolve(true)
        }
      }

      map.on("styledataloading", checkLayer)
      map.on("render", checkLayer)
    }
  })
}

const RangeSelectWrapper = styled.div`
  pointer-events: auto;
  max-width: 700px;
  position: absolute;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  @media screen and (min-width: ${(p) => p.theme.breakpointMobile}) {
    .map-type-fullscreen & {
      width: 600px;
      top: auto;
      bottom: 5vh;
    }
  }
  .map-type-box & {
    top: -34px;
    bottom: auto;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  > div:nth-child(1),
  > div:nth-child(3) {
    font-size: 1.2rem;
    padding: 1em 0.5em;
    background-color: #003f5c;
    font-weight: 800;
    color: white;
  }
  > div:nth-child(2) {
    margin: 0;
    padding: 0.5em 1.5em;
    transform: translateY(3px);
    flex: 1;
    .MuiSlider-valueLabelLabel {
      display: none;
      font-size: 2rem;
      color: white !important;
    }
    .MuiSlider-thumb[data-index="1"] .MuiSlider-valueLabelLabel > span {
      transform: translateY(130%);
    }
  }
`
