import { useReducer, useEffect, useContext } from "react"
import { getColor } from "./term-colors"
import { LangContext } from "gatsby-source-dek-wp"
import { LangDef } from "../../hooks/langs"
import { DEFAULT_TERM_STATE } from "./contexts/terms"
import { TermObj } from "./term-setter/term-obj"

export interface TermState {
  langId: LangDef["id"]
  terms: TermObj[]
  markers: Marker[]
  range?: [number, number]
}

export interface Marker {
  value: number // year
  label?: string
}

const ADD_TERM = "addTerm" as const
const SET_TERMS_ARR = "setTermsArr" as const
const REMOVE_TERM = "removeTerm" as const
const MERGE_TERMS = "mergeTerms" as const
const REMOVE_ALL_TERMS = "removeAllTerms" as const
const SET_TERM_LANG = "setTermLang" as const
const SET_MARKERS = "setMarkers" as const
const SET_RANGE = "setRange" as const

export type Action =
  | {
      type: typeof ADD_TERM
      term: string
      color?: string
      translation?: string
    }
  | {
      type: typeof SET_TERMS_ARR
      termsArr: TermObj[]
      range?: [number, number]
    }
  | { type: typeof REMOVE_TERM; term: string }
  | { type: typeof MERGE_TERMS; targetTerm: string; sourceTerm: string }
  | { type: typeof REMOVE_ALL_TERMS }
  | { type: typeof SET_TERM_LANG; langId: string }
  | { type: typeof SET_MARKERS; markers: any[] }
  | { type: typeof SET_RANGE; range: [number, number] }

export const addTerm = (
  term: string,
  color?: string,
  translation?: string
): Action => ({
  type: ADD_TERM,
  term,
  color,
  translation,
})

export const setTermsArr = (
  termsArr: TermObj[],
  range: [number, number]
): Action => ({
  type: SET_TERMS_ARR,
  termsArr,
  range,
})
export const removeTerm = (term: string): Action => ({
  type: REMOVE_TERM,
  term,
})
export const mergeTerms = (targetTerm: string, sourceTerm: string): Action => ({
  type: MERGE_TERMS,
  targetTerm,
  sourceTerm,
})
export const removeAllTerms = (): Action => ({ type: REMOVE_ALL_TERMS })
export const setMarkers = (markers: Marker[]): Action => ({
  type: SET_MARKERS,
  markers,
})
export const setRange = (range: [number, number]): Action => ({
  type: SET_RANGE,
  range,
})

function termReducer(state: TermState, action: Action): TermState {
  // console.log("REDUCER", action, state)
  switch (action.type) {
    case ADD_TERM:
      if (state.terms.find((t) => t.term === action.term)) return state
      const usedColors = state.terms.map((t) => t.color ?? "")
      const newColor = getColor(usedColors, usedColors, action.term) // state.terms.length
      // console.log(action.term, action.color, newColor)
      return {
        ...state,
        terms: [
          ...state.terms,
          {
            term: action.term,
            color: action.color || newColor,
            translation: action.translation,
          },
        ],
      }
    case SET_TERMS_ARR:
      return {
        ...state,
        terms: (action.termsArr || []).map((t) => ({
          ...t,
          color: t.color || getColor([], [], t.term),
        })),
        range: action.range ?? DEFAULT_TERM_STATE.range,
      }
    case REMOVE_TERM:
      return {
        ...state,
        terms: state.terms.filter((t) => t.term !== action.term),
      }
    case MERGE_TERMS:
      return {
        ...state,
        terms: state.terms.reduce((acc, t) => {
          if (t.term === action.sourceTerm) return acc
          // sourceTerm will be gone
          else if (t.term === action.targetTerm) {
            const mergedTerms = [
              ...new Set([
                ...t.term.split("+"),
                ...action.sourceTerm.split("+"),
              ]),
            ]
            return [...acc, { ...t, term: mergedTerms.join("+") }]
          } else return [...acc, t]
        }, [] as TermObj[]),
      }
    case REMOVE_ALL_TERMS:
      return { ...state, terms: [] }
    case SET_TERM_LANG:
      return { ...state, langId: action.langId }
    case SET_MARKERS:
      return {
        ...state,
        markers: action.markers ?? [],
      }
    case SET_RANGE:
      return {
        ...state,
        range: action.range ?? [],
      }
    default:
      throw new Error()
  }
}

export function useTermReducer(langFromArg?: LangDef) {
  const langFromContext = useContext(LangContext)
  const lang = langFromContext ?? langFromArg
  const initState = {
    ...DEFAULT_TERM_STATE,
    langId: lang.id,
  } as TermState
  const [termState, termDispatch] = useReducer(termReducer, initState)
  useEffect(() => {
    termDispatch({ type: SET_TERM_LANG, langId: lang.id })
  }, [lang.id])
  useEffect(() => {
    // set by hash
    if (!window.location.hash) return
    const hashQuery = window.location.hash
      .split("#")[1]
      .split("&")
      .find((h) => h.match(/^q=/))
    if (!hashQuery) return
    const terms = decodeURIComponent(hashQuery.replace("q=", "")).split(",")
    if (terms.length) termDispatch(removeAllTerms())
    terms.forEach((t) => {
      termDispatch(addTerm(t))
    })
  }, [])
  return [termState, termDispatch] as const
}
