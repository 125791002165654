import styled from "styled-components"

const BG_DARKNESS = 0.1

interface DeckWrapperProps {
  backgroundImage?: string
}

export const CardDeckWrapper = styled.div<DeckWrapperProps>`
  width: 100%;
  max-width: none !important;
  margin: 0 auto;
  margin-bottom: 0 !important;
  // margin-top: -5px !important; // TODO !
  min-height: calc(100vh - 50px);
  position: relative;
  height: 100%;

  display: flex;
  flex-direction: column;

  overflow-x: clip;
  overflow-y: visible;

  @media print {
    display: none;
  }

  // border: 1px dashed rgb(200, 200, 200);
  background-attachment: fixed;
  background-size: cover;
  background-position: left center;
  background-image: ${(p) =>
    p.backgroundImage
      ? `linear-gradient(rgba(0,0,0,${BG_DARKNESS}), rgba(0,0,0,${BG_DARKNESS})), url(${p.backgroundImage})`
      : ""};

  @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
    padding: 0 !important;
    .card-deck-inner {
      // padding-bottom: 7em;
    }
    // overflow: hidden;
  }

  a:hover {
    border: none !important;
  }

  &.cards-1 {
    background-attachment: scroll;
  }
  .card-deck-bg-image {
    display: none;
    z-index: 0;
    width: 100%;
    height: 100vh;
    position: sticky;
    top: 0px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 0 center;
    background-image: ${(p) =>
      p.backgroundImage
        ? `linear-gradient(rgba(0,0,0,${BG_DARKNESS}), rgba(0,0,0,${BG_DARKNESS})), url(${p.backgroundImage})`
        : ""};
  }
  @media screen and (max-width: ${(p) => p.theme.breakpointTablet}) {
    &:not(.cards-1) {
      background-image: none;
      .card-deck-bg-image {
        display: block;
      }
      > *:not(.card-deck-bg-image) {
        z-index: 1;
        position: relative;
      }
      .card-deck-bg-image + * {
        margin-top: -100vh !important;
      }
    }
  }
  &.template-translator,
  &.template-translator-front {
    h1,
    h2 {
      font-family: "Courier", "Courier New";
      color: white;
      padding: 0 1rem 0;
      max-width: 60%;
    }
    h1 {
      color: white;
      margin: 0 !important;
      @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
        font-size: ${(p) => p.theme.fontSizeH3};
      }
    }
    h2 {
      margin-top: 0;
      padding-top: 1rem;
      @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
        font-size: ${(p) => p.theme.fontSizeH5};
      }
    }
  }
  &.template-translator {
    h1 {
      opacity: 0;
      pointer-events: none;
      user-select: none;
      height: 1px;
      overflow: hidden;
    }
    h2 {
      font-size: ${(p) => p.theme.fontSizeH3};
    }
  }
  &.template-translator-front {
    h1,
    h2 {
      max-width: none;
      width: 100%;
      text-align: center;
    }
    h1 {
      font-weight: 700;
      padding-top: 1.5rem;
    }
    h2 {
      font-weight: 400;
    }
  }
`

export const CardDeckInner = styled.div`
  flex: 1 1 auto; // consume remaining space
  display: flex;
  flex-direction: column;

  user-select: none;
  // border: 1px dashed red;
  position: relative;
  height: 100%;
  width: 100%;
  padding-top: 3em;
  @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
    padding-top: 2em;
  }
  body.menu-open & {
    opacity: 0;
  }
`

interface CardWrapperProps {
  hidden?: boolean
  filtered?: boolean
}

export const CardWrapper = styled.div<CardWrapperProps>`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  // width: 200px;
  // height: 200px;
  pointer-events: none;
  /* border: 1px solid red; */
  transition: 0.7s ease-in-out;
  opacity: ${(p) => (p.hidden || p.filtered ? 0 : 1)};

  // border: 1px dashed red;
`
