import { css } from "styled-components"

export const chronicleStyles = css`
  .wp-block-quote {
    border-left: 3px solid ${(p) => p.theme.articleRed};
    padding-left: 1.5em;
    margin-top: 1.5em !important;
    margin-bottom: 1.5em !important;
    p {
      font-size: 1em;
      font-weight: normal;
      margin-left: 0;
      &:before {
        width: auto;
        margin-right: 0.25em;
        font-weight: 700;
      }
    }

    cite {
      em {
        font-style: normal !important;
      }
      font-family: "Roboto Condensed";
      font-style: normal !important;
      font-weight: 300;
      font-size: ${(p) => p.theme.fontSizeSmall};
    }
  }
  h4 {
    button {
      @media screen and (min-width: ${(p) => p.theme.breakpointMobile}) {
        margin-left: -2em;
      }
    }
  }
`
