import React, {
  useContext,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react"
import { useUid } from "../../modules/hooks"
import { MapPointContext, setPreviousY } from "./map-points" // getPreviousY
import {
  MapPointRoute,
  add,
  remove,
  setCurrentPoint,
} from "./map-point-reducer"
import { useInView } from "react-intersection-observer"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons"
import scrollIntoView from "scroll-into-view"
import { BoxWrapper, MapPointWrapper, TitleWrapper } from "./map-point.style"
import { LockContext } from "./lock-context"
import { isElement } from "gatsby-source-dek-wp"
import { COLORS } from "./routes"

export const SCROLL_OFFSET_TOP = 50 // 15 // in percent

let _isJumping = false
function setIsJumping(val: boolean) {
  _isJumping = val
}
function isJumping() {
  return _isJumping
}

interface MapPointH4Props {
  isActive: boolean
  categoryColor: string
  onClick: () => void
  children: React.ReactNode
  id: string
  lat: number
  lon: number
  category: string
  zoom: number
}

const MapPointH4 = ({
  isActive,
  categoryColor,
  onClick,
  children,
  id,
  lat,
  lon,
  category,
  zoom,
}: MapPointH4Props) => {
  const [, dispatch] = useContext(MapPointContext)
  const [inViewRef, inView] = useInView({
    threshold: 0,
    rootMargin: `-${SCROLL_OFFSET_TOP}% 0% -${100 - SCROLL_OFFSET_TOP}% 0%`,
  })

  useEffect(() => {
    if (!inView) return
    if (isJumping()) return
    const point = { id, lat, lon, category, zoom }
    dispatch(setCurrentPoint(point))
  }, [lat, lon, category, id, dispatch, inView, zoom])

  return (
    <TitleWrapper ref={inViewRef}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <h4
          style={{
            paddingRight: ".5em",
            color: isActive ? categoryColor : "#cacaca",
          }}
        >
          <button onClick={onClick}>
            <FontAwesomeIcon icon={faMapMarkerAlt} />
          </button>
        </h4>
        <button onClick={onClick} style={{ textAlign: "left" }}>
          {children}
        </button>
      </div>
    </TitleWrapper>
  )
}

interface MapPointProps {
  lat: number
  lon: number
  category: string
  children: React.ReactElement[]
  zoom: number
  hidden?: boolean
}

export const MapPoint: React.FC<MapPointProps> = ({
  lat,
  lon,
  category,
  children,
  zoom,
  hidden,
}) => {
  const id = useUid()
  const scrollRef = useRef<HTMLDivElement>(null)
  const [{ currentPoint, routes }, dispatch] = useContext(MapPointContext)
  const [inViewRef, inView, entry] = useInView({
    threshold: 0,
    rootMargin: `-${SCROLL_OFFSET_TOP}% 0% -${100 - SCROLL_OFFSET_TOP}% 0%`,
  })
  const { setIsLocked } = useContext(LockContext)
  const onClick = useCallback(() => {
    if (!scrollRef.current) return
    setIsLocked(true)
    setIsJumping(true)
    scrollIntoView(
      scrollRef.current,
      {
        align: { topOffset: window.innerHeight / 4 },
      },
      () => setIsJumping(false),
    )
    dispatch(setCurrentPoint({ id, lat, lon, category }))
  }, [category, dispatch, id, lat, lon, setIsLocked])

  useEffect(() => {
    dispatch(add({ id, lat, lon, category, onClick, children, hidden }))
    return () => {
      dispatch(remove(id))
    }
  }, [lat, lon, id, dispatch, category, onClick, children, hidden])

  useEffect(() => {
    if (!inView) return
    if (currentPoint?.id === id) return

    if (isJumping()) return

    // zoom 3 only when scrolling from top
    const currentY = entry?.boundingClientRect.y
    // const scrollingDown = currentY > getPreviousY()
    // console.log(`scrolling ${scrollingDown ? "down" : "up"}`)
    // zoom: scrollingDown ? 3 : zoom
    dispatch(setCurrentPoint({ id, lat, lon, category, zoom }))
    if (currentY) setPreviousY(currentY)
  }, [id, lat, lon, category, dispatch, inView, entry, currentPoint, zoom])

  const isActive = currentPoint?.id === id
  const categoryColor = useCategoryColor(category, routes)
  return (
    <div ref={scrollRef} style={{ display: hidden ? "none" : "block" }}>
      <MapPointWrapper ref={inViewRef} className={hidden ? "hidden" : ""}>
        {React.Children.toArray(children).map((c, i) =>
          isElement(c) && c.type === "h4" ? (
            <MapPointH4
              {...{
                id,
                lat,
                lon,
                category,
                zoom,
                // scrollRef,
                isActive,
                categoryColor,
                onClick,
              }}
              key={i}
            >
              {c}
            </MapPointH4>
          ) : (
            <BoxWrapper color={categoryColor} key={i}>
              {c}
            </BoxWrapper>
          ),
        )}
      </MapPointWrapper>
    </div>
  )
}

function useCategoryColor(category: string, routes: MapPointRoute[]) {
  return useMemo(
    () => (routes.find((r) => r.name === category) || {})?.color || COLORS[0],
    [category, routes],
  )
}
