import { useRef, useState, useCallback, useEffect } from "react"

export function useMinHeight(width: number) {
  const ref = useRef()
  const [minHeight, setMinHeight] = useState("500px")
  const [imgHeight, setImgHeight] = useState(0)
  const handleActiveEl = useCallback((el: HTMLElement) => {
    const newMinHeight = el?.offsetHeight
    if (newMinHeight) setMinHeight(`${newMinHeight}px`)
    const newImgHeight = getImgHeight(el)
    setImgHeight(newImgHeight)
  }, [])
  useEffect(() => {
    if (!ref.current) return
    handleActiveEl(ref.current)
  })
  return [ref, minHeight, imgHeight] as const
}

export function useOverallMinHeight(wrapperRef: React.RefObject<HTMLDivElement>) {
  // TODO: for show-overflow ? 
}

function getImgHeight(el: HTMLElement) {
  const children = el?.children
  if (!children || !children.length) return 0
  const child = children[0]
  if (child.tagName !== "FIGURE") return 0
  const img = Array.from(child.children).find(e => e.tagName === "IMG") as HTMLImageElement | undefined
  if (!img) return 0
  const imgHeight = img.offsetHeight
  return imgHeight
}