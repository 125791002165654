import React, { createContext, useContext } from "react"
import { LangContext } from "gatsby-source-dek-wp"
import { QuizItemWrapper, QuizOptions, QuizCounter } from "./quiz-item.style"

export const QuizItemContext = createContext()

const correct = {
  de: "Richtig",
  ru: "Верно",
}

const incorrect = {
  de: "Falsch",
  ru: "Неверно",
}
const QuizItemFeedback = ({ explanation, selection }) => {
  const langId = useContext(LangContext).id
  if (!selection) return null
  return (
    <div className="quiz-item-feedback">
      <b>{selection.isCorrect ? correct[langId] : incorrect[langId]}!</b>
      {explanation}
    </div>
  )
}

const QuizItem = (props) => {
  const { question, options, img, total, id, selection } = props
  return (
    <QuizItemContext.Provider value={{ id, selection }}>
      <QuizItemWrapper>
        <QuizCounter>
          {id + 1} / {total}
        </QuizCounter>
        {img}
        {question}
        <QuizOptions>{options}</QuizOptions>
        <QuizItemFeedback {...props} />
      </QuizItemWrapper>
    </QuizItemContext.Provider>
  )
}

export default QuizItem
