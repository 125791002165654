import React, { useContext } from "react"
import { MetaInfo } from "../meta-info/meta-info"
import ReadingProgressBar from "../reading-progress-bar/reading-progress-bar"
import { Cover, CoverProps } from "./cover"
import { PageInfoContext } from "../article/article"

interface CoverAsTitleProps extends CoverProps {
  credit?: string | React.ReactNode
  isFirst?: boolean
}

export const CoverAsTitle = (props: CoverAsTitleProps) => {
  const { credit, isFirst } = props
  const creditText = React.isValidElement(credit)
    ? credit?.props?.children // if element
    : props.credit // if string
  const { hasCustomMeta } = useContext(PageInfoContext)
  return (
    <>
      <Cover {...props} adjustToViewport={true} />
      {isFirst && <ReadingProgressBar />}
      {isFirst && !hasCustomMeta && <MetaInfo titleVideoSource={creditText} />}
    </>
  )
}
