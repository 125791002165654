import React, { useMemo } from "react"

const MAX_DEVIATION_DESKTOP = 50
const MAX_DEVIATION_MOBILE = 20

export default function useInitPoses(
  children: React.ReactNode[],
  isMobile?: boolean,
) {
  return useMemo(
    () => children.map((c, i) => getInitPos(i, isMobile), children.length),
    [children, isMobile],
  )
}

function getInitPos(i: number, isMobile?: boolean) {
  const xMax = isMobile ? MAX_DEVIATION_MOBILE : MAX_DEVIATION_DESKTOP
  const yMax = isMobile ? MAX_DEVIATION_MOBILE : MAX_DEVIATION_DESKTOP
  const x = -xMax + Math.random() * xMax * 2
  const y = -yMax + Math.random() * yMax * 2
  const result = `translateX(${Math.round(x)}px) translateY(${Math.round(y)}px)`
  return result
}
