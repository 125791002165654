import { useMemo, cloneElement } from "react"

export default function useEnrichedItems(items, userSelections) {
  return useMemo(
    () =>
      items.map((item, id) => {
        const enrichedProps = {
          ...item.props,
          id,
          selection: userSelections.find((s) => s.id === id),
          total: items.length,
        }
        const enrichedItem = cloneElement(item, enrichedProps)
        return enrichedItem
      }),
    [items, userSelections],
  )
}
