import { attributesToProps } from "html-react-parser"
import React from "react"
import { Img } from "./image"

export function imageTransformer(node, i, { classList, children }) {
  if (node.name === "img") {
    const props = attributesToProps(node.attribs)
    return <Img {...props} />
  }
}
