import React from "react"
import { CoverAsTitle } from "./cover-as-title"
import { H1 } from "./h1"
import { isElement, Transformer } from "gatsby-source-dek-wp"

export const coverTransformer: Transformer = (
  node,
  i,
  { classList, children: _children },
) => {
  if (classList.includes("wp-block-cover")) {
    const isFirst = !node.parent && !node.prev?.prev
    const children = React.Children.toArray(_children).filter(isElement)
    const videoSrc = children.find((c) => c.type === "video")?.props?.src
    const imgEl = children.find((c) =>
      (c?.props?.className || "").includes("wp-block-cover__image-background"),
    )
    const imgSrc = imgEl?.props?.src
    const credit = imgEl?.props?.alt
    // TODO: videoPoster ?!?
    const posterSrc = imgSrc
    const _innerChildren =
      children.find((c) =>
        (c?.props?.className || "").includes("wp-block-cover__inner-container"),
      )?.props?.children || []
    const innerChildren = _innerChildren.map((c: React.ReactElement) => {
      if (
        c.type === "p" &&
        (c.props.className || "").includes("has-large-font-size")
      ) {
        return <H1 {...c.props}>{c.props.children}</H1>
      } else if (c.type === "p") {
        return <h2 {...c.props}>{c.props.children}</h2>
      } else return c
    })
    const hasTextAlignLeft = innerChildren
      .find((c) => c.type === H1)
      ?.props?.className?.includes("has-text-align-left")
    classList = hasTextAlignLeft
      ? [...classList, "has-text-align-left"]
      : classList
    const objectPosition =
      imgEl?.props?.["data-object-position"] || "center center"

    const hasBackgroundGradient = children.find((c) =>
      (c.props?.className || "").includes("has-background-gradient"),
    )
    classList = hasBackgroundGradient
      ? [...classList, "has-background-gradient"]
      : classList
    return (
      <CoverAsTitle
        className={classList.join(" ")}
        {...{ videoSrc, posterSrc, credit, isFirst, objectPosition }}
      >
        {innerChildren}
      </CoverAsTitle>
    )
  }
  if (classList.includes("wp-block-dekoder-custom-blocks-fullscreen-title")) {
    const isFirst = !node.parent && !node.prev?.prev
    const children = React.Children.toArray(_children).filter(isElement)
    const figure = children.find((c) => c.type === "figure")
    if (!figure) return null
    const filteredChildren = children.filter((c) => c !== figure)
    const isVideo = figure.props.className.includes("wp-block-video")

    const figureChildren = React.Children.toArray(figure.props.children).filter(
      isElement,
    )
    const credit = figureChildren.find((c) => c.type === "figcaption")
    const visualComp = figureChildren[0] // img or video
    const src = visualComp.props.src
    const poster = visualComp.props.poster
    const videoSrc = isVideo ? src : ""
    const posterSrc = isVideo ? poster : src
    return (
      <CoverAsTitle {...{ videoSrc, posterSrc, classList, credit, isFirst }}>
        {filteredChildren}
      </CoverAsTitle>
    )
  }
}
