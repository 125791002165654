import { useEffect } from "react"

export function useKeyboardNav(inView: boolean, changeSlideKey: (n: number) => void) {
  useEffect(() => {
    if (!inView) return
    function handleKeydown(e: KeyboardEvent) {
      if (e.code === "ArrowLeft") changeSlideKey(-1)   
      else if (e.code === "ArrowRight") changeSlideKey(1)
    }
    document.addEventListener("keydown", handleKeydown)
    return () => document.removeEventListener("keydown", handleKeydown)
  }, [inView, changeSlideKey])
}