import styled from "styled-components"

export const FlexWrapper = styled.div<{
  ref: (instance: HTMLDivElement | null) => void // calback ref
}>`
  position: relative;
  // border: 1px dashed yellow;
`

export const FlexItemWrapper = styled.div`
  position: absolute;
  transition: 0.7s ease-in-out;
`
