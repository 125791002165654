import React from "react"
import { SplitViewWrapper } from "./split-view.style"
import { withVisComps } from "../scroll-trigger/vis-container"
import { MapProvider } from "react-map-gl"

interface SplitViewProps {
  firstChild: React.ReactElement
  children: React.ReactNode[]
  className?: string
}

const SecondaryWrapper: React.FC = (props) => (
  <div className="split-view__secondary" {...props}></div>
)
const SecondaryWrapperWithVisComps = withVisComps(SecondaryWrapper)

export const SplitView: React.FC<SplitViewProps> = ({
  firstChild,
  children,
  className,
}) => (
  <MapProvider>
    <SplitViewWrapper className={className}>
      <div className="split-view__primary">{firstChild}</div>
      <SecondaryWrapperWithVisComps>{children}</SecondaryWrapperWithVisComps>
    </SplitViewWrapper>
  </MapProvider>
)
