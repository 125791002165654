import React, { Suspense } from "react"
const ImageMap = React.lazy(() => import("./image-map"))

function imageMapTransformer(node, i, { classList, children }) {
  if (classList.includes("wp-block-image-map-pro-image-map-pro")) {
    if (!children.length) {
      console.error("imageMapTransformer: no children")
      return null
    }
    const id = children[0].props.id
    const settingsStr = children[0].props.children
    const settings = JSON.parse(settingsStr.replace(/\\/g, ""))
    return (
      <Suspense fallback={<div>Loading ...</div>}>
        <ImageMap id={id} settings={settings} />
      </Suspense>
    )
  }
}

export default imageMapTransformer
