import React from "react"
import { Intro } from "../intro"
import { Transformer } from "../../types"
import { SocialBar } from "./social-bar"

export const introTransformer: Transformer = (node, i, { classList, children }) => {
  if (classList.includes("wp-block-dekoder-custom-blocks-intro"))
    return <Intro children={children} className={classList.join(" ")} />
  else if (classList.includes("wp-block-dekoder-custom-blocks-social-bar"))
    return <SocialBar />
}
