import React from "react"
import App from "../app"
import LayoutStandalone from "./layout-standalone"
import * as metaData from "../../../meta-data-es6"
import { LangDef } from "gatsby-source-dek-wp/hooks/langs"

interface Props {
  pageContext: {
    langId: LangDef["id"]
    layout?: string
  }
}

const Layout = (props: Props) => {
  if (props.pageContext.layout === "no-layout") {
    return <LayoutStandalone {...props} metaData={metaData} />
  } else {
    return <App {...props} metaData={metaData} />
  }
}

export default Layout
