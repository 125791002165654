import { css } from "styled-components"

export const coverShadows = css`
  .cover-title-outer {
    box-shadow: inset 0 0 150px #000 !important;
    background-image: radial-gradient(
      transparent 80%,
      rgba(0, 0, 0, 0.2)
    ) !important;
  }
`

export const darkModeStyles = css`
  background: ${(p) => p.theme.bgDark};
  box-shadow: rgb(0, 0, 0) 0px 0px 50px inset;
  color: ${(p) => p.theme.textColorLight || "rgb(250, 250, 250)"};

  ${coverShadows};
`
