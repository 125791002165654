import React from "react"
import { Toc } from "./toc"
import { Transformer } from "../../types"

export const tocTransformer: Transformer = (
  node,
  i,
  { children, classList },
) => {
  if (classList.includes("wp-block-dekoder-custom-blocks-toc"))
    return <Toc classList={classList} />
}
