import React, { useState, useEffect, useCallback, useMemo, useRef } from "react"
import {
  CarouselItem,
  CarouselWrapper,
  HeightWrapper,
  CarouselOuter,
} from "./carousel.style"
import { useIntersectionObserver } from "../../hooks/intersection-observer"
import { useSprings, useSpring, animated } from "@react-spring/web"
import { useDrag } from "@use-gesture/react"
import { Arrow } from "./arrow"
import { useMinHeight, useOverallMinHeight } from "./min-height"
import { useKeyboardNav } from "./keyboard-nav"
import { useResponsiveWidth } from "./responsive-width"
import { Dots } from "./dots"

// https://codesandbox.io/s/github/pmndrs/use-gesture/tree/main/demo/src/sandboxes/viewpager?file=/src/App.tsx

const THRESHOLD_WIDTH_DIVISOR = 4 // = 1/4 of parent width
const SLIDE_PADDING = 30

export const SlideContext = React.createContext<number>(0)

interface CarouselProps {
  children: React.ReactNode[]
  classList?: string[]
  onChange?: (slideKey: number) => void
  dots?: boolean
  showOverflow?: boolean
  selKey?: number
  customArrows?: React.ReactNode[]
  infinite?: boolean
  size?: "normal" | "big" | "full"
}

export const Carousel = ({
  children,
  classList = [],
  onChange,
  dots = true,
  showOverflow = false,
  selKey = 0,
  customArrows,
  infinite = false,
  size = "normal", // "big" / "full"
}: CarouselProps) => {
  const [wrapperRef, width] = useResponsiveWidth()
  const [inViewRef, inView] = useIntersectionObserver({
    rootMargin: "-45% 0px",
  })

  const [currIndex, setCurrIndex] = useState(selKey)
  const getKey = useCallback(
    (idx: number) => {
      const maxK = children.length - 1
      const a = Math.ceil(Math.abs(idx) / maxK)
      const result =
        (idx < 0 ? idx + a * children.length : idx) % children.length
      return result
    },
    [children.length]
  )
  const slideKey = useMemo(() => getKey(currIndex), [currIndex, getKey])
  const changeSlideKey = useCallback(
    (val: number) => {
      // val: +1 or -1
      setCurrIndex((i) =>
        infinite ? i + val : clamp(i + val, 0, children.length - 1)
      )
      return
    },
    [infinite, children.length]
  )

  const getPos = useCallback(
    (shift: number) => {
      return (slideKey + shift + children.length) % children.length
    },
    [slideKey, children.length]
  )

  const isNeighbourByDistance = useCallback(
    (i: number, distance: number) => {
      const isPrev = i === getPos(-distance)
      const isNext = i === getPos(distance)
      // console.log("IS PREV IS NEXT", isPrev, isNext, i, slideKey)
      return isPrev || isNext
    },
    [getPos]
  )

  useEffect(() => {
    // selKey from parent component
    setCurrIndex((currI) => {
      return getKey(currI) === selKey ? currI : selKey
    })
  }, [selKey, getKey])

  useKeyboardNav(inView, changeSlideKey)

  const [activeSlideRef, minHeight, imgHeight] = useMinHeight(width)
  useOverallMinHeight(inViewRef) // const overallMinHeight =

  const wrapperStyles = useSpring({ minHeight })

  const [props, api] = useSprings(children.length, (i) => ({
    x: i * (width + SLIDE_PADDING),
    scale: 1,
    display: "block",
  }))
  const prevPoses = useRef<Record<number, number>>({})

  // const prevKey = usePrev(slideKey)
  const runSprings = useCallback(
    (active = false, mx = 0) => {
      // const dir = slideKey - prevKey
      function getPosForI(i: number, slideKey: number) {
        return !infinite
          ? i - slideKey
          : i === getPos(-1)
          ? -1
          : i === getPos(1)
          ? 1
          : i === getPos(-2)
          ? -2
          : i === getPos(2)
          ? 2
          : i - slideKey
      }

      api.start((i) => {
        const pos = getPosForI(i, slideKey)
        const immediate =
          i !== slideKey &&
          prevPoses.current[i] &&
          Math.abs(pos - prevPoses.current[i]) > 1
        prevPoses.current[i] = pos
        const x = pos * (width + SLIDE_PADDING) + (active ? mx : 0)
        return {
          x,
          scale: active
            ? 1 - Math.abs(mx) / width / THRESHOLD_WIDTH_DIVISOR
            : 1,
          display:
            i !== slideKey &&
            !isNeighbourByDistance(i, 1) &&
            !isNeighbourByDistance(i, -1)
              ? "none"
              : "block",
          immediate,
        }
      })
    },
    [api, isNeighbourByDistance, getPos, slideKey, width, infinite]
  )
  const bind = useDrag(
    (state) => {
      const {
        active,
        movement: [mx],
        direction: [xDir],
        cancel,
        tap,
        args,
      } = state
      if (showOverflow && tap && typeof args?.[0] === "number") {
        setCurrIndex(args[0])
      }
      if (active && Math.abs(mx) > width / THRESHOLD_WIDTH_DIVISOR) {
        changeSlideKey(xDir > 0 ? -1 : 1)
        cancel()
      }
      runSprings(active, mx)
    },
    {
      filterTaps: true,
      preventScroll: true,
      axis: "x",
    }
  )
  useEffect(() => {
    runSprings()
  }, [runSprings])

  useEffect(() => {
    if (typeof onChange === "function") onChange(slideKey)
  }, [slideKey, onChange])
  return (
    <SlideContext.Provider value={slideKey}>
      <CarouselOuter>
        <CarouselWrapper
          ref={wrapperRef}
          className={`carousel-wrapper ${
            inView ? "in-view " : ""
          } ${classList.join(" ")} size-${size} ${
            showOverflow ? "show-overflow" : ""
          }`}
        >
          <HeightWrapper
            ref={inViewRef}
            style={wrapperStyles}
            // style={{ minHeight, transition: "min-height .3s ease-in-out" }}
          >
            {props.map(({ x, display, scale }, i) => (
              <animated.div {...bind(i)} key={i} style={{ display, x }} className={`carousel-item-wrapper ${i === slideKey ? "active" : ""}`}>
                <animated.div style={{ scale }}>
                  <CarouselItem
                    ref={
                      i === slideKey
                        ? (el) => (activeSlideRef.current = el)
                        : undefined
                    }
                    isCurrent={i === slideKey}
                  >
                    {children[i]}
                  </CarouselItem>
                </animated.div>
              </animated.div>
            ))}
          </HeightWrapper>
          <Arrow
            className="carousel-arrow left"
            dir="left"
            aria-label="previous slide"
            inactive={!infinite && slideKey <= 0}
            onClick={() => changeSlideKey(-1)}
            icon={customArrows ? customArrows[0] : null}
            topOffset={
              imgHeight && size !== "full" ? Math.round(imgHeight / 2) : 0
            }
          />
          <Arrow
            className="carousel-arrow right"
            dir="right"
            aria-label="next slide"
            inactive={!infinite && slideKey >= children.length - 1}
            onClick={() => changeSlideKey(1)}
            icon={customArrows ? customArrows[1] : null}
            topOffset={
              imgHeight && size !== "full" ? Math.round(imgHeight / 2) : 0
            }
          />
          {dots && (
            <Dots
              children={children}
              slideKey={slideKey}
              setSlideKey={setCurrIndex}
            />
          )}
        </CarouselWrapper>
      </CarouselOuter>
    </SlideContext.Provider>
  )
}

// see lodash implementation
function clamp(number: number, lower: number, upper: number) {
  if (upper !== undefined) {
    number = number <= upper ? number : upper
  }
  if (lower !== undefined) {
    number = number >= lower ? number : lower
  }
  return number
}
