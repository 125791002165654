import { css } from "styled-components"

export const figcaptionStyles = css`
  display: block;
  font-family: "Roboto Condensed";
  font-size: ${(p) => p.theme.fontSizeSuperSmall} !important;
  padding-left: 0;
  margin-top: 0.5em;
  color: rgb(100, 100, 100);
  letter-spacing: 0.5px;
  text-transform: uppercase;

  @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
    padding-left: 1em;
  }

  a {
    color: rgb(180, 180, 180) !important;
    &:hover {
      background: none !important;
      border-bottom: 0.2em solid ${(p) => p.theme.articleRed} !important;
    }
  }
  a,
  a:hover {
    border: none !important;
  }
`
