import React, { useContext, useRef } from "react"
import Card from "./card"
// import { useCategories } from "../category-filter/categories"
import { FlexBox, FlexItem } from "../flex-box/flex-box"
import { CardWrapper, CardDeckWrapper, CardDeckInner } from "./card-deck.style"
import { useInViewClass } from "../../modules/hooks"
import { useZIndexes, ZWrapper } from "./z-indexes"
import useInitPoses from "./init-poses"
import { isMobile } from "../../modules/utils"
import { Link } from "gatsby"
import { SelectedContext } from "gatsby-source-dek-wp"
import scrollIntoView from "scroll-into-view"
import ReadingProgressBar from "../reading-progress-bar/reading-progress-bar"

// Inspired by https://codesandbox.io/s/fduch
// see also: https://github.com/orbiting/republik-frontend/blob/master/components/Marketing/Employees.js

interface CardDeckProps {
  cards: any[]
  backgroundImage?: string
  tileSize?: { width: number; height: number }
  maxPerRow?: number
  flexHook?: any
  children?: any
  bgImageUrl?: string
  template?: string
  className?: string
  justifyContent?: string
  isFirst?: boolean
}
// React.forwardRef(props, ref)
export const CardDeck = ({
  children: _children = [],
  cards = [],
  bgImageUrl,
  tileSize = { width: 320, height: 300 },
  maxPerRow = 3,
  flexHook,
  template = "default",
  className = "",
  justifyContent = "start",
  isFirst,
}: CardDeckProps) => {
  const ref = useRef()
  const [inViewRef] = useInViewClass("on-deck", { threshold: 0 })
  // const [categoryFilter, filteredCards] = useCategories(cards)
  const filteredCards = cards
  const [zIndexes, setTopIndex] = useZIndexes(cards)
  const mobile = isMobile()
  const initPoses = useInitPoses(cards, mobile)

  const { width, height } = tileSize
  const selected = useContext(SelectedContext)
  const parentSlug = selected.slug.split("/")[0]
  const children =
    template === "translator" ? (
      <Link to={`/${parentSlug}`}>{_children}</Link>
    ) : (
      _children
    )

  const scrollToStart = () => {
    const metaDiv = document.querySelector(".meta-info")
    if (!metaDiv) return
    scrollIntoView(metaDiv, { align: { top: 0, topOffset: 45 } })
  }

  return (
    <>
      <CardDeckWrapper
        ref={ref}
        backgroundImage={bgImageUrl}
        className={`template-${template} cards-${cards.length} ${className}`}
      >
        <div className="card-deck-bg-image" />
        {children}
        {/* categoryFilter */}
        <CardDeckInner
          className="card-deck-inner"
          ref={inViewRef}
          style={{ justifyContent }}
        >
          <FlexBox
            itemWidth={width}
            itemHeight={height}
            maxPerRow={maxPerRow}
            flexHook={flexHook}
          >
            {filteredCards.map((card, i) => {
              const { id, pos, filtered, hidden, postId } = card.props
              const transform = `${initPoses[i]} scale(${filtered ? 0.3 : 1})`
              const onSelect = () => {
                if (zIndexes[zIndexes.length - 1] !== i) {
                  setTopIndex(i)
                }
              }
              const onClick = cards.length === 1 ? scrollToStart : undefined
              return (
                <ZWrapper key={`-${id || i}`} zIndex={zIndexes.indexOf(i) + 2}>
                  <FlexItem pos={pos || i}>
                    <CardWrapper
                      style={{ transform }}
                      {...{ hidden, filtered }}
                    >
                      <Card
                        id={i}
                        onSelect={onSelect}
                        postId={postId}
                        onClick={onClick}
                      >
                        {card}
                      </Card>
                    </CardWrapper>
                  </FlexItem>
                </ZWrapper>
              )
            })}
          </FlexBox>
        </CardDeckInner>
      </CardDeckWrapper>
      {isFirst && <ReadingProgressBar />}
    </>
  )
}
