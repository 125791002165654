import React from "react"
import { MetaInfo } from "./meta-info"
import { Transformer } from "gatsby-source-dek-wp"

export const metaInfoTransformer: Transformer = (
  node,
  i,
  { classList, children },
) => {
  if (classList.includes("wp-block-dekoder-custom-blocks-meta-info")) {
    const withSpecialRelations = classList.includes("with-special-relations")
    return <MetaInfo withSpecialRelations={withSpecialRelations} />
  }
}
