import { useEffect, useState, useMemo, useRef } from "react"
import { useIntersectionObserver } from "gatsby-source-dek-wp"
import { v4 as uuidv4 } from "uuid"
import { BREAKPOINT_MOBILE } from "./utils"

export function useBodyClass(className: string) {
  useEffect(() => {
    if (!className) return
    if (typeof document === "undefined") return
    document.body.classList.add(className)
    return () => document.body.classList.remove(className)
  }, [className])
}

export function useInViewClass(className: string, options = {}) {
  const [ref, inView] = useIntersectionObserver(options)
  useBodyClass(inView ? className : "")
  return [ref, inView] as const
}

export function useUid() {
  return useMemo(uuidv4, [])
}

export function useMobileState(defaultState: boolean) {
  const [isMobile, setIsMobile] = useState(defaultState)
  useEffect(() => {
    function checkMobile() {
      setIsMobile(window.innerWidth < (BREAKPOINT_MOBILE || 900))
    }
    checkMobile()
    window.addEventListener("resize", checkMobile)
    return () => window.removeEventListener("resize", checkMobile)
  }, [])
  return isMobile
}

export function usePrev(value: any) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}
