import styled from "styled-components"

export const FeedbackWrapper = styled.div`
  position: relative;
  padding: 1em;
  background: ${(p) => (p.img ? `url(${p.img})` : "rgb(230, 230, 230)")};
  background-size: cover;
  background-position: 50% 50%;
  color: ${(p) => (p.img ? "white" : "inherit")};
  // text-shadow: ${(p) => (p.img ? "1px 1px black" : "none")};
  h3 {
    text-align: center;
    z-index: 2;
    position: relative;
    margin: 7em 0 !important;
  }
  h3,
  small {
    z-index: 2;
    position: relative;
  }
  a {
    color: ${(p) => (p.img ? "white" : "inherit")} !important;
  }
  &:before {
    display: block;
    content: "";
    z-index: 1;
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 50px rgba(0, 0, 0, 1);
  }
`
