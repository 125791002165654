import React, { useContext } from "react"
import { useTransformer } from "../../modules/transformer"
import { LangContext } from "gatsby-source-dek-wp"
import { FeedbackWrapper } from "./quiz-feedback.style"

const QuizFeedback = ({ total, correct, feedback, img }) => {
  const lang = useContext(LangContext)
  const feedbackText = useTransformer(feedback)
  return (
    <FeedbackWrapper img={img}>
      <small>
        {lang.id === "ru"
          ? `Вы ответили правильно на ${correct} из ${total} вопросов`
          : `Du hast ${correct} von ${total} Fragen richtig beantwortet`}
        .
      </small>
      <h3>{feedbackText}</h3>
    </FeedbackWrapper>
  )
}

export default QuizFeedback

export const QuizFeedbackTemplate = () => {
  return null
}
