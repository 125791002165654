import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  FC,
  ReactElement,
} from "react"
import { Source, Layer } from "react-map-gl"
import {
  CircleLayerSpecification,
  FillLayerSpecification,
  LineLayerSpecification,
  SymbolLayerSpecification,
} from "mapbox-gl"
import { MapContext } from "react-map-gl/dist/esm/components/map"
import { useSpreadsheetLoader } from "./spreadsheet"
import { getTransformFeatureFunc } from "./transform-feature"
import { useCtx } from "../scroll-trigger/external-callbacks"

export interface MapLayerProps {
  spreadsheetId?: string
  geojsonUrl?: string
  layerProps:
    | CircleLayerSpecification
    | FillLayerSpecification
    | LineLayerSpecification
    | SymbolLayerSpecification
  isActive: boolean
  isInteractive: boolean
  label: string
  color?: string
  nameKey: string
  descriptionKey?: string
  scriptEl?: React.ReactElement
}

export type FillLayerProps = MapLayerProps & {
  layerProps: FillLayerSpecification
}

export type MapLayerElement = React.ReactElement<MapLayerProps>

export const MapLayer: FC<MapLayerProps> = ({
  spreadsheetId,
  geojsonUrl,
  layerProps,
  scriptEl,
  isActive,
}) => {
  // const inView = useContext(InViewContext): TODO: start loading only when in view?
  const spreadsheetGeojson = useSpreadsheetLoader(spreadsheetId)
  const fileGeojson = useGeojsonFetcher(geojsonUrl)
  const geojson = useMemo(() => {
    const data = spreadsheetId ? spreadsheetGeojson : fileGeojson
    // TODO: transform features at 1 place only?
    const transformFeature = getTransformFeatureFunc(scriptEl)
    if (!data) return
    const transformedData = {
      ...data,
      features: data.features.map(transformFeature),
    }
    return transformedData
  }, [spreadsheetId, spreadsheetGeojson, fileGeojson, scriptEl])
  const mapCtx = useContext(MapContext)
  const ctx = useCtx()
  useEffect(() => {
    async function runInit() {
      if (!geojson?.features?.length) return
      if (!ctx.map) return
      const onInit = `onInit_${layerProps.id}`
      if (typeof window[onInit] === "function") {
        await mapboxMapLoaded(ctx.map)
        window[onInit]({ ...ctx, geojson })
      }
    }
    runInit()
  }, [geojson, ctx])
  if (!mapCtx) {
    console.error("Error: MapLayer not in map context!", { geojsonUrl, mapCtx })
    return null
  }
  return (
    <>
      {!!geojson && isActive && (
        <Source id={layerProps.id} type="geojson" data={geojson}>
          <Layer {...layerProps} />
        </Source>
      )}
      {scriptEl}
    </>
  )
}

export async function mapboxMapLoaded(map: mapboxgl.Map) {
  return new Promise((resolve) => {
    const checkLoad = () => {
      if (map.loaded()) {
        map.off("styledataloading", checkLoad)
        map.off("render", checkLoad)
        resolve(true)
      } else {
        map.on("styledataloading", checkLoad)
        map.on("render", checkLoad)
      }
    }
    checkLoad()
  })
}

const EMPTY_GEOJSON = {
  type: "FeatureCollection",
  features: [],
} as GeoJSON.FeatureCollection<GeoJSON.Geometry>

export function useGeojsonFetcher(url: string | undefined) {
  const [geojson, setGeojson] = useState(EMPTY_GEOJSON)
  useEffect(() => {
    if (!url) return
    fetch(`${url}?1`)
      .then((res) => res.text())
      .then((data) => {
        setGeojson(JSON.parse(data))
      })
      .catch(console.error)
  }, [url])
  return geojson
}

export function isFillLayer(
  l: ReactElement<MapLayerProps>,
): l is ReactElement<FillLayerProps> {
  return l.props.layerProps.type === "fill"
}
