import React from "react"
import { CardDeck } from "./card-deck"

const DEFAULT_PROPS = {
  tileSize: { width: 320, height: 300 },
  maxPerRow: 3,
  justifyContent: "start",
}

const TRANSLATOR_PROPS = {
  maxPerRow: 2,
  tileSize: { width: 400, height: 300 },
  justifyContent: "end",
  flexHook: ({
    x,
    y,
    totalItems,
    itemsPerRow,
    containerHeight,
    containerWidth,
  }) => {
    const newX = containerWidth > 800 ? containerWidth - 600 : x
    const newY = containerHeight - 345 // display at bottom
    // console.log({ containerHeight, y, newY })
    return [newX, newY]
  },
}

const TRANSLATOR_FRONT_PROPS = {
  ...TRANSLATOR_PROPS,
  justifyContent: "start",
  flexHook: ({ x, y, i, itemsPerRow, rowNo }) => {
    // shift X every 2nd row, shift Y every 2nd item
    const newX = rowNo % 2 ? x - 80 : x + 80
    const newY = i % 2 ? y + 80 : y - 20
    return itemsPerRow === 2 ? [newX, newY] : [x, y]
  },
}

const TEMPLATE_PROPS = {
  translator: TRANSLATOR_PROPS,
  "translator-front": TRANSLATOR_FRONT_PROPS,
}

export function cardDeckTransformer(node, i, { classList, children }) {
  if (classList.includes("wp-block-dekoder-custom-blocks-card-deck")) {
    const isFirst = !node.parent && !node.prev?.prev
    const cards = children
    const { bgimageurl, title, subtitle, template } = node.attribs
    const inner = (
      <>
        {!!title && <h1>{title}</h1>}
        {!!subtitle && <h2>{subtitle}</h2>}
      </>
    )
    const props = TEMPLATE_PROPS[template]
      ? { ...DEFAULT_PROPS, ...TEMPLATE_PROPS[template] }
      : DEFAULT_PROPS
    return (
      <CardDeck
        cards={cards}
        bgImageUrl={bgimageurl}
        template={template}
        className={classList.join(" ")}
        isFirst={isFirst}
        {...props}
      >
        {inner}
      </CardDeck>
    )
  }
}
