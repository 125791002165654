import styled from "styled-components"
import tvOverlay from "./tv-overlay.png"

const TvOverlay = styled.div<{ active?: boolean }>`
  display: block;
  z-index: 2;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-image: url(${tvOverlay}); // see: https://github.com/jaysalvat/vegas
  background-size: 3px;
  opacity: ${(p) => (p.active ? 0.3 : 0)}; //0.5;
  transition: opacity 1s ease-in-out;
  pointer-events: none;
`

export default TvOverlay
