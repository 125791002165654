import React from "react"
import { isElement, Transformer } from "gatsby-source-dek-wp"
import { MapPoint } from "./map-point"

export const mapPointTransformer: Transformer = (
  node,
  i,
  { classList, children },
) => {
  if (
    "attribs" in node &&
    classList.includes(
      "wp-block-dekoder-custom-blocks-container-with-coordinates",
    )
  ) {
    const { lat, lon, category, zoom, bearing, pitch } = node.attribs
    const props = {
      lat: parseFloat(lat),
      lon: parseFloat(lon),
      zoom: parseFloat(zoom),
      bearing: parseFloat(bearing),
      pitch: parseFloat(pitch),
      category,
      hidden: classList.includes("hide"),
    }
    return <MapPoint {...props}>{children.filter(isElement)}</MapPoint>
  }
}
