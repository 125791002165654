import React, { Suspense } from "react"
import { isElement, Transformer } from "gatsby-source-dek-wp"
import { MapLayer } from "./map-layer"
import { MapRangeSelect } from "./range-select"

const Map = React.lazy(() => import("./map"))

export const mapTransformer: Transformer = (
  node,
  i,
  { children: _children, classList },
) => {
  if (
    "attribs" in node &&
    classList.includes("wp-block-dekoder-custom-blocks-map")
  ) {
    const children = React.Children.toArray(_children).filter(isElement)
    const {
      lat,
      lon,
      zoom,
      zoommobile: zoomMobile,
      bearing,
      pitch,
      projection,
      styleurl: style,
    } = node.attribs
    const center = lon && lat ? [parseFloat(lon), parseFloat(lat)] : [53, 35]

    const options = {
      center,
      zoom: parseFloat(zoom),
      zoomMobile: zoomMobile ? parseFloat(zoomMobile) : undefined,
      bearing: parseFloat(bearing),
      pitch: parseFloat(pitch),
      projection,
      style,
      mapType: `${node.attribs.maptype}`,
    }

    const mapLayers = children.filter((c) => c.type === MapLayer)
    const rangeSelect = children.find((c) => c.type === MapRangeSelect)
    const otherChildren = children.filter(
      (c) => !mapLayers.includes(c) && c !== rangeSelect,
    )

    return (
      <Suspense fallback={<div>Loading ...</div>}>
        <Map options={options} layers={mapLayers} rangeSelect={rangeSelect}>
          {otherChildren}
        </Map>
      </Suspense>
    )
  }
}
