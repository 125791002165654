import styled from "styled-components"
import chroma from "chroma-js"

interface BtnProps {
  isActive?: boolean
  color: string
}

export const FillLayerBtn = styled.button<BtnProps>`
  background-color: ${(p) =>
    chroma(p.color)
      .luminance(p.isActive ? 0.4 : 0.8)
      .hex()};
  padding: 0.5rem 1rem;
  margin: 0 1rem 1rem 0;
  // display: block;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  font-size: ${(p) => p.theme.fontSizeSmall};
  // font-weight: ${(p) => (p.isActive ? 700 : 400)};
  .has-scroll-elements & {
    display: none;
  }
`

export const FillLayerBtnWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 2rem;
  @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
    padding: 1rem;
  }
`

export const FillLAyerBtnWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`
